import { Directive, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Identifier } from '@inigo/data/misc';
import { MyBS, MyBSArray, SignalArray } from '@inigo/gen-helpers/classes';
import { BlobDownloadService } from '@inigo/gen-helpers/services';
import { FilterRequest, PagedRequest, PagedResponse } from '@inigo/pagination';
import { TableSelectionResult } from '@inigo/x-table/utility';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { ACrudStoreService } from '../crud/a-crud-store.service';
import { ErrorInfo, SuccessInfo } from '../models/result-info';
import { IPageHttpService } from './i-page-http.service';
import { IPageStoreService } from './i-page-store.service';

//################################################################################//

/**
 * If not using PagePackages just override this.getPagePackage()  with: this.getPage()
 */
@Directive()
export abstract class APageStoreService<T, TUpload extends T> extends ACrudStoreService<T, TUpload> implements IPageStoreService<T, TUpload>
{

  protected _pagedDataBs: MyBSArray<T> = new MyBSArray<T>()
  pagedData$: Observable<T[]> = this._pagedDataBs.obs$

  protected _pagedData = new SignalArray<T>([])
  pagedData: Signal<T[]> = this._pagedData.signal

  protected _pageNumberBs: MyBS<number> = new MyBS<number>(0)
  pageNumber$: Observable<number> = this._pageNumberBs.obs$

  protected _pageSizeBs: MyBS<number> = new MyBS<number>(0)
  pageSize$: Observable<number> = this._pageSizeBs.obs$

  protected _totalItemsBs: MyBS<number> = new MyBS<number>(0)
  totalItems$: Observable<number> = this._totalItemsBs.obs$
  totalItems = toSignal(this.totalItems$)  

  protected _totalPagesBs: MyBS<number> = new MyBS<number>(0)
  totalPages$: Observable<number> = this._totalPagesBs.obs$

  protected _pagedResponseBs = new MyBS<PagedResponse<T>>(new PagedResponse<T>())
  pagedResponse$: Observable<PagedResponse<T>> = this._pagedResponseBs.obs$

  protected _currentFilterBs: MyBS<PagedRequest> = new MyBS<PagedRequest>(PagedRequest.Empty())
  currentFilter$: Observable<PagedRequest> = this._currentFilterBs.obs$

  //Use Subjects here because ou don't want a user to be sent the previous error.
  //All relevant Success/Error info will come AFTER subscription
  protected itemCountPaged = 0

  protected _exportSub?: Subscription
  protected _pageSub?: Subscription
  protected _pkgSub?: Subscription


  protected _blobHandler: BlobDownloadService = new BlobDownloadService()
  private _iPageHttpService: IPageHttpService<T, TUpload>

  //-------------------------------------------------------//

  constructor(httpService: IPageHttpService<T, TUpload>) {
    super(httpService)
    this._iPageHttpService = httpService

    this.added$.pipe(takeUntilDestroyed())
      .subscribe(newItem => {
        this._pagedDataBs.push(newItem)
        this._pagedData.push(newItem)
      })

    this.deleted$.pipe(takeUntilDestroyed())
      .subscribe(itemIdx => {
        this._pagedDataBs.remove(itemIdx.idx)
        this._pagedData.remove(itemIdx.idx)
      })

    this.edited$.pipe(takeUntilDestroyed())
      .subscribe(itemIdx => {
        this._pagedDataBs.replace(itemIdx.item, itemIdx.idx)
        this._pagedData.replace(itemIdx.item, itemIdx.idx)
      })
  }

  //-------------------------------------------------------//

  export(filter: FilterRequest[], fileName: string = 'export.xlsx'): Observable<Blob> {

    if (!this._iPageHttpService.export) {
      this._errorSb.next(ErrorInfo.Export('No Export Method supplied. Export cannot be completed'))
      return EMPTY
    } //if

    this._isLoadingBs.next(true)

    this._exportSub?.unsubscribe() //switchMap
    const export$ = this._iPageHttpService
      .export([...filter])
      .pipe(
        finalize(() => this._isLoadingBs.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
      )

    this._exportSub = export$.subscribe({
      next: (response) => {
        this._blobHandler.download(response, fileName)
        this._successSb.next(SuccessInfo.Export(fileName))
      },
      error: (error) => this._errorSb.next(error),
    }) //subscribe

    return export$

  } //export

  //-------------------------------------------------------//

  /** Downloads Esclel sheet of table Selection */
  exportTableSelection(tableSelection?: TableSelectionResult<T>, fileName: string = 'Export.xlsx'): Observable<Blob> {

    if (!tableSelection) {
      this._errorSb.next(ErrorInfo.Export('Nothing selected  for export'))
      return EMPTY
    }

    const request = tableSelection.generateFilterRequestFromSelection((t) =>
      this.hasId(t) ? t.id : null)

    return this.export(request, fileName)

  } //exportTableSelection

  //-------------------------------------------------------//

  filterPage(pagedRequest: PagedRequest, id: Identifier = null): Observable<PagedResponse<T>> {


    if (!pagedRequest) return EMPTY

    this._currentFilterBs.next(pagedRequest)
    return this.getPage(pagedRequest, id)

  } //filter

  //-------------------------------------------------------//

  protected getPage(pagedRequest: PagedRequest, id: Identifier = null): Observable<PagedResponse<T>> {

    this._isLoadingBs.next(true)
    this._pageSub?.unsubscribe() //switchMap


    const getAllItemsPaged$ = this._iPageHttpService
      .getAllItemsPaged(pagedRequest, id)
      .pipe(
        finalize(() => this._isLoadingBs.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
      )

    this._pageSub = getAllItemsPaged$
      .subscribe({
        next: (pagedResponse: PagedResponse<T>) => this.handlePageResponse(pagedResponse),
        error: (error) => this._errorSb.next(error),
      }) //subscribe

    return getAllItemsPaged$

  } //getPage

  //-------------------------------------------------------//

  protected handlePageResponse(response?: PagedResponse<T>): void {
    if (!response) return

    this._pagedResponseBs.next(response)
    this._pagedDataBs.next(response.data ?? [])
    this._pagedData.set(response.data ?? [])
    this._pageNumberBs.next(response.pageNumber)
    this._pageSizeBs.next(response.pageSize)
    this._totalItemsBs.next(response.totalItems)
    this._totalPagesBs.next(response.totalPages)

    this.itemCountPaged = (response.data ?? []).length
    
  } //handleResponse


  //-------------------------------------------------------//

  /** Clean up resources and subscriptions */
  override tidyUp(): void {
    super.tidyUp()
    this._exportSub?.unsubscribe()
    this._pagedDataBs.next([])
    this._pagedData.set([])
    this._pageSub?.unsubscribe()
    this._pkgSub?.unsubscribe()
  } //tidyUp

  //-------------------------------------------------------//

  getCurrentPageSize = (): number => {
    // this._pagedDataBsArray.getValue()?.length ?? 0
    return this._pagedData.getValue()?.length ?? 0
  }

  //-------------------------------------------------------//

  private hasId = (t: any): t is IHasId => (t as IHasId).id !== undefined

  //-------------------------------------------------------//


  refreshPage(): void { this.filterPage(PagedRequest.Empty()) }

  //-------------------------------------------------------//

  /**
   * If no page data exists request it from the Server.
   * OVerride to add or remove functionallity
   */
  protected refreshPageIfNecessary() {

    if (this.getCurrentPageSize() < 1)
      this.filterPage(PagedRequest.Empty())

  } //refreshIfNecessary

  //-------------------------------------------------------//

  override ngOnDestroy(): void {
    super.ngOnDestroy()
    this.tidyUp()
    // console.log('destroy');

  }
  //-------------------------------------------------------//

} //Cls

//#######################################################//

interface IHasId {
  id?: Identifier
}

//#######################################################//

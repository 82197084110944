/**
 * Class for passing data to Diaolog elements
 */
export class DialogConfig<D = any> {
  public maxHeight: string = '90%';
  public width: string = '';


  /** 
   * Can multiple dialogs be on the page at the same time.
   * If set to false opening a new dialog will close the last one.
   * 
   * Default = false
   */
  public allowDialogStack = false

  /**
   * Enter nulls or nothing to get default values.
   * @param maxWidth Max-Width of the dialog - default = '1200px'
   * @param data Any extra data that the component being displayed in the dialog will need - default = null
   * @param isSpinner Will this be just used for a spinner/bouncer - default = false
   * @param zIndex z-index of overlay - May need to be changed if drop-down has lower z-index - Default = 999
   * @param transparentBg Invisble background with no padding instead of white - Default = false
   * @param closeOnOverlayClick Should the dialog close when the outside is clicked. Default = true
   */
  constructor(
    public maxWidth: string = '1200px',
    public data: D | null = null,
    public isSpinner: boolean = false,
    public zIndex: number = 999,
    public transparentBg: boolean = false,
    public closeOnOverlayClick: boolean = true
  ) {}

  //---------------------------------------------------------------------//

  /**
   * Create new instance of DialogConfig. Enter nulls or nothing to get default values.
   * @param maxWidth Max-Width of the dialog - default = '1200px'
   * @param data Any extra data that the component being displayed in the dialog will need - default = null
   * @param isSpinner Will this be just used for a spinner/bouncer - default = false
   * @param zIndex z-index of overlay - May need to be changed if drop-down has lower z-index - default=999
   * @param transparentBg Invisble background with no padding instead of white - default = false
   * @param closeOnOverlayClick Should the dialog close when the outside is clicked. Default = true
   */
  static Create<D>(
    maxWidth: string = '1200px',
    data: D | null = null,
    isSpinner: boolean = false,
    zIndex: number = 999,
    transparentBg: boolean = false,
    closeOnOverlayClick: boolean = true
  ): DialogConfig<D> {
    return new DialogConfig(
      maxWidth,
      data,
      isSpinner,
      zIndex,
      transparentBg,
      closeOnOverlayClick
    );
  } //create

  //---------------------------------------------------------------------//

  /**
   * @param allowDialogStack Allow other dialogs to open over this one. Default = false
   * @returns Updated DialogConfig
   */
  setAllowDialogStack(allowDialogStack: boolean): DialogConfig<D> {
    this.allowDialogStack = allowDialogStack
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param closeOnOverlayClick Should the dialog close when the outside is clicked. Default = true
   * @returns Updated DialogConfig
   */
  setCloseOnOverlayClick(closeOnOverlayClick: boolean): DialogConfig<D> {
    this.closeOnOverlayClick = closeOnOverlayClick;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param data Any extra data that the component being displayed in the dialog will need
   * @returns Updated DialogConfig
   */
  setData(data: D): DialogConfig<D> {
    this.data = data;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param isSpinner Will this be just used for a spinner/bouncer
   * @returns Updated DialogConfig
   */
  setIsSpinner(isSpinner: boolean): DialogConfig<D> {
    this.isSpinner = isSpinner;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param width Max-Width of the dialog. Default '90%'
   * @returns Updated DialogConfig
   */
  setMaxHeight(height: string): DialogConfig<D> {
    this.maxHeight = height;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param width Max-Width of the dialog. Default '500px'
   * @returns Updated DialogConfig
   */
  setMaxWidth(width: string): DialogConfig<D> {
    this.maxWidth = width;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param transparentBg Invisble background with no padding instead of white. Default = true
   * @returns Updated DialogConfig
   */
  setTransparentBg(transparentBg: boolean = true): DialogConfig<D> {
    this.transparentBg = transparentBg;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param zIndex z-index of overlay - May need to be changed if drop-down has lower z-index
   * @returns Updated DialogConfig
   */
  setZIndex(zIndex: number): DialogConfig<D> {
    this.zIndex = zIndex;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param width Width of the dialog. Default = '' (unset)
   * @returns Updated DialogConfig
   */
  setWidth(width: string): DialogConfig<D> {
    this.width = width;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//
} //Cls

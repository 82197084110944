import { Inject, Injectable, inject } from '@angular/core';
import { LoadingSpinnerType } from '@inigo/my-loaders';
import { ISpinner, PopUpService } from '@inigo/pop-up';
import { CoreConfig, CoreConfigService } from '../../core-config';
import { ALoaderInterceptor } from './a-loader.interceptor';

/**
 * This interceptor shows a popup spinner every time a http request is made.
 */
@Injectable()
export class LoaderInterceptor extends ALoaderInterceptor {

  private _popUps = inject(PopUpService)

  private _spnr: ISpinner

  //---------------------------------------------//

  constructor(@Inject(CoreConfigService) private _coreConfig: CoreConfig) {
    super(_coreConfig.urlsToIgnore)

    this._spnr = this.getSpinner(_coreConfig.loadingSpinnerType)

  }//ctor

  //---------------------------------------------//

  protected override  onLoadingStarted(requestedUrl: string) {

    if (this._coreConfig.useLoadingInterceptor)
      this._spnr.open()

  } //onLoadingStarted

  //---------------------------------------------//

  protected override  onLoadingCompleted(requestedUrl: string) {

    this._spnr.close()

  } //onRequestCompleted

  //---------------------------------------------//

  /**
   * Convert SpinnerType to a Spinner
   * @param spnrType Type of Spinner
   */
  getSpinner(spnrType: LoadingSpinnerType): ISpinner {

    switch (spnrType) {
      case 'trouncer':
        return this._popUps.trouncer;
      case 'bouncer':
        return this._popUps.bouncer;
      case 'dots':
        return this._popUps.dotSpinner;
      default:
        return this._popUps.trouncer;
    }//switch

  }//getSpinner

  //---------------------------------------------//

} //Cls

import { InjectionToken } from '@angular/core';
import { StringHelpers } from '@inigo/gen-helpers/text';

//========================================================================================================//

export const TrouncerConfigService = new InjectionToken<TrouncerConfig>(
  'TrouncerConfig'
);

//========================================================================================================//

export class TrouncerConfig {
  private _imgPath1 = '/assets/images/trouncer/trouncer1.png';
  /** Path to image for first trouncer - default "/assets/images/trouncer/trouncer1.png" */
  public get imgPath1(): string {
    return StringHelpers.Clone(this._imgPath1);
  }

  private _imgPath2 = '/assets/images/trouncer/trouncer2.png';
  /** Path to image for second trounce - default "/assets/images/trouncer/trouncer2.png" */
  public get imgPath2(): string {
    return StringHelpers.Clone(this._imgPath2);
  }

  private _imgPath3 = '/assets/images/trouncer/trouncer3.png';
  /** Path to image for third trouncer - default "/assets/images/trouncer/trouncer3.png" */
  public get imgPath3(): string {
    return StringHelpers.Clone(this._imgPath3);
  }

  private _bounce = true;
  /** Whether images should shrink back down (bounce) or fade out at the top */
  public get bounce(): boolean {
    return this._bounce;
  }

  private _useImages = false;
  /** Use images instead of just background color */
  public get useImages(): boolean {
    return this._useImages;
  }

  private _useShadow = true;
  /** Add a shadow to create elevation. May not look good on some images with transpararnt parts. */
  public get useShadow(): boolean {
    return this._useShadow;
  }

  private _color1: string = '';
  /** Color for first trouncer - default 'colorPrimary' */
  public get color1(): string {
    return StringHelpers.Clone(this._color1);
  }

  private _color2: string = '';
  /** Color for second trouncer - default 'colorSecondary' */
  public get color2(): string {
    return StringHelpers.Clone(this._color2);
  }

  private _color3: string = '';
  /** Color for third trouncer - default 'colorTertiarary' */
  public get color3(): string {
    return StringHelpers.Clone(this._color3);
  }

  private _diameterPx = 100;
  /** Diameter of the Circles */
  public get diameterPx(): number {
    return this._diameterPx;
  }

  private _zIndex = 1000;
  /** default 1000 */
  public get zIndex(): number {
    return this._zIndex;
  }

  /** Array of colors for when more than 3 is required */
  private _colors: string[];
  public get colors(): string[] {
    return this._colors;
  }
  /** Array of images for when more than 3 is required */
  private _images: string[];
  public get images(): string[] {
    return this._images;
  }

  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * Enter no paramaters to use default values on everything
   * @param useImages Use images instead of just background color
   * @param useShadow Add a shadow to create elevation. May not look good on some images with transpararnt parts.
   * @param imgPath1 Path to image for first trouncer - default "/assets/images/trouncer/trouncer1.png"
   * @param imgPath2 Path to image for second trounce - default "/assets/images/trouncer/trouncer2.png"
   * @param imgPath3 Path to image for third trouncer - default "/assets/images/trouncer/trouncer3.png"
   * @param color1 Color for first trouncer - default colorPrimary
   * @param color2 Color for second trouncer - default colorSecondary
   * @param color3 Color for third trouncer - default colorTertiarary
   * @param bounce Whether images should shrink back down (bounce) or fade out at the top
   * @param diameterPx  Width of the Circles - default 100
   * @param zIndex - default 1000
   * @param colors Array of colors for when more than 3 is required - default null
   * @param imagePaths Array of images for when more than 3 is required - default null
   */
  protected constructor(
    useImages: boolean | null = null,
    useShadow: boolean | null = null,
    imgPath1: string = '',
    imgPath2: string = '',
    imgPath3: string = '',
    color1: string = '',
    color2: string = '',
    color3: string = '',
    bounce: boolean | null = null,
    diameterPx: number = 0,
    zIndex: number = 0,
    colors: string[] | null = null,
    imagePaths: string[] | null = null
  ) {
    if (useShadow !== null) this._useShadow = useShadow;

    if (useImages !== null) this._useImages = useImages;

    if (imgPath1) this._imgPath1 = imgPath1;
    if (imgPath2) this._imgPath2 = imgPath2;
    if (imgPath3) this._imgPath3 = imgPath3;

    if (bounce !== null) this._bounce = bounce;

    if (color1) this._color1 = color1;

    if (color2) this._color2 = color2;

    if (color3) this._color3 = color3;

    if (diameterPx) this._diameterPx = diameterPx;

    if (zIndex) this._zIndex = zIndex;

    this._images = imagePaths ?? [];
    this._colors = colors ?? [];
  } //ctor

  //---------------------------------------------------------------------//

  /**
   * Create new instance of TrouncerConfig
   * @param useImages Use images instead of just background color. Default = false
   * @param useShadow Add a shadow to create elevation. May not look good on some mages with transpararnt parts. Default = true
   */
  static Create(
    useImages: boolean = false,
    useShadow: boolean = true
  ): TrouncerConfig {
    return new TrouncerConfig(useImages, useShadow);
  } //create

  //---------------------------------------------------------------------//

  /**
   * @param useImages Use images instead of just background color. Default = false
   * @returns Updated TrouncerConfig
   */
  setUseImages(useImages: boolean): TrouncerConfig {
    this._useImages = useImages;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param useShadow Add a shadow to create elevation. May not look good on some images with transpararnt parts. Default = true
   * @returns Updated TrouncerConfig
   */
  setUseShadow(useShadow: boolean): TrouncerConfig {
    this._useShadow = useShadow;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param imgPath1 Path to image for first trouncer - default "/assets/images/trouncer/trouncer1.png"
   * @returns Updated TrouncerConfig
   */
  setImgPath1(imgPath1: string): TrouncerConfig {
    this._imgPath1 = imgPath1;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param  imgPath2 Path to image for second trounce - default "/assets/images/trouncer/trouncer2.png"
   * @returns Updated TrouncerConfig
   */
  setImgPath2(imgPath2: string): TrouncerConfig {
    this._imgPath2 = imgPath2;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param imgPath3 Path to image for third trouncer - default "/assets/images/trouncer/trouncer3.png"
   * @returns Updated TrouncerConfig
   */
  setImgPath3(imgPath3: string): TrouncerConfig {
    this._imgPath3 = imgPath3;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color1 Color for first trouncer - default colorPrimary
   * @returns Updated TrouncerConfig
   */
  setColor1(color1: string): TrouncerConfig {
    this._color1 = color1;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color2 Color for second trouncer - default colorSecondary
   * @returns Updated TrouncerConfig
   */
  setColor2(color2: string): TrouncerConfig {
    this._color2 = color2;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color3 Color for third trouncer - default colorTertiarary
   * @returns Updated TrouncerConfig
   */
  setColor3(color3: string): TrouncerConfig {
    this._color3 = color3;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param bounce Whether images should shrink back down (bounce) or fade out at the top
   * @returns Updated TrouncerConfig
   */
  setBounce(bounce: boolean): TrouncerConfig {
    this._bounce = bounce;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param diameterPx  Width of the Circles - default 100
   * @returns Updated TrouncerConfig
   */
  setDiameterPx(diameterPx: number): TrouncerConfig {
    this._diameterPx = diameterPx;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param zIndex  Z-Index of spinners. Default 1000
   * @returns Updated TrouncerConfig
   */
  setZIndex(zIndex: number): TrouncerConfig {
    this._zIndex = zIndex;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param colors Array of colors for when more than 3 is required - default null
   * @returns Updated TrouncerConfig
   */
  setColors(colors: string[]): TrouncerConfig {
    this._colors = colors;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param imagePaths Array of images for when more than 3 is required - default null
   * @returns Updated TrouncerConfig
   */
  setImagePaths(imagePaths: string[]): TrouncerConfig {
    this._images = imagePaths;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  //---------------------------------------------------------------------//
} //Cls

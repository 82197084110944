import { Injectable } from '@angular/core';
import { ToFormData } from '@inigo/gen-helpers/objects';
import { APageHttpService } from '@inigo/my-store';
import { Observable } from 'rxjs';
import { AppActions } from '../../../../app-actions';
import { AppControllers } from '../../../../app-controllers';
import { MeasurementDef } from '../../../dtos/measurementDef';

@Injectable({
  providedIn: 'root',
})
export class MeasurementDefsHttpService extends APageHttpService<MeasurementDef, MeasurementDef> {
  
  //---------------------------------------------------------------------//

  constructor() {
    super();
    this.url = AppControllers.FullPath.MEASUREMENTS
  } //ctor

  //---------------------------------------------------------------------//

  upload(def: MeasurementDef): Observable<any> {
    return this.postAction(AppActions.Measurements.UPLOAD, ToFormData(def));
  } //upload

  //---------------------------------------------------------------------//

  getAllPresets = (opts?: any): Observable<MeasurementDef[]> =>
    this.getAllAction(AppActions.Measurements.ALL_PRESETS, opts ?? {})

  //---------------------------------------------------------------------//

} //service

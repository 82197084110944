import { Injectable } from '@angular/core';
import { MessageResponseDto } from '@inigo/data/misc';
import { SuccessComponent } from '../../../components/info/success/success.component';
import { DialogConfig } from '../../../utility/dialog-config';
import { BaseDialogService } from '../../base/base-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class SuccessDialogService extends BaseDialogService {

  //------------------------------------------------------//

  /**
   * Open Success Dialog
   * @param title Title of Dialog
   * @param message Content of Dialog
   * @param width How wide is it - defaults to '350px'
   */
  public open(title: string, message: string, width: string = '350px') {
    const data = {
      title: title,
      message: message,
    };
    const config = new DialogConfig(width, data, false);

    return this.openBase(SuccessComponent, config);
  } //open

  //------------------------------------------------------//

  /**
   * Open Success Dialog with title set to 'Success'
   * @param message Content of Dialog
   * @param width How wide is it - defaults to '350px'
   */
  public openSuccess = (message: string, width?: string) =>
    this.open('Success', message, width)

  //------------------------------------------------------//

  /**
   * Open Success Dialog with title set to 'Success'
   * @param msgDto Dto with the message info
   * @param width How wide is it - defaults to '350px'
   */
  public openMessageResponseDto = (msgDto: MessageResponseDto, width?: string) =>
    this.open('Success', msgDto.message, width)

  //------------------------------------------------------//
} //Cls

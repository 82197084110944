import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TextHelpersConfig, TextHelpersConfigService } from './text-helpers-config';
import { TextSetup } from './text-setup';

@NgModule({
  imports: [CommonModule],
})
export class TextModule {

  static configure(config: TextHelpersConfig = new TextHelpersConfig()): ModuleWithProviders<TextModule> {
    return {
      ngModule: TextModule,
      providers: TextSetup.getProviders(config)
    }
  }//configure

}//Cls

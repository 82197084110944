import { Directive, HostBinding, HostListener, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
@Directive()
export abstract class MyControlValueAccessor implements ControlValueAccessor, OnInit {

    //------------------------------------------------------//

    protected touched$= new BehaviorSubject<boolean>(false)
    protected disabled$ = new BehaviorSubject<boolean>(false)

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - //

    @HostBinding('class.invalid') protected _invalid = false
    @HostBinding('class.touched') protected _touched = false
    @HostBinding('class.focused') protected _focused = false
    @HostBinding('class.disabled') protected _disabled = false

    //------------------------------------------------------//

    ngOnInit(): void {

        this.touched$.subscribe(
            t => this._touched = t
        )

        this.disabled$.subscribe(
            d => this._disabled = d
        )

    }//ngOnInit

    //------------------------------------------------------//

    myCvaDisabled = false

    /** Call this when new values need to be emitted/passed to the DOM/Form */
    myCvaOnChange = (_: any) => { }


    /** Call this when new values need to be emitted/passed to the DOM/Form */
    myCvaOnTouched = () => { this.touched$.next(true) }

    //------------------------------------------------------//

    abstract writeValue(obj: any): void

    //------------------------------------------------------//

    /**
     * Grab the propogate function so we can use it to emit values later.
     * @param fn The function that will talk to the form.
     */
    registerOnChange = (fn: any): void => this.myCvaOnChange = fn

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - //

    registerOnTouched(fn: any): void {

        this.myCvaOnTouched = () => fn

    }

    //------------------------------------------------------//

    setDisabledState?(isDisabled: boolean): void {

        this.myCvaDisabled = isDisabled
        this.disabled$.next(isDisabled)

    }

    //------------------------------------------------------//

    @HostListener('focusin')
    protected onFocusIn() {

        this._focused = true
        this.myCvaOnTouched()

    }//focusIn

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - //

    @HostListener('focusout', ['$event'])
    protected onFocusOut(ev: any): void {

        if (!!ev?.relatedTarget)
            return

        this._focused = false
        this.touched$.next(true)

    }//focusOut

    //------------------------------------------------------//

}//Cls


import { Identifier } from '@inigo/data/misc';
import { PagedRequest } from '@inigo/pagination';
import { Observable } from 'rxjs';
import { APagePackage } from '../models/a-page-package';
import { APageHttpService } from '../page/a-page-http.service';
import { IPagePkgHttpService } from './i-page-pkg-http.service';

/**
 * Interface for basic HttpCalls that an implentation of BaseStore will user
 *
 * @template T The main type that we're performing CRUD on.
 * @template TUpload The type that will be used for uploading (add/edit). Can be the same as T but may contain some extra fields.
 */
export abstract class APagePkgHttpService<T, TUpload extends T, TPkg extends APagePackage<T>> extends APageHttpService<T, TUpload> implements IPagePkgHttpService<T, TUpload, TPkg>
{


  getAllItemsPagePkg(pagedRequest: PagedRequest, id?: Identifier, opts?: any): Observable<TPkg> {

//  console.log('getAllItemsPagePkg===>>', id);
    if (id == null || id == undefined)
      return this.getPagePkg<TPkg>(pagedRequest, opts ?? {})
    else
      return this.getPagePkgById<TPkg>(pagedRequest, id, opts ?? {})
    // return this.getPagePkg<TPkg>(pagedRequest, opts ?? {})

  }

} //Cls

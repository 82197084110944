
export class AppControllers {

    static readonly API = 'api'
    static readonly ADDRESSES = 'addresses' //'pa'
    static readonly DELIVERY_ADDRESSES = 'deliveryaddresses' //'pa'
    static readonly APP_SETTINGS = 'appSettings'
    static readonly BRANDS = 'brands'
    static readonly CONTACT = 'contact'
    static readonly COLORS = 'colors'
    static readonly CUSTOMERS = 'customers'
    static readonly DB_MAINTENANCE = 'maintenance'
    static readonly DEPARTMENTS = 'departments'
    static readonly DIMENS = 'dimens'
    static readonly DOCUMENTS = 'documents'
    static readonly IMAGE = 'image'
    static readonly MEASUREMENTS = 'measurements'
    static readonly ORDERLINES = 'orderlines'
    static readonly ORDERS = 'orders'
    static readonly PRODUCTS = 'products'
    static readonly SHOP = 'shop'
    static readonly STYLES = 'styles'
    static readonly UNITS = 'units'
    static readonly VARIANTS = 'productvariants'

    //---------------------------------------------------------//

    static FullPath = class {

        public static readonly ADDRESSES = `/${AppControllers.API}/${AppControllers.ADDRESSES}`
        public static readonly APP_SETTINGS = `/${AppControllers.API}/${AppControllers.APP_SETTINGS}`
        public static readonly BRANDS = `/${AppControllers.API}/${AppControllers.BRANDS}`
        public static readonly CONTACT = `/${AppControllers.API}/${AppControllers.CONTACT}`
        public static readonly COLORS = `/${AppControllers.API}/${AppControllers.COLORS}`
        public static readonly CUSTOMERS = `/${AppControllers.API}/${AppControllers.CUSTOMERS}`
        public static readonly DB_MAINTENANCE = `/${AppControllers.API}/${AppControllers.DB_MAINTENANCE}`
        public static readonly DELIVERY_ADDRESSES = `/${AppControllers.API}/${AppControllers.DELIVERY_ADDRESSES}`
        public static readonly DEPARTMENTS = `/${AppControllers.API}/${AppControllers.DEPARTMENTS}`
        public static readonly DIMENS = `/${AppControllers.API}/${AppControllers.DIMENS}`
        public static readonly DOCUMENTS = `/${AppControllers.API}/${AppControllers.DOCUMENTS}`
        public static readonly IMAGE = `/${AppControllers.API}/${AppControllers.IMAGE}`
        public static readonly MEASUREMENTS = `/${AppControllers.API}/${AppControllers.MEASUREMENTS}`
        public static readonly ORDERLINES = `/${AppControllers.API}/${AppControllers.ORDERLINES}`
        public static readonly ORDERS = `/${AppControllers.API}/${AppControllers.ORDERS}`
        public static readonly PRODUCTS = `/${AppControllers.API}/${AppControllers.PRODUCTS}`
        public static readonly SHOP = `/${AppControllers.API}/${AppControllers.SHOP}`
        public static readonly STYLES = `/${AppControllers.API}/${AppControllers.STYLES}`
        public static readonly UNITS = `/${AppControllers.API}/${AppControllers.UNITS}`
        public static readonly VARIANTS = `/${AppControllers.API}/${AppControllers.VARIANTS}`

    }//Cls

    //---------------------------------------------------------//

}//Cls
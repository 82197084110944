
export class AppActions {



    //---------------------------------------------------------//

    static Addresses = class {

        // public static readonly NEWS = 'news'
        // public static readonly OFFERS = 'offers'
        // public static readonly EVENTS = 'events'

    }//Cls

    //---------------------------------------------------------//

    static AppSettings = class {

        // public static readonly NEWS = 'news'
        // public static readonly OFFERS = 'offers'
        // public static readonly EVENTS = 'events'

    }//Cls

    //---------------------------------------------------------//

    static Brands = class {

        // public static readonly NEWS = 'news'
        // public static readonly OFFERS = 'offers'
        // public static readonly EVENTS = 'events'
        public static readonly GET_TEMPLATE = 'getTemplate'
        public static readonly ADD_BULK = 'addBulk'

    }//Cls

    //---------------------------------------------------------//

    static Contact = class {

        // public static readonly RE_STRIPE = 'restripe'
    }//Cls

    //---------------------------------------------------------//

    static Colors = class {

        public static readonly ALL_PRESETS = 'getAllPresets'
        public static readonly ADD_BULK = 'addBulk'
        public static readonly GET_TEMPLATE = 'getTemplate'
    }//Cls

    //---------------------------------------------------------//

    static Customers = class {
        public static readonly REGISTER = 'register'
        public static readonly RE_STRIPE = 'restripe'
        public static readonly MYINFO = 'myInfo';
    }//Cls

    //---------------------------------------------------------//

    static DbMaintenance = class {

        public static readonly ADD_MORE_RDPS = 'addMoreRDPs'
        public static readonly CLEAR_BNB = 'clearBnB'
        public static readonly POPULATE_BNB = 'populateBnB'
        public static readonly REFRESH_BNB = 'refreshBnB'
        public static readonly UPDATE_RESERVATIONS = 'updateReservations'
        public static readonly UPDATE_RESERVATIONS_FROM_LIST = 'updateReservationsFromList'
        public static readonly WIPE_BOOKINGS = 'wipeBookings'
        public static readonly WU_PUSH = 'wuPush'
        public static readonly WU_PUSH_ACTIVATE = 'activatePush'
        public static readonly WU_PUSH_CANCEL = 'cancelPush'
        public static readonly RESTORE_DB = 'restoreDb'

    }//Cls

    //---------------------------------------------------------//

    static Departments = class {

        public static readonly GET_ALL_PRESETS = 'getAllPresets'
        public static readonly GET_TEMPLATE = 'getTemplate'
    }//Cls

    //---------------------------------------------------------//

    static Dimensions = class {

        // public static readonly RE_STRIPE = 'restripe'
    }//Cls

    //---------------------------------------------------------//

    static Documnents = class {

        public static readonly DELETE_ALL_SHOP_DOCUMENT = 'deleteAllShopDocument'
        public static readonly UPLOAD = 'upload'
    }//Cls

    //---------------------------------------------------------//

    static Image = class {

    }//Cls

    //---------------------------------------------------------//

    static Measurements = class {

        public static readonly ALL_PRESETS = 'getAllPresets'
        public static readonly UPLOAD = 'upload'

    }//Cls


    //---------------------------------------------------------//

    static OrderLines = class {

        // public static readonly NEWS = 'news'
        // public static readonly OFFERS = 'offers'
        // public static readonly EVENTS = 'events'

    }//Cls

    //---------------------------------------------------------//

    static Orders = class {

        // public static readonly NEWS = 'news'
        // public static readonly OFFERS = 'offers'
        public static readonly MARK_AS_COMPLETE = 'markAsComplete'
        public static readonly MARK_AS_PAID = 'markAsPaid'
        public static readonly MARK_AS_PROCESSING_PAYMENT = 'markAsProcessingPayment'
        public static readonly SET_STRIPE_REF = 'setStripeRef'
        public static readonly PAGE_PACKAGE_BY_CUSTOMER = 'customerPagePackage'
        public static readonly PAGE_BY_CUSTOMER = 'customerPage'

    }//Cls

    //---------------------------------------------------------//

    static Products = class {

        public static readonly GET_TEMPLATE = 'getTemplate'
        public static readonly GET_PAGE_MNTC = 'pageMntc'
        public static readonly GET_PAGE_PKG_MNTC = 'pagePackageMntc'
        public static readonly ADD_BULK = 'addBulk'
        public static readonly APPEND_GALLERY = 'appendGallery'
        public static readonly DELETE_DOCUMENTS = 'deletedocuments'
        public static readonly DELETE_GALLERY_IMAGES = 'deleteGalleryImages'
        public static readonly DOWNLOAD_DOC = 'downloadDoc'

    }//Cls

    //---------------------------------------------------------//

    static Shop = class {

        public static readonly PLACE_ORDER = 'placeOrder'

    }//Cls

    //---------------------------------------------------------//

    static Styles = class {

        public static readonly ALL_PRESETS = 'getAllPresets'
        public static readonly UPLOAD = 'upload'
        public static readonly ADD_BULK = 'addBulk'
        public static readonly GET_TEMPLATE = 'getTemplate'

    }//Cls

    //---------------------------------------------------------//

    static Units = class {

        public static readonly ALL_PRESETS = 'getAllPresets'
        public static readonly UPLOAD = 'upload'

    }//Cls

    //---------------------------------------------------------//

    static Variants = class {

        public static readonly ADD_BULK = 'addBulk'
        public static readonly ADD_BULK_MULTI = 'addBulkMulti'
        public static readonly PAGE_PACKAGE_BY_PRODUCT_CUSTOMER = 'pagePackageByProductCustomer'
        public static readonly PAGE_PACKAGE_BY_PRODUCT_ADMIN = 'pagePackageByProductAdmin'
        public static readonly PAGE_BY_PRODUCT = 'pageByProduct'
        public static readonly GET_TEMPLATE = 'getTemplate'
        public static readonly GET_MULTI_TEMPLATE = 'getMultiTemplate'

    }//Cls

    //---------------------------------------------------------//

}//Cls
import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DEFAULT_SVG_ICON_FOLDER } from '@inigo/data/misc';

@Injectable({
  providedIn: 'root',
})
export class SvgToIconService {


  private _iconRegistry = inject(MatIconRegistry)
  private _sanitizer = inject(DomSanitizer)
  private _platformId = inject(PLATFORM_ID)

  //----------------------------------------------------------------------------//

  private _registeredIconSet: Set<string> = new Set();

  //----------------------------------------------------------------------------//

  /**
   * Add custom svg to MaterialOld Icons
   * @param title Html reference
   * @param pathToSvg Location of SVG file
   */
  addIconToRegistry(title: string, pathToSvg: string) {
    if (this._registeredIconSet.has(title)) return

    // console.log('addIconToRegistry', universalPathToSvg);

    if (isPlatformServer(this._platformId))
      this._iconRegistry.addSvgIconLiteral(title, this._sanitizer.bypassSecurityTrustHtml('<svg></svg>'));
    else
      this._iconRegistry.addSvgIcon(title, this._sanitizer.bypassSecurityTrustResourceUrl(pathToSvg));

    this._registeredIconSet.add(title);
  } //addIconToRegistry

  //----------------------------------------------------------------------------//

  /**
   * Add custom svgs to MaterialOld Icons
   */
  addIconsToRegistry(icons: SvgIcon[]) {

    icons.forEach((icon) =>
      this._iconRegistry.addSvgIcon(
        icon.title,
        this._sanitizer.bypassSecurityTrustResourceUrl(icon.path)
      )
    )

  } //addIconToRegistry

  //----------------------------------------------------------------------------//

  setIconTypeOutline = () =>
    this._iconRegistry.setDefaultFontSetClass('material-icons-outlined');

  //----------------------------------------------------------------------------//

  /**
   * Register all the icons in the shared\assets\material-icons folder.
   * Remember to add shared\assets\material-icons to your project assets in angular.json.
   * @param folderPath where to find the icons (must end with '/') - default 'assets/images/material-icons/'
   */
  registerAllIcons(folderPath: string = DEFAULT_SVG_ICON_FOLDER) {
    // console.log(folderPath + 'account_balance.svg')
    // this.addIconToRegistry('tester', folderPath + 'tester.svg')

    this.addIconToRegistry('account_balance', folderPath + 'account_balance.svg')
    this.addIconToRegistry('account_circle', folderPath + 'account_circle.svg')
    this.addIconToRegistry('availability', folderPath + 'event_available.svg')
    this.addIconToRegistry('add', folderPath + 'add.svg')
    this.addIconToRegistry('addchart', folderPath + 'addchart.svg')
    this.addIconToRegistry('add_check', folderPath + 'add_check.svg')
    this.addIconToRegistry('add_circle', folderPath + 'add_circle.svg')
    this.addIconToRegistry('add_credit_card', folderPath + 'add_credit_card.svg')
    this.addIconToRegistry('add_email', folderPath + 'add_email.svg')
    this.addIconToRegistry('add_notes', folderPath + 'add_notes.svg')
    this.addIconToRegistry('add_payment', folderPath + 'add_payment.svg')
    // this.addIconToRegistry('add_to_home_screen', folderPath + 'add_to_home_screen.svg')
    this.addIconToRegistry('admin_panel_settings', folderPath + 'admin_panel_settings.svg')
    this.addIconToRegistry('analytics', folderPath + 'analytics.svg')
    this.addIconToRegistry('anchor', folderPath + 'anchor.svg')
    this.addIconToRegistry('apps', folderPath + 'apps.svg')
    this.addIconToRegistry('at', folderPath + 'at.svg')
    // this.addIconToRegistry('bed', folderPath + 'bed.svg')
    this.addIconToRegistry('balance', folderPath + 'balance.svg')
    this.addIconToRegistry('boss', folderPath + 'boss.svg')
    this.addIconToRegistry('broken_image', folderPath + 'broken_image.svg')
    this.addIconToRegistry('bug_report', folderPath + 'bug_report.svg')
    this.addIconToRegistry('building-construction', folderPath + 'building-construction.svg')
    this.addIconToRegistry('business', folderPath + 'business.svg')
    this.addIconToRegistry('cancel', folderPath + 'cancel.svg')
    this.addIconToRegistry('carpenter', folderPath + 'carpenter.svg')
    this.addIconToRegistry('chevron-down', folderPath + 'chevron-down.svg')
    this.addIconToRegistry('check_circle', folderPath + 'check_circle.svg')
    this.addIconToRegistry('check_euro', folderPath + 'check_euro.svg')
    this.addIconToRegistry('clear', folderPath + 'clear.svg')
    // this.addIconToRegistry('close', folderPath + 'close.svg')
    this.addIconToRegistry('cloud', folderPath + 'cloud.svg')
    this.addIconToRegistry('cloud_queue', folderPath + 'cloud_queue.svg')
    // this.addIconToRegistry('cloud_upload', folderPath + 'cloud_upload.svg')
    this.addIconToRegistry('columns', folderPath + 'columns.svg')
    this.addIconToRegistry('compass-outline', folderPath + 'compass-outline.svg')
    this.addIconToRegistry('contact_support', folderPath + 'contact_support.svg')
    this.addIconToRegistry('content_copy', folderPath + 'content_copy.svg')
    // this.addIconToRegistry('copyright', folderPath + 'copyright.svg')
    this.addIconToRegistry('credit_card', folderPath + 'credit_card.svg')
    this.addIconToRegistry('dashboard', folderPath + 'dashboard.svg')
    this.addIconToRegistry('database', folderPath + 'database.svg')
    this.addIconToRegistry('description', folderPath + 'description.svg')
    this.addIconToRegistry('devices', folderPath + 'devices.svg')
    this.addIconToRegistry('disabled_by_default', folderPath + 'disabled_by_default.svg')
    this.addIconToRegistry('diversity_3', folderPath + 'diversity_3.svg')
    this.addIconToRegistry('edit', folderPath + 'edit.svg')
    this.addIconToRegistry('edit-outline', folderPath + 'edit-outline.svg')
    this.addIconToRegistry('edit_user', folderPath + 'edit_user.svg')
    this.addIconToRegistry('email', folderPath + 'email.svg')
    this.addIconToRegistry('error', folderPath + 'error.svg')
    this.addIconToRegistry('engineering', folderPath + 'engineering.svg')
    this.addIconToRegistry('euro', folderPath + 'euro.svg')
    this.addIconToRegistry('event', folderPath + 'tomorrow.svg')
    this.addIconToRegistry('event_available', folderPath + 'event_available.svg')
    this.addIconToRegistry('event_repeat', folderPath + 'event_repeat.svg')
    this.addIconToRegistry('excel_logo', folderPath + 'excel_logo.svg')
    this.addIconToRegistry('facebook_circle', folderPath + 'facebook_circle.svg')
    this.addIconToRegistry('facebook-f', folderPath + 'facebook-f.svg')
    this.addIconToRegistry('facebook-line', folderPath + 'facebook-line.svg')
    // this.addIconToRegistry('family_restroom', folderPath + 'family_restroom.svg')
    this.addIconToRegistry('file-type-ai', folderPath + 'file-type-ai.svg')
    this.addIconToRegistry('file-type-android', folderPath + 'file-type-android.svg')
    this.addIconToRegistry('file-type-apk', folderPath + 'file-type-apk.svg')
    this.addIconToRegistry('file-type-css', folderPath + 'file-type-css.svg')
    this.addIconToRegistry('file-type-disc', folderPath + 'file-type-disc.svg')
    this.addIconToRegistry('file-type-doc', folderPath + 'file-type-doc.svg')
    this.addIconToRegistry('file-type-excel', folderPath + 'file-type-excel.svg')
    this.addIconToRegistry('file-type-font', folderPath + 'file-type-font.svg')
    this.addIconToRegistry('file-type-iso', folderPath + 'file-type-iso.svg')
    this.addIconToRegistry('file-type-javascript', folderPath + 'file-type-javascript.svg')
    this.addIconToRegistry('file-type-jpg', folderPath + 'file-type-jpg.svg')
    this.addIconToRegistry('file-type-js', folderPath + 'file-type-js.svg')
    this.addIconToRegistry('file-type-mail', folderPath + 'file-type-mail.svg')
    this.addIconToRegistry('file-type-mp3', folderPath + 'file-type-mp3.svg')
    this.addIconToRegistry('file-type-mp4', folderPath + 'file-type-mp4.svg')
    this.addIconToRegistry('file-type-music', folderPath + 'file-type-music.svg')
    this.addIconToRegistry('file-type-pdf', folderPath + 'file-type-pdf.svg')
    this.addIconToRegistry('file-type-php', folderPath + 'file-type-php.svg')
    this.addIconToRegistry('file-type-play', folderPath + 'file-type-play.svg')
    this.addIconToRegistry('file-type-powerpoint', folderPath + 'file-type-powerpoint.svg')
    this.addIconToRegistry('file-type-ppt', folderPath + 'file-type-ppt.svg')
    this.addIconToRegistry('file-type-psd', folderPath + 'file-type-psd.svg')
    this.addIconToRegistry('file-type-record', folderPath + 'file-type-record.svg')
    this.addIconToRegistry('file-type-sql', folderPath + 'file-type-sql.svg')
    this.addIconToRegistry('file-type-svg', folderPath + 'file-type-svg.svg')
    this.addIconToRegistry('file-type-text', folderPath + 'file-type-text.svg')
    this.addIconToRegistry('file-type-ttf', folderPath + 'file-type-ttf.svg')
    this.addIconToRegistry('file-type-txt', folderPath + 'file-type-txt.svg')
    this.addIconToRegistry('file-type-unknown', folderPath + 'file-type-unknown.svg')
    this.addIconToRegistry('file-type-vcf', folderPath + 'file-type-vcf.svg')
    this.addIconToRegistry('file-type-vector', folderPath + 'file-type-vector.svg')
    this.addIconToRegistry('file-type-video', folderPath + 'file-type-video.svg')
    this.addIconToRegistry('file-type-word', folderPath + 'file-type-word.svg')
    this.addIconToRegistry('file-type-xls', folderPath + 'file-type-xls.svg')
    this.addIconToRegistry('file-type-zip', folderPath + 'file-type-zip.svg')
    this.addIconToRegistry('file_attachment', folderPath + 'file_attachment.svg')
    this.addIconToRegistry('files', folderPath + 'files.svg')
    this.addIconToRegistry('filter_drama_black', folderPath + 'filter_drama_black.svg')
    this.addIconToRegistry('flare', folderPath + 'flare.svg')
    this.addIconToRegistry('flight_black', folderPath + 'flight_black.svg')
    // this.addIconToRegistry('folder_zip', folderPath + 'folder_zip.svg')
    this.addIconToRegistry('games', folderPath + 'games.svg')
    this.addIconToRegistry('get_app', folderPath + 'get_app.svg')
    this.addIconToRegistry('grid_view', folderPath + 'grid_view.svg')
    this.addIconToRegistry('hammer_and_saw', folderPath + 'hammer_and_saw.svg')
    this.addIconToRegistry('hangfire_icon', folderPath + 'hangfire_icon.svg')
    this.addIconToRegistry('headset', folderPath + 'headset.svg')
    this.addIconToRegistry('help', folderPath + 'help.svg')
    this.addIconToRegistry('home', folderPath + 'home.svg')
    this.addIconToRegistry('instagram_outline', folderPath + 'instagram_outline.svg')
    this.addIconToRegistry('instagram-line', folderPath + 'instagram-line.svg')
    this.addIconToRegistry('launch', folderPath + 'launch.svg')
    this.addIconToRegistry('light-shade', folderPath + 'light-shade.svg')
    this.addIconToRegistry('lightbulb-basic', folderPath + 'lightbulb-basic.svg')
    this.addIconToRegistry('lightbulb', folderPath + 'lightbulb.svg')
    this.addIconToRegistry('light-mode', folderPath + 'light-mode.svg')
    this.addIconToRegistry('list_alt', folderPath + 'list_alt.svg')
    this.addIconToRegistry('login', folderPath + 'login.svg')
    this.addIconToRegistry('logout', folderPath + 'logout.svg')
    this.addIconToRegistry('manage_accounts', folderPath + 'manage_accounts.svg')
    this.addIconToRegistry('menu', folderPath + 'menu.svg')
    this.addIconToRegistry('menu-book', folderPath + 'menu-book.svg')
    // this.addIconToRegistry('notes', folderPath + 'notes.svg')
    this.addIconToRegistry('nurse', folderPath + 'nurse.svg')
    this.addIconToRegistry('open_in_new', folderPath + 'open_in_new.svg')
    this.addIconToRegistry('order_approve', folderPath + 'order_approve.svg')
    this.addIconToRegistry('order_play', folderPath + 'order_play.svg')
    this.addIconToRegistry('overview', folderPath + 'overview.svg')
    // this.addIconToRegistry('palette', folderPath + 'palette.svg')
    this.addIconToRegistry('payments', folderPath + 'payments.svg')
    this.addIconToRegistry('people', folderPath + 'people.svg')
    this.addIconToRegistry('perm_media', folderPath + 'perm_media.svg')
    // this.addIconToRegistry('phone', folderPath + 'phone.svg')
    this.addIconToRegistry('photo', folderPath + 'photo.svg')
    this.addIconToRegistry('ppe', folderPath + 'ppe.svg')
    this.addIconToRegistry('products', folderPath + 'products.svg')
    this.addIconToRegistry('pwd-reset', folderPath + 'pwd-reset.svg')
    this.addIconToRegistry('quiz', folderPath + 'quiz.svg')
    this.addIconToRegistry('question-mark', folderPath + 'question_mark.svg')
    this.addIconToRegistry('refresh', folderPath + 'refresh.svg')
    this.addIconToRegistry('reset_pwd', folderPath + 'pwd-reset.svg')
    this.addIconToRegistry('run_circle', folderPath + 'run_circle.svg')
    this.addIconToRegistry('sanitizer', folderPath + 'sanitizer.svg')
    this.addIconToRegistry('schedule', folderPath + 'schedule.svg')
    this.addIconToRegistry('school', folderPath + 'school.svg')
    this.addIconToRegistry('search', folderPath + 'search.svg')
    this.addIconToRegistry('search_off', folderPath + 'search_off.svg')
    this.addIconToRegistry('set_square', folderPath + 'set_square.svg')
    this.addIconToRegistry('settings', folderPath + 'settings.svg')
    this.addIconToRegistry('settings_suggest', folderPath + 'settings_suggest.svg')
    this.addIconToRegistry('subscriptions', folderPath + 'subscriptions.svg')
    this.addIconToRegistry('superman', folderPath + 'superman.svg')
    this.addIconToRegistry('swagger', folderPath + 'swagger.svg')
    this.addIconToRegistry('table', folderPath + 'table.svg')
    this.addIconToRegistry('team', folderPath + 'team.svg')
    this.addIconToRegistry('team-filled', folderPath + 'team-filled.svg')
    this.addIconToRegistry('team-outline', folderPath + 'team-outline.svg')
    this.addIconToRegistry('thumb_down', folderPath + 'thumb_down.svg')
    this.addIconToRegistry('thumb_up', folderPath + 'thumb_up.svg')
    this.addIconToRegistry('today', folderPath + 'today.svg')
    this.addIconToRegistry('tomorrow', folderPath + 'tomorrow.svg')
    this.addIconToRegistry('touch_app', folderPath + 'touch_app.svg')
    this.addIconToRegistry('tune', folderPath + 'tune.svg')
    this.addIconToRegistry('twitter_bird', folderPath + 'twitter_bird.svg')
    this.addIconToRegistry('twitter-line', folderPath + 'twitter-line.svg')
    this.addIconToRegistry('unfold_more', folderPath + 'unfold_more.svg')
    this.addIconToRegistry('view_carousel', folderPath + 'view_carousel.svg')
    this.addIconToRegistry('vpn_key', folderPath + 'vpn_key.svg')
    this.addIconToRegistry('wifi_off', folderPath + 'wifi_off.svg')
    this.addIconToRegistry('window', folderPath + 'window.svg')
    this.addIconToRegistry('window-open', folderPath + 'window-open.svg')

    this.addIconToRegistry('person_add copy', folderPath + 'person_add copy.svg')


    // console.log('Icons Count',  this._registeredIconSet.size)
  } //registerAllIcons

  //----------------------------------------------------------------------------//
} //Cls

//======================================================================================================//

export class SvgIcon {
  /**
   * Class for encapsulating an SVG icon file.
   * @param title Name of Icon: This will be used in the HTML to identify the icon
   * @param path Where is the image located
   */
  constructor(public title: string, public path: string) { } //ctor
} //Cls

//======================================================================================================//

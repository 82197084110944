import { INameDescId } from "@inigo/data/dtos"
import { Identifier } from "@inigo/data/misc"
import { FilterListItem } from "@inigo/pagination"

export class FilterListHelpers{
    
   static  pushIfUnique(
        filterList: FilterListItem[],
        item: INameDescId,
        valueSelector: (item: any) => Identifier = (item) => item.id,
        viewValueSelector: (item: any) => string | undefined = (item) => item.name
      ) {
    
        const idx = filterList.findIndex(
          c => c.viewValue === viewValueSelector(item)
        )
    
        if (idx < 0)
          filterList.push(new FilterListItem(valueSelector(item), viewValueSelector(item)))
    
      }//pushIfUnique
}
export class InsertingDto<T> {
  /**
   * The new database entry
   */
  public entry: T;

  /**
   * Insert without checking - Default = false
   */
  public skipDuplicateCheck = false;

  //----------------------------------------------------//

  constructor(newEntry: T, skipDuplicateCheck = false) {
    this.entry = newEntry;
    this.skipDuplicateCheck = skipDuplicateCheck;
  } //ctor

  //----------------------------------------------------//
} //Cls

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AUTH_HEADER, TOKEN_PREFIX } from '@inigo/authentication/config';
import { AuthMiniService } from '@inigo/authentication/core';
import { Jwt } from '@inigo/authentication/jwt';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private _authService = inject(AuthMiniService)

  //-----------------------------------------------------------------------//

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // console.log('intercept 2', req.url);


    const reqWithAuth = this.addAuthHeader(req);

    return next.handle(reqWithAuth)

  } //intercept

  //-----------------------------------------------------------------------//

  /**Adds the JWT (if there is one) to the Auth Header */
  protected addAuthHeader(request: HttpRequest<any>) {

    const auth: Jwt | null = this._authService.getJwt()

    if (auth == null)
      return request;

    return request.clone({
      headers: request.headers.set(
        AUTH_HEADER,
        TOKEN_PREFIX + auth.accessToken
      ),
    })

  } //addAuthHeader

} //Cls

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { debounceTime, delayWhen, filter, interval, map, of, switchMap, tap } from 'rxjs';
import { CoreConfig, CoreConfigService } from '../../core-config';

@Component({
  selector: 'inigo-lazy-loading-indicator',
  templateUrl: './lazy-loading-indicator.component.html',
  styleUrls: ['./lazy-loading-indicator.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LazyLoadingIndicatorComponent {


  private _router = inject(Router)
  private _coreConfig: CoreConfig = inject(CoreConfigService)

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  @Input('colorBg') _colorBg?: string; //= 'lime'
  @Input('colorTxt') _colorTxt?: string; // = 'black'

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  private _showIndicator$ = this._router.events
  .pipe(
    filter(event => event instanceof NavigationStart || event instanceof NavigationEnd),
    debounceTime(500),
    map((event) => event instanceof NavigationStart && !this.skipEvent(event)
    )
  )

  protected _showLazyLoadingIndicator = toSignal(this._showIndicator$)

  private _routePathsToIgnore = new Set<string>()

  //--------------------------------------------------------//

  constructor() {

    this._coreConfig.lazyRoutesToIgnore.forEach((uti) =>
      this._routePathsToIgnore.add(uti.replace(/^\/+/g, '').trim().toLowerCase())
    )

  } //ctor

  //--------------------------------------------------------//

  /**
   * Should we skip the loader for this event
   */
  private skipEvent(routeEvent: NavigationStart): boolean {

    const url = routeEvent.url.replace(/^\/+/g, '').trim()?.toLowerCase()

    if (url && this._routePathsToIgnore.has(url))
      return true

    return false

  } //skipUrl

  //--------------------------------------------------------//
} //Cls

import { InjectionToken } from '@angular/core';
import { BouncerConfig } from '@inigo/my-loaders/bouncer';
import { DotSpinnerConfig } from '@inigo/my-loaders/dot-spinner';
import { TrouncerConfig } from '@inigo/my-loaders/trouncer';

//===================================================================//

/**The type of spinner to show when making */
export type LoadingSpinnerType = 'dots' | 'bouncer' | 'trouncer' | 'none';

//===================================================================//

export const MyLoadersConfigService = new InjectionToken<MyLoadersConfig>(
  'MyLoadersConfig'
);

//===================================================================//

export class MyLoadersConfig {
  /**
   * Configure all types of progress spinners
   * Enter no paramaters to use default values on everything
   * @param trouncerConfig  Config for the Trouncer Module
   * @param bouncerConfig   Config for the Bouncer Module
   * @param dotSpinnerConfigConfig for the Dot Spinner Module
   */
  private constructor(
    public trouncerConfig: TrouncerConfig = TrouncerConfig.Create(),
    public bouncerConfig: BouncerConfig = BouncerConfig.Create(),
    public dotSpinnerConfig: DotSpinnerConfig = DotSpinnerConfig.Create()
  ) {} //ctor

  //---------------------------------------------------------------------//
  /**
   * Create new instance of SpinnerConfig
   * Configure all types of progress spinners
   * Enter no paramaters to use default values on everything
   * @param trouncerConfig  Config for the Trouncer Module
   * @param bouncerConfig   Config for the Bouncer Module
   * @param dotSpinnerConfigConfig for the Dot Spinner Module
   */
  static Create(
    trouncerConfig: TrouncerConfig = TrouncerConfig.Create(),
    bouncerConfig: BouncerConfig = BouncerConfig.Create(),
    dotSpinnerConfig: DotSpinnerConfig = DotSpinnerConfig.Create()
  ): MyLoadersConfig {
    return new MyLoadersConfig(
      trouncerConfig,
      bouncerConfig,
      dotSpinnerConfig
    );
  } //create

  //---------------------------------------------------------------------//
} //Cls

import { Injectable } from '@angular/core';
import { DotSpinnerComponent } from '../../../components/spinners/dot-spinner/dot-spinner.component';
import { DialogRef } from '../../../utility/dialog-ref';
import { BaseSpinner } from '../base-spinner';
import { ISpinner } from '../i-spinner';

@Injectable({
  providedIn: 'root',
})
export class DotSpinnerService extends BaseSpinner implements ISpinner {

  public open = (): DialogRef | undefined =>
    this.openSpinner(DotSpinnerComponent)

} //Cls

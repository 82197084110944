import { RegExes } from "@inigo/gen-helpers/text";

//================================================================================//

export function IsNumber(n: any) { return RegExes.IsNumber.test(n); }

//================================================================================//

/**
 * Makes sure that the numer is an integer between @param bottom and @param top (if supplied)
 * @param value Value to sanitize
 * @param bottom Lower limit
 * @param top Upper limit
 * @returns Sanitized integer
 */
export function SafeInteger(value?: number | null, bottom: number | null | undefined = null, top: number | null | undefined = null): number {

    if (!value)
        return bottom ?? 0

    if (!IsNumber(value))
        return bottom ?? 0

    const int = Math.round(value)

    if (bottom && int < bottom)
        return bottom


    if (top && int > top)
        return top

    return int

}//SafeInteger


//================================================================================//

/**
 * Makes sure that the numer is between @param bottom and @param top (if supplied)
 * @param value Value to sanitize
 * @param bottom Lower limit
 * @param top Upper limit
 * @returns Sanitized number
 */
export function SafeNumber(value?: number | null, bottom: number | null | undefined = null, top: number | null | undefined = null): number {

    if (!value)
        return bottom ?? 0

    if (!IsNumber(value))
        return bottom ?? 0

    if (bottom && value < bottom)
        return bottom


    if (top && value > top)
        return top

    return value

}//SafeNumber


//================================================================================//


export function mod(n: number, m: number) {
    return ((n % m) + m) % m;
}

//================================================================================//


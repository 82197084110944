import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoaderBouncerModule } from '@inigo/my-loaders/bouncer';
import { LoaderDotSpinnerModule } from '@inigo/my-loaders/dot-spinner';
import { LoaderTrouncerModule } from '@inigo/my-loaders/trouncer';
import { MyLoadersConfig, MyLoadersConfigService } from './spinners-config';

@NgModule({
  imports: [ LoaderBouncerModule, LoaderTrouncerModule, LoaderDotSpinnerModule],
  exports: [LoaderBouncerModule, LoaderTrouncerModule, LoaderDotSpinnerModule],
})
export class MyLoadersModule {


  /**
  * Enter m no paramaters to use default values on everything
  * @param config Configration file that contains the connfigurations for the
  * 3 submodules (BouncerModule, TrouncerModule & DotSpinnerModule)
  */
  static configure(config: MyLoadersConfig = MyLoadersConfig.Create()): ModuleWithProviders<MyLoadersModule> {
    return {
      ngModule: MyLoadersModule,
      providers: [
        {
          provide: MyLoadersConfigService,
          useValue: config,
        },
        LoaderBouncerModule.configure(config.bouncerConfig).providers!,
        LoaderTrouncerModule.configure(config.trouncerConfig).providers!,
        LoaderDotSpinnerModule.configure(config.dotSpinnerConfig).providers!,
      ],
    };
  } //forRoot


}

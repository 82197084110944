import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagePreloaderService {

  private _platformId = inject(PLATFORM_ID)

  //------------------------------------------//

  private preloadList: HTMLImageElement[] = []

  //------------------------------------------//

  preload(imgUrls?: string[]) {

    //There's no reason to preload on  the server
    if (isPlatformServer(this._platformId))
      return


    if (!imgUrls?.length)
      return

    if (!this.preloadList?.length)
      this.preloadList = []
    var list = this.preloadList


    for (var i = 0; i < imgUrls.length; i++) {

      var img = new Image()

      img.onload = () => {
        var index = list.indexOf(img)

        // remove image from the array once it's loaded for memory consumption reasons
        if (index !== -1)
          list.splice(index, 1)
      }

      list.push(img)
      img.src = imgUrls[i]

    } //for

  } //preloadImages

  //------------------------------------------//

} //Cls

import { Component, Inject } from '@angular/core';
import { DialogConfig } from '../../../utility/dialog-config';
import { DialogRef } from '../../../utility/dialog-ref';
import {
  PopUpConfigService,
  PopUpConfig,
} from '../../../setup/pop-up-config';
import { TrouncerConfig } from '@inigo/my-loaders/trouncer';

@Component({
  selector: 'inigo-trouncer',
  templateUrl: './trouncer.component.html',
  styleUrls: ['./trouncer.component.scss'],
})
export class TrouncerComponent {
  //This will be used to set the Inputs of TrouncerComponent rather than through the forRoot Config
  //That was this won't interfere with another use of the TrouncerModule
  _config: TrouncerConfig;

  //--------------------------------------------------------//

  constructor(
    public dialogConfig: DialogConfig,
    public dialogRef: DialogRef,
    @Inject(PopUpConfigService) popUpConfig: PopUpConfig
  ) {
    this._config = popUpConfig.trouncerConfig;
    // console.log(dialogRef);
    
  } //ctor

  //--------------------------------------------------------//
} //Cls

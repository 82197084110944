import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation, withPreloading, } from '@angular/router';
import { FromAppHeaderInterceptor } from '@inigo/authentication/interceptors';
import { InigoCoreModule } from '@inigo/core';
import { LoaderTrouncerModule } from '@inigo/my-loaders/trouncer';
import { StripeHelpersSetup } from '@inigo/stripe-helpers/config';
import { SwHelpersModule } from '@inigo/sw-helpers';
import { provideNgxStripe } from 'ngx-stripe';
import { CORE_CONFIG, TROUNCER_CONFIG } from './app-config';
import { Secrets } from './app-secrets';
import { appRoutes } from './app.routes';
import { DelayPreloadingStrategy } from '@inigo/lazy-load-helpers/preload';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withPreloading(DelayPreloadingStrategy)
    ),
    StripeHelpersSetup.getProvidersBasic(Secrets.stripeKey),
    provideNgxStripe(Secrets.stripeKey),
    importProvidersFrom(LoaderTrouncerModule.configure(TROUNCER_CONFIG)),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FromAppHeaderInterceptor,
      multi: true,
    },
    importProvidersFrom(InigoCoreModule.configure(CORE_CONFIG)),
    importProvidersFrom(SwHelpersModule.configure())
  ],
};

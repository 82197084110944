import { FormControl, FormGroup } from '@angular/forms';
import { Identifier } from '@inigo/data/misc';
import { Dimension } from './dimension';
import { MeasurementDef } from './measurementDef';
import { Product } from './product';

export class DimensionDefinition {
  /** Database Identifier */
  public id: Identifier;

  /** More info */
  public description?: string;

  /** Display name */
  public name?: string;

  public measurementDef1Id: Identifier;
  public measurementDef1?: MeasurementDef;

  public measurementDef2Id: Identifier;
  public measurementDef2?: MeasurementDef;

  public measurementDef3Id: Identifier;
  public measurementDef3?: MeasurementDef;

  public measurementDef4Id: Identifier;
  public measurementDef4?: MeasurementDef;


  public productId: Identifier;
  public product?: Product;

  //-------------------------------------------------------------//

  public toStringMeasurements() {
    let msrs: string[] = [];
    if (this.measurementDef1) msrs.push(this.measurementDef1.display ?? '0');

    if (this.measurementDef2) msrs.push(` x ${this.measurementDef2.display}`);

    if (this.measurementDef3) msrs.push(` x ${this.measurementDef3.display}`);

    if (this.measurementDef4) msrs.push(` x ${this.measurementDef4.display}`);
  } //toStringMeasurements

  //-------------------------------------------------------------//

  /**
   * Create empty Dimension for 'New' form
   * @returns Empty Dimension
   */
  static Create(): DimensionDefinition {
    const dmn = new DimensionDefinition();

    return dmn;
  } //Create

  //-------------------------------------------------------------//

  static ToEmptyDimension(def?: DimensionDefinition): Dimension {
    const dmn = Dimension.Create();

    if (!def) return dmn;

    dmn.definitionId = def.id;
    dmn.definition = def;

    // dmn.measurement1Value = 0
    // dmn.measurement2Value = !!def.measurementDef2 ? 0 : undefined
    // dmn.measurement3Value = !!def.measurementDef3 ? 0 : undefined
    // dmn.measurement4Value = !!def.measurementDef4 ? 0 : undefined

    return dmn

  } //ToEmptyDimension

  //-------------------------------------------------------------//

  /**
   * Copy DimensionDefinition
   * @returns Empty DimensionDefinition
   */
  static Copy(original?: DimensionDefinition | null): DimensionDefinition {
    const dmn = new DimensionDefinition();

    if (!original) return dmn;

    dmn.description = original.description;
    dmn.id = original.id;
    dmn.name = original.name;
    dmn.measurementDef1Id = original.measurementDef1Id;
    dmn.measurementDef2Id = original.measurementDef2Id;
    dmn.measurementDef3Id = original.measurementDef3Id;
    dmn.measurementDef4Id = original.measurementDef4Id;
    dmn.measurementDef1Id = original.measurementDef1Id;
    dmn.measurementDef1Id = original.measurementDef1Id;
    dmn.measurementDef1 = original.measurementDef1;
    dmn.measurementDef2 = original.measurementDef2;
    dmn.measurementDef3 = original.measurementDef3;
    dmn.measurementDef4 = original.measurementDef4;

    return dmn;
  } //Create

  //-------------------------------------------------------------//
} //Cls

//=========================================================================//

export interface IDimensionDefForm
  extends FormGroup<{
    id: FormControl<Identifier>;
    name: FormControl<string | null>;
    description: FormControl<string | null>;
    msrDef1Id: FormControl<Identifier>;
    msrDef2Id: FormControl<Identifier>;
    msrDef3Id: FormControl<Identifier>;
    msrDef4Id: FormControl<Identifier>;
  }> { }

//=========================================================================//

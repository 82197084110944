export class ArgumentNullError extends Error {

    /** The name of the parameter that caused the exception. */
    paramName: string


    /**
     * @param paramName The name of the parameter that caused the exception.
     * @param message The error message that explains the reason for this exception.
     */
    constructor(paramName: string, message: string = 'Argument Null Error') {
        super(message)
        this.paramName = paramName
    }//ctor

}//Cls
import { Injectable } from '@angular/core';
import { Identifier } from '@inigo/data/misc';
import { ToFormData } from '@inigo/gen-helpers/objects';
import { APagePkgHttpService } from '@inigo/my-store';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { AppActions } from '../../../../app-actions';
import { AppControllers } from '../../../../app-controllers';
import { BulkUploadDto } from '../../../dtos/bulk-upload';
import { ProductPagePackage } from '../../../dtos/prod-page-package';
import { Product } from '../../../dtos/product';
import { ProductDocumentDeleteDto } from '../../../dtos/product-document-delete-dto';
import { ProductUpload } from '../../../dtos/product-upload';
import { ShopDocument } from '../../../dtos/shop-doc';

@Injectable({
  providedIn: 'root',
})
export abstract class AProductHttpService extends APagePkgHttpService<Product, ProductUpload, ProductPagePackage> {

  //---------------------------------------------------------------------//

  constructor() {
    super();
    this.url = AppControllers.FullPath.PRODUCTS
  } //ctor

  //---------------------------------------------------------------------//

  addBulk = (dto: BulkUploadDto): Observable<Blob> =>
    this.postAction(AppActions.Products.ADD_BULK, ToFormData(dto), { responseType: 'blob' }).pipe(
      timeout(600000)
    );

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  appendGallery = (prodId: Identifier, gallery: File[], opts: any = null): Observable<Product> =>
    this.postAction(`${AppActions.Products.APPEND_GALLERY}/${prodId}`, ToFormData(gallery), opts ?? {});

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Delete certain docs from product
   */
  deleteDocs = (dto: ProductDocumentDeleteDto, opts: any = null): Observable<Product> =>
    this.postAction(AppActions.Products.DELETE_DOCUMENTS, dto, opts ?? {});

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Delete certain imgs from product gallery
   */
  deleteGalleryImages = (dto: ProductDocumentDeleteDto, opts: any = null): Observable<Product> =>
    this.postAction(AppActions.Products.DELETE_GALLERY_IMAGES, dto, opts ?? {});

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  downloadDoc = (doc: ShopDocument, opts: any = null) =>
    this.postAction(AppActions.Products.DOWNLOAD_DOC, doc, opts ?? {});

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  getAllByDept = (dept: string, opts: any = null): Observable<Product[]> =>
    this.getAllAction(`getAll/${dept}`, opts ?? {});

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  // override getTemplate = (id: Identifier, opts?: any): Observable<Blob> =>
  //   this.getAction(AppActions.Products.GET_TEMPLATE, { responseType: 'blob' });

  //---------------------------------------------------------------------//
} //service

import { InjectionToken } from '@angular/core';
import { AccRoutes } from '@inigo/acc-n-auth-data/routes';
import { AuthConfig } from '@inigo/authentication/config';
import { ContactConfig } from "@inigo/contact/config";
import { StringHelpers } from '@inigo/gen-helpers/text';
import { HangfireConfig } from '@inigo/hangfire-helpers/config';
import { HttpServicesConfig } from '@inigo/http-services/config';
import { LoadingSpinnerType, MyLoadersConfig } from '@inigo/my-loaders';
import { PopUpConfig } from '@inigo/pop-up';
import { ThemeConfig } from '@inigo/theme-and-mode/config';
import { ToastConfig } from '@inigo/toast';

//===================================================================//

export const CoreConfigService = new InjectionToken<CoreConfig>('CoreConfig')

//===================================================================//

export class CoreConfig {

  private _themeConfig: ThemeConfig = ThemeConfig.Create()
  /** Configuration for themeentication (routes, urls, roleName Prefixes...): - Default = new themeConfig() */
  public get themeConfig(): ThemeConfig {
    return this._themeConfig
  }

  private _authConfig: AuthConfig
  /** Configuration for authentication (routes, urls, roleName Prefixes...): - Default = new AuthConfig() */
  public get authConfig(): AuthConfig {
    return this._authConfig
  }

  private _isDev: boolean = false
  /** Are we in Dev Mode? */
  public get isDev(): boolean {
    return this._isDev
  }

  private _httpServicesConfig: HttpServicesConfig = HttpServicesConfig.Create()
  /** Configuration for base data service and error reporting service: -  Default = new HttpServicesConfig() */
  public get httpServicesConfig(): HttpServicesConfig {
    return this._httpServicesConfig
  }

  private _contactConfig: ContactConfig
  /** Configuration contct details/form/mapo etc: -  Default = none */
  public get contactConfig(): ContactConfig {
    return this._contactConfig
  }

  private _hangfireConfig: HangfireConfig = HangfireConfig.Create()
  /** Configuration hangfire : -  Default = none */
  public get hangfireConfig(): HangfireConfig {
    return this._hangfireConfig
  }

  private _loadingSpinnerType: LoadingSpinnerType = 'bouncer'
  /** Type of spinner eveerytime we make a http request. Default = 'bouncer' */
  public get loadingSpinnerType(): LoadingSpinnerType {
    return this._loadingSpinnerType
  }

  private _lazyRoutesToIgnore: string[] = [
    'home',
    'NotFound',
  ]
  /** Collection of Component Names that the LAZY LOADING INDICATOR interceptor should ignore -  Default = ['HomeComponent', 'NotFoundComponent']*/
  public get lazyRoutesToIgnore(): string[] {
    return this._lazyRoutesToIgnore
  }

  private _loginRelativePath = `${AccRoutes.ACCOUNT_FEATURES}/${AccRoutes.LOGIN}`
  /** Route for login page - default: 'accounts/login' */
  public get loginRelativePath(): string {
    return StringHelpers.Clone(this._loginRelativePath)
  }

  private _navUrlsToIgnore: string[] = []
  /** Collection of urls (or partial urls) that the NAV progress loader interceptor should ignore -  Default = []*/
  public get navUrlsToIgnore(): string[] {
    return this._navUrlsToIgnore
  }

  private _myLoadersConfig: MyLoadersConfig = MyLoadersConfig.Create()
  /** Configuration for popup dialogs and spinners: - Default = new MyLoadersConfig() */
  public get myLoadersConfig(): MyLoadersConfig {
    return this._myLoadersConfig
  }

  private _popUpConfig: PopUpConfig = PopUpConfig.Create()
  /** Configuration for popup dialogs and spinners: - Default = new PopUpConfig() */
  public get popUpConfig(): PopUpConfig {
    return this._popUpConfig
  }

  private _skipEmailErrors = false
  /** Don't email the error report. Default = false*/
  public get skipEmailErrors(): boolean {
    return this._skipEmailErrors
  }

  private _toastConfig: ToastConfig = ToastConfig.Create()
  /** Configuration for toast notifications (Position, Animation Duration ...): -  Default = new ToastConfig() */
  public get toastConfig(): ToastConfig {
    return this._toastConfig
  }

  private _useLoadingInterceptor = true
  /** Whether to automatically use a spinner everytime we make a http request. Default = true*/
  public get useLoadingInterceptor(): boolean {
    return this._useLoadingInterceptor
  }

  private _urlsToIgnore: string[] = []
  /** Collection of urls (or partial urls) that the progress loader interceptor should ignore -  Default = []*/
  public get urlsToIgnore(): string[] {
    return this._urlsToIgnore
  }

  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * Enter no paramaters to use default values on everything
   * @param authConfig Configuration for authentication (routes, urls, roleName Prefixes...): - default new AuthConfig()
   * @param popUpConfig Configuration for popup dialogs and spinners: - default new PopUpConfig()
   * @param toastConfig Configuration for toast notifications (Position, Animation Duration ...): - default new ToastConfig()
   * @param contactConfig Configuration contact details/form/mapo etc: -  Default = none 
   * @param urlsToIgnore Collection of urls (or partial urls) that the progress loader interceptor should ignore - default []
   * @param httpServicesConfig Configuration for base data service and error reporting service: - default new HttpServicesConfig()
   * @param loginRelativePath Route for login page - default: '/login' (Will use authConfig.loginRoute if this is null and authConfig.loginroute != null)
   * @param useLoadingInterceptor Whether to automatically use a spinner eveerytime we make a http request - default true
   * @param loadingSpinnerType Type of spinner eveerytime we make a http request - default 'bouncer
   * @param navUrlsToIgnore Collection of urls (or partial urls) that the NAV progress loader interceptor should ignore - default []
   * @param lazyComponentNamesToIgnore Collection of Component Names that the LAZY LOADING INDICATOR interceptor should ignore - default ['HomeComponent', 'NotFoundComponent']
   */
  constructor(
    authConfig: AuthConfig,
    contactConfig: ContactConfig,
    popUpConfig?: PopUpConfig,
    toastConfig?: ToastConfig,
    urlsToIgnore?: string[],
    httpServicesConfig?: HttpServicesConfig,
    loginRelativePath?: string,
    useLoadingInterceptor?: boolean,
    loadingSpinnerType: LoadingSpinnerType = 'bouncer',
    navUrlsToIgnore?: string[],
    lazyComponentNamesToIgnore?: string[]
  ) {

    this._authConfig = authConfig

    if (loginRelativePath)
      this._loginRelativePath = loginRelativePath
    else if (authConfig?.loginRelativePath)
      this._loginRelativePath = authConfig.loginRelativePath

    if (popUpConfig)
      this._popUpConfig = popUpConfig

    if (httpServicesConfig)
      this._httpServicesConfig = httpServicesConfig

    if (toastConfig)
      this._toastConfig = toastConfig

    if (authConfig)
      this._authConfig = authConfig

    this._contactConfig = contactConfig

    if (useLoadingInterceptor)
      this._useLoadingInterceptor = useLoadingInterceptor

    if (loadingSpinnerType)
      this._loadingSpinnerType = loadingSpinnerType

    if (urlsToIgnore)
      this._urlsToIgnore = urlsToIgnore

    if (navUrlsToIgnore)
      this._navUrlsToIgnore = navUrlsToIgnore

    if (lazyComponentNamesToIgnore)
      this._lazyRoutesToIgnore = lazyComponentNamesToIgnore

  } //ctor

  //---------------------------------------------------------------------//

  /**
   * Create new instance of CoreConfig
   * @param authConfig Configuration for authentication (routes, urls, roleName Prefixes...): - default new AuthConfig()
   */
  static Create(authConfig: AuthConfig, contactConfig: ContactConfig): CoreConfig {

    return new CoreConfig(authConfig, contactConfig)

  } //create

  //---------------------------------------------------------------------//

  /**
   * @param authConfig Configuration for authentication (routes, urls, roleName Prefixes...). Default = new AuthConfig()
   * @returns Updated CoreConfig
   */
  setAuthConfig(authConfig: AuthConfig): CoreConfig {
    this._authConfig = authConfig
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param contactConfig Configuration contact details/form/mapo etc: -  Default = none 
   * @returns Updated CoreConfig
   */
  setContactConfig(contactConfig: ContactConfig): CoreConfig {
    this._contactConfig = contactConfig
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param hangfireConfig Configuration hangfire details: -  Default = none 
   * @returns Updated CoreConfig
   */
  setHangfireConfig(hangfireConfig: HangfireConfig): CoreConfig {
    console.log();

    this._hangfireConfig = hangfireConfig
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param httpServicesConfig Configuration for base data service and error reporting service. Default = new HttpServicesConfig()
   * @returns Updated CoreConfig
   */
  setHttpServicesConfig(httpServicesConfig: HttpServicesConfig): CoreConfig {

    this._httpServicesConfig = httpServicesConfig
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param lazyRoutesToIgnore Collection of Component Names that the LAZY LOADING INDICATOR interceptor should ignore. Default = ['HomeComponent', 'NotFoundComponent']
   * @returns Updated CoreConfig
   */
  setLazyComponentNamesToIgnore(lazyRoutesToIgnore: string[]): CoreConfig {
    this._lazyRoutesToIgnore = lazyRoutesToIgnore
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param loadingSpinnerType Type of spinner eveerytime we make a http request.  Default = 'bouncer'
   * @returns Updated CoreConfig
   */
  setLoadingSpinnerType(loadingSpinnerType: LoadingSpinnerType): CoreConfig {
    this._loadingSpinnerType = loadingSpinnerType
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param loginRelativePath  Where to go after access is denied. Relative to the parent route.  Default: 'accounts/login'
   * @returns Updated CoreConfig
   */
  setLoginRelativePath(loginRelativePath: string): CoreConfig {
    this._loginRelativePath = loginRelativePath
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param navUrlsToIgnore Collection of urls (or partial urls) that the NAV progress loader interceptor should ignore. Default = []
   * @returns Updated CoreConfig
   */
  setNavUrlsToIgnore(navUrlsToIgnore: string[]): CoreConfig {
    this._navUrlsToIgnore = navUrlsToIgnore
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param myLoadersConfig Configuration for loaderss and spinners. Default = new MyLoadersConfig()
   * @returns Updated CoreConfig
   */
  setMyLoadersConfig(myLoadersConfig: MyLoadersConfig): CoreConfig {
    this._myLoadersConfig = myLoadersConfig
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param popUpConfig Configuration for popup dialogs and spinners. Default = new PopUpConfig()
   * @returns Updated CoreConfig
   */
  setPopUpConfig(popUpConfig: PopUpConfig): CoreConfig {
    this._popUpConfig = popUpConfig
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param themeConfig Configuration for themeentication (routes, urls, roleName Prefixes...). Default = new ThemeConfig()
   * @returns Updated CoreConfig
   */
  setThemeConfig(themeConfig: ThemeConfig): CoreConfig {
    this._themeConfig = themeConfig
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param useLoadingInterceptor Whether to automatically use a spinner everytime we make a http request. Default = true
   * @returns Updated CoreConfig
   */
  setUseLoadingInterceptor(useLoadingInterceptor: boolean): CoreConfig {
    this._useLoadingInterceptor = useLoadingInterceptor
    return this
  }
  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param skipEmailErrors  Don't email the error report. Default = false
   * @returns Updated CoreConfig
   */
  setSkipEmailErrors(skipEmailErrors: boolean): CoreConfig {
    this._skipEmailErrors = skipEmailErrors
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param toastConfig Configuration for toast notifications (Position, Animation Duration ...). Default = new ToastConfig()
   * @returns Updated CoreConfig
   */
  setToastConfig(toastConfig: ToastConfig): CoreConfig {
    this._toastConfig = toastConfig
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param urlsToIgnore Collection of urls (or partial urls) that the progress loader interceptor should ignore. Default = []
   * @returns Updated CoreConfig
   */
  setUrlsToIgnore(urlsToIgnore: string[]): CoreConfig {
    // console.log(urlsToIgnore)

    this._urlsToIgnore = urlsToIgnore
    return this
  }

  //---------------------------------------------------------------------//
} //Cls

export class ToastConstants {
  static ColorBgError = '#f44336';
  static ColorBgWarn = '#ffcc00';
  static ColorBgSuccess = '#c4ecff';
  static ColorBgInfo = '#e2ffa8';
  static ColorBgDefault = '#fff';
  static ColorText = '#000';

  static IconNameError = 'error';
  static IconNameWarn = 'warning';
  static IconNameSuccess = 'done';
  static IconNameInfo = 'info';
  static IconNameDefault = 'info';
} //Cls

import { EnvironmentProviders, Provider } from "@angular/core"
import { NotFoundConfig, NotFoundConfigService } from "./config"

export class NotFoundSetup {

    static getProviders(config: NotFoundConfig = new NotFoundConfig()): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: NotFoundConfigService,
                useValue: config,
            }
        ]

    } //getProviders

} //Cls
import { Injectable } from '@angular/core';
import { Identifier } from '@inigo/data/misc';
import { ToFormData } from '@inigo/gen-helpers/objects';
import { APagePkgHttpService } from '@inigo/my-store';
import { PagedRequest, PagedResponse } from '@inigo/pagination';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { AppActions } from '../../../../app-actions';
import { AppControllers } from '../../../../app-controllers';
import { BulkUploadDto } from '../../../dtos/bulk-upload';
import { ProductVariant } from '../../../dtos/variant';
import { VariantByProductPagePackage } from '../../../dtos/vars-by-prod-page-package';

@Injectable({
  providedIn: 'root',
})
export class AdminVariantHttpService extends APagePkgHttpService<ProductVariant, ProductVariant, VariantByProductPagePackage> {

  //---------------------------------------------------------------------//

  constructor() {
    super();
    this.url = AppControllers.FullPath.VARIANTS
  } //ctor

  //---------------------------------------------------------------------//

  addBulk = (dto: BulkUploadDto): Observable<Blob> =>
    this.postAction(AppActions.Variants.ADD_BULK, ToFormData(dto), { responseType: 'blob' }).pipe(
      timeout(600000)
    );

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  addBulkMulti = (dto: BulkUploadDto): Observable<Blob> =>
  this.postAction(AppActions.Variants.ADD_BULK_MULTI, ToFormData(dto), { responseType: 'blob' }).pipe(
    timeout(600000)
  );

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  override getAllItemsPaged = (pagedRequest: PagedRequest, prodId: Identifier, opts = {}): Observable<PagedResponse<ProductVariant>> =>
    this.getPageActionById(AppActions.Variants.PAGE_BY_PRODUCT, prodId, pagedRequest, opts);

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  override getAllItemsPagePkg = (pagedRequest: PagedRequest, prodId: Identifier, opts = {}): Observable<VariantByProductPagePackage> =>
    this.getPageActionById<VariantByProductPagePackage>(
      AppActions.Variants.PAGE_PACKAGE_BY_PRODUCT_ADMIN,
      prodId,
      pagedRequest,
      opts
    );

  //---------------------------------------------------------------------//

} //service

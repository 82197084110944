import { InjectionToken } from '@angular/core';
import { StringHelpers } from '@inigo/gen-helpers/text';

export const NotFoundConfigService = new InjectionToken<NotFoundConfig>('NotFoundConfig')
export const HOME_ROUTE_DEFAULT = '/'
export type NotFoundTheme = 'primary' | 'accent' | 'warn' | 'mixed' | 'mixed-inverted' | 'none'

//===================================================================//

export class NotFoundConfig {

  private _homeRoute = HOME_ROUTE_DEFAULT
  /** Route for to go to next - default: '/' */
  public get homeRoute(): string { return StringHelpers.Clone(this._homeRoute) }

  private _buttHomeText = 'Home'
  /** Go Home button text - default: 'Home' */
  public get buttHomeText(): string { return StringHelpers.Clone(this._buttHomeText) }

  private _bgImgUrl?: string
  /** Go Home button text - default: 'Home' */
  public get bgImgUrl(): string | undefined { return this._bgImgUrl ? StringHelpers.Clone(this._bgImgUrl) : undefined }

  private _msg = `The page you're looking for is not here anymore.`
  /** Go Home button text - default: 'Home' */
  public get msg(): string { return StringHelpers.Clone(this._msg) }

  private _theme: NotFoundTheme = 'none'
  /** Color settings. Will be ignored by certain components (i.e. Searchlight). Default = 'none */
  public get theme(): NotFoundTheme { return this._theme }



  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * @param notFoundRoute Route to NotFound page- default: '/inigoadminnotFound'
   */
  constructor(homeRoute: string | null = HOME_ROUTE_DEFAULT) {

    if (homeRoute) this._homeRoute = homeRoute

  } //ctor

  //---------------------------------------------------------------------//

  static Create(homeRoute?: string | null): NotFoundConfig {

    const config = new NotFoundConfig(homeRoute)
    return config

  }//Create

  //---------------------------------------------------------------------//

  setHomeRoute(homeRoute: string): NotFoundConfig {

    this._homeRoute = homeRoute
    return this

  }//setHomeRoute

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Background Image on any component that has one. WIll use default image if none supplied
   */
  setBgImgUrl(bgImgUrl: string): NotFoundConfig {

    this._bgImgUrl = bgImgUrl
    return this

  }//setBgImgUrl

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
    * Go Home button text - default: 'Home' 
   */
  setButtHomeText(buttHomeText: string): NotFoundConfig {

    this._buttHomeText = buttHomeText
    return this

  }//setButtHomeText

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Extra Info
   */
  setMsg(msg: string): NotFoundConfig {

    this._msg = msg
    return this

  }//setMsg

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Set Colors
   */
  setTheme(theme: NotFoundTheme): NotFoundConfig {

    this._theme = theme
    return this

  }//setTheme

  //---------------------------------------------------------------------//

} //Cls

import { InjectionToken } from '@angular/core';
import { BouncerConfig } from '@inigo/my-loaders/bouncer';

export const BouncerConfigService = new InjectionToken<PopUpBouncerConfig>(
  'BouncerConfig'
);

export class PopUpBouncerConfig extends BouncerConfig {
  //---------------------------------------------------------------------//

  /**
   * @param color1 Color for first bouncer - default colorPrimary
   * @param color2 Color for second bouncer - default colorPrimaryLight
   * @param zIndex Z-Index for the Bouncers - default 1000
   */
  private constructor(
    color1: string = '',
    color2: string = '',
    zIndex: number = 1000
  ) {
    super(color1, color2, zIndex);
  } //ctor

  //---------------------------------------------------------------------//

  /**
   * Create new instance of BouncerConfig
   * @param color1 Color for first bouncer - default colorPrimary
   * @param color2 Color for second bouncer - default colorPrimaryLight
   * @param zIndex Z-Index for the Bouncers - default 1000
   */
  static override Create(
    color1: string = '',
    color2: string = '',
    zIndex: number = 1000
  ): PopUpBouncerConfig {
    return new PopUpBouncerConfig(color1, color2, zIndex);
  } //create

  //---------------------------------------------------------------------//

  /**
   * @param color1 Color for first bouncer - default 'deepskyblue'
   * @returns Updated AuthConfig
   */
  override setColor1(color1: string): PopUpBouncerConfig {
    super.setColor1(color1);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color2 Color for first bouncer - default 'deepskyblue'
   * @returns Updated AuthConfig
   */
  override setColor2(color2: string): PopUpBouncerConfig {
    super.setColor1(color2);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param zIndex Z-Index for the Bouncers - default 1000
   * @returns Updated AuthConfig
   */
  override setZIndex(zIndex: number): PopUpBouncerConfig {
    super.setZIndex(zIndex);
    return this;
  }

  //---------------------------------------------------------------------//
} //Cls

import { Identifier } from '@inigo/data/misc';
import { DataService } from '@inigo/http-services';
import { Observable } from 'rxjs';
import { ITemplateHttpService } from './i-template-http.service';

/**
 * Interface for HttpCalls to get component template data
 *
 */
export class DefaultTemplateHttpService extends DataService implements ITemplateHttpService {

  //--------------------------------------------------------------//

  constructor(url: string) {
    super()
    this.url = url
  }

  //--------------------------------------------------------------//

  getTemplate(id: Identifier, action = 'getTemplate', opts?: any): Observable<Blob> {
    if (!!id)
      return this.getActionName(action, id, { responseType: 'blob', ...opts })
    else
      return this.getAction(action, { responseType: 'blob', ...opts})
  }

  //--------------------------------------------------------------//

} //Cls

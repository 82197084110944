import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry, timer } from 'rxjs';

//########################################################//

export const retryCount = 3
export const retryWaitMilliSeconds = 3000

//########################################################//

@Injectable()
export class ServiceUnavailableRetryInterceptor implements HttpInterceptor {

  //--------------------------------------------------------//

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      retry({ count: retryCount, delay: this.shouldRetry })
    )

  }//request

  //--------------------------------------------------------//

  shouldRetry(error: HttpErrorResponse) {

    if (error.status === 503) {
      console.log('503 Retry', retryCount, retryWaitMilliSeconds, error.url)
      return timer(retryWaitMilliSeconds)
    }

    throw error

  }//shouldRetry

  //--------------------------------------------------------//

}//Cls

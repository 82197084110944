import { EnvironmentProviders, Provider } from "@angular/core";
import { ToastConfig, TOAST_CONFIG_TOKEN } from "../toast-config";

export class ToastSetup {

    static getProviders(config: ToastConfig = ToastConfig.Create()): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: TOAST_CONFIG_TOKEN,
                useValue: config,
            },
        ]

    } //configure


} //Cls
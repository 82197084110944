import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, inject, Injector, Type } from '@angular/core';
import { DialogComponent } from '../../components/container/dialog.component';
import { PopUpConfigService } from '../../setup/pop-up-config';
import { DialogConfig } from '../../utility/dialog-config';
import { DialogInjector } from '../../utility/dialog-injector';
import { DialogRef } from '../../utility/dialog-ref';
import { DOCUMENT } from '@angular/common';

const CLOSE_TIMEOUT_MS = 250
const CLOSE_ANIMATION_TIMEOUT_MS = 900
export class BaseDialogService {

  //------------------------------------------------------//

  protected dialogComponentRef?: ComponentRef<DialogComponent>;
  private _closeTimeoutMs = 300

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - //

  protected injector = inject(Injector)
  protected componentFactoryResolver = inject(ComponentFactoryResolver)
  protected appRef = inject(ApplicationRef)
  protected doc = inject(DOCUMENT)

  //------------------------------------------------------//

  constructor() {
    this.setAnimationTimeout()
  }

  //------------------------------------------------------//

  protected appendDialogComponentToBody(config: DialogConfig) {
    const map = new WeakMap()

    // add the DialogConfig to dependency injection
    map.set(DialogConfig, config)

    // add the DialogRef to dependency injection
    const dialogRef = new DialogRef()
    map.set(DialogRef, dialogRef)

    // we want to know when somebody called the close method
    const sub = dialogRef.afterClosed.subscribe(() => {
      // close the dialog. but wait for animation
      // setTimeout(() => {
      this.removeDialogComponentFromBody()
      sub.unsubscribe()
      // }, this._closeTimeoutMs);
    })

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent)

    const componentRef = componentFactory.create(
      new DialogInjector(this.injector, map)
    );

    this.appRef.attachView(componentRef.hostView)

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.doc.body.appendChild(domElem)


    this.dialogComponentRef = componentRef

    this.dialogComponentRef.instance.onClose
      .subscribe(() => {
        console.log('dialogComponentRef.instance.onClose');
        this.removeDialogComponentFromBody();
      })

    return dialogRef

  } //appendDialogComponentToBody

  //------------------------------------------------------//

  protected removeDialogComponentFromBody() {

    if (!this.dialogComponentRef) return

    this.appRef.detachView(this.dialogComponentRef.hostView)
    this.dialogComponentRef.destroy()

  } //removeDialogComponentFromBody

  //------------------------------------------------------//

  protected openBase(componentType: Type<any>, config: DialogConfig):DialogRef {

    //Close the old one if there is one
    if (!config.allowDialogStack)
      this.removeDialogComponentFromBody();

    const dialogRef = this.appendDialogComponentToBody(config);

    if (this.dialogComponentRef)
      this.dialogComponentRef.instance.childComponentType = componentType;

    return dialogRef

  } //open

  //------------------------------------------------------//

  private setAnimationTimeout() {

    const config = this.injector.get(PopUpConfigService);

    if (config.openAnimation === 'none')
      this._closeTimeoutMs = CLOSE_TIMEOUT_MS
    else
      this._closeTimeoutMs = CLOSE_ANIMATION_TIMEOUT_MS

    // console.log(this._closeTimeoutMs + 'ms');

  }//setAnimationTimeout

  //------------------------------------------------------//

} //Cls

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CoreConfig, CoreConfigService } from '../../core-config';
import { NavProgressBarService } from '@inigo/nvbr/progress';

/**
 * This interceptor shows a popup spinner every time a http request is made.
 */
@Injectable()
export class NavLoaderInterceptor implements HttpInterceptor {

  private _navProgressBarService = inject(NavProgressBarService)
  private _coreConfig: CoreConfig = inject(CoreConfigService)


  private _urlsToIgnore: string[] = ['assets'];

  private _isLoading: boolean = false;

  /**
   * This is used to handle multiple requests at the same time.
   * The Spinner should be vilible until they have all returned.
   */
  private _ongoingRequests: Set<string> = new Set<string>();

  //-----------------------------------------------------------------------//

  constructor() {
    this._coreConfig.navUrlsToIgnore.forEach((uti) => this._urlsToIgnore.push(uti));
  } //ctor

  //-----------------------------------------------------------------------//

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.skipUrl(req.url)) return next.handle(req);

    this._ongoingRequests.add(req.url);

    if (this._coreConfig.useLoadingInterceptor && !this._isLoading)
      this._navProgressBarService.setProgress(true);

    this._isLoading = true;

    return next.handle(req).pipe(
      finalize(() => {
        this.onRequestCompleted(req.url);
      })
    ); //pipe

  } //intercept

  //-----------------------------------------------------------------------//

  /**
   * Should we skip the loader for this url
   * @param requestedUrl The url we've requested
   */
  private skipUrl(requestedUrl: string): boolean {

    for (const url of this._urlsToIgnore) {
      if (requestedUrl.startsWith(url)) return true;
    } //for

    return false

  } //skipUrl

  //-----------------------------------------------------------------------//

  /**
   * Removes requestedUrl from _ongoingRequests and if _ongoingRequests is empty, closes the spnr
   * @param requestedUrl Requested Url
   */
  private onRequestCompleted(requestedUrl: string) {

    if (!this._ongoingRequests.has(requestedUrl)) return;

    this._ongoingRequests.delete(requestedUrl);

    if (!this._ongoingRequests?.size) {
      this._isLoading = false;
      this._navProgressBarService.setProgress(false);
    } //if

  } //onRequestCompleted

  //-----------------------------------------------------------------------//
} //Cls

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { EnvironmentProviders, ErrorHandler, Provider } from "@angular/core";
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, UrlSerializer } from "@angular/router";
import { AuthSetup } from "@inigo/authentication/config";
import { JwtInterceptor, UnauthorizedInterceptor } from "@inigo/authentication/interceptors";
import { ContactSetup } from "@inigo/contact/config";
import { HangfireHelpersSetup } from "@inigo/hangfire-helpers/config";
import { HttpServicesSetup } from "@inigo/http-services/config";
import { DelayPreloadingStrategy } from '@inigo/lazy-load-helpers/preload';
import { MyLoadersModule } from "@inigo/my-loaders";
import { MyMatSetup } from "@inigo/my-mat/config";
import { PopUpModule } from "@inigo/pop-up";
import { ThemeAndModeSetup } from "@inigo/theme-and-mode/config";
import { ToastSetup } from "@inigo/toast";
import { CoreConfig, CoreConfigService } from "./core-config";
import { AppErrorHandler } from "./errors/app-error-handler";
import { BlobErrorHttpInterceptor } from "./http/interceptors/blob-interceptor";
import { IconRetryInterceptor } from "./http/interceptors/icon-retry.interceptor";
import { LoaderInterceptor } from "./http/interceptors/loader.interceptor";
import { NavLoaderInterceptor } from "./http/interceptors/nav-loader.interceptor";
import { NotFoundInterceptor } from "./http/interceptors/not-found.interceptor";
import { ServiceUnavailableRetryInterceptor } from "@inigo/errors/interceptors";
import { DataRouteReuseStrategy } from "./routing/data-routereuse-strategy";
import { LowerCaseUrlSerializer } from "./routing/lower-case-url-serializer";
// import { HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
// import { CoreHammerConfig } from "./utility/hammer-config";

export class CoreSetup {

    static getProviders(config: CoreConfig): (Provider | EnvironmentProviders)[] {
        
        return [
            {
              provide: ErrorHandler,
              useClass: AppErrorHandler,
            },
            // {
            //   provide: HAMMER_GESTURE_CONFIG,
            //   useClass: CoreHammerConfig,
            // },
            {
              provide: CoreConfigService,
              useValue: config,
            },
            {
              provide: UrlSerializer,
              useClass: LowerCaseUrlSerializer,
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: JwtInterceptor,
              multi: true,
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: UnauthorizedInterceptor,
              multi: true,
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: ServiceUnavailableRetryInterceptor,
              multi: true,
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: IconRetryInterceptor,
              multi: true,
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: NotFoundInterceptor,
              multi: true,
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: LoaderInterceptor,
              multi: true,
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: NavLoaderInterceptor,
              multi: true,
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: BlobErrorHttpInterceptor,
              multi: true,
            },
            {
              provide: RouteReuseStrategy,
              useClass: DataRouteReuseStrategy,
            },
            provideHttpClient(
              withInterceptorsFromDi()
            ),
            provideAnimations(),
            DelayPreloadingStrategy,
            PopUpModule.configure(config.popUpConfig).providers!,
            MyLoadersModule.configure(config.myLoadersConfig).providers!,
            HttpServicesSetup.getProviders(config.httpServicesConfig),
            ToastSetup.getProviders(config.toastConfig),
            AuthSetup.getProviders(config.authConfig),
            ContactSetup.getAllProviders(config.contactConfig),
            MyMatSetup.getProviders_DD_MMM_YYYY(),
            ThemeAndModeSetup.getThemeProviders(config.themeConfig),
            HangfireHelpersSetup.getProviders(config.hangfireConfig)
          ]


    }


}//Cls

<inigo-loaders-trouncer
  [useImages]="false"
  [imgPath1]="_config.imgPath1"
  [imgPath2]="_config.imgPath2"
  [imgPath3]="_config.imgPath3"
  [color1]="_config.color1"
  [color2]="_config.color2"
  [color3]="_config.color3"
  [bounce]="true"
  [diameterPx]="_config.diameterPx"
>
</inigo-loaders-trouncer>

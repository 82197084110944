export class RegExes {

    static readonly ChunkFailedMessage = /Loading chunk .* failed/
    static readonly IsNumber = /^-?[\d.]+(?:e-?\d+)?$/

    static readonly  IntegerUnsigned= '^[0-9]*$'
    static readonly  IntegerSigned= '^-?[0-9]+$'
    static readonly  DecimalUnsigned= '^[0-9]+(.[0-9]+)?$'
    static readonly  DecimalSigned= '^-?[0-9]+(.[0-9]+)?$'
    static readonly  Whitespace = /\s+/g
    static readonly  NonAlphaNumeric = /[^a-zA-Z0-9]/g
    static readonly  NonAlphaNumericSpace = /[^a-zA-Z0-9 ]/g
   

}//Cls
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthConfig, AuthConfigService, FROM_APP_HEADER } from '@inigo/authentication/config';
import { Observable } from 'rxjs';

@Injectable()
export class FromAppHeaderInterceptor implements HttpInterceptor {

  private _config: AuthConfig = inject(AuthConfigService)

  //-----------------------------------------------------------------------//

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    const fromAppRequest = req.clone({
      headers: req.headers.set(
        FROM_APP_HEADER,
        this._config.fromAppHeaderValue
      ),
    })

    return next.handle(fromAppRequest)

  } //intercept

  //-----------------------------------------------------------------------//

} //Cls

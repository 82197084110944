import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlobDownloadService {

  private _platformId = inject(PLATFORM_ID)
  private _document = inject(DOCUMENT)

  //-------------------------------------------// 

  download(blob: Blob, filename: string = 'download'): void {

    if (isPlatformServer(this._platformId))
      return
    
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], { type: "octet/stream" })

    // // IE doesn't allow using a blob object directly as link href instead it is necessary to use msSaveOrOpenBlob
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(newBlob)
    //   return
    // }

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob)

    const link = this._document.createElement('a')
    link.href = data
    link.download = filename
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data)
      link.remove()
    }, 100)

  }//download

  //-------------------------------------------// 

  /** Create a text file and download it */
  downloadText(txt: string, filename: string = 'download') {

    const blob = new Blob([txt], { type: 'application/octet-stream' });
    this.download(blob, filename)

  }//download

  //-------------------------------------------// 

}//Cls

import { IndustryDisplayConfig } from "./industry-display-config"


export class IndustryConfig {

    public static readonly Construction = new IndustryDisplayConfig(
        'construction',
        'Construction',
        'Right',
        'building-construction',
        'assets/images/industry/construction.jpg',
        'assets/images/industry/placeholder/construction.jpg',
        'assets/images/industry/home-page/construction.jpg',
        'assets/images/industry/home-page/placeholder/construction.jpg',
        'assets/images/industry/home-page/mobile/construction.jpg',
        'assets/images/industry/home-page/mobile/placeholder/construction.jpg',
        [
            'Rawlplug',
            'Heller', 'Illbruck',
            'Tyrolit',
            'Simpson',
            'Rapierstar'
        ]
    )

    public static readonly Engineering = new IndustryDisplayConfig(
        'engineering',
        'Engineering',
        'Left',
        'products',
        'assets/images/industry/engineering.jpg',
        'assets/images/industry/placeholder/engineering.jpg',
        'assets/images/industry/home-page/engineering.jpg',
        'assets/images/industry/home-page/placeholder/engineering.jpg',
        'assets/images/industry/home-page/mobile/engineering.jpg',
        'assets/images/industry/home-page/mobile/placeholder/engineering.jpg',
        [
            'Heller',
            'Voelkel',
            'Illbruck',
            'Simpson',
            'Scellit',
            'Huck',
            'FE Power tools',
        ]
    )

    public static readonly PPE = new IndustryDisplayConfig(
        'ppe',
        'PPE',
        'Left',
        'ppe',
        'assets/images/industry/ppe.jpg',
        'assets/images/industry/placeholder/ppe.jpg',
        'assets/images/industry/home-page/ppe.jpg',
        'assets/images/industry/home-page/placeholder/ppe.jpg',
        'assets/images/industry/home-page/mobile/ppe.jpg',
        'assets/images/industry/home-page/mobile/placeholder/ppe.jpg',
        [
            'Beeswift',
            'Cofra',
            'Traffi glove',
            'Eye Protection',
            'Ear protection',
            'Foot Protection',
            'Head Protection',
        ]
    )

    public static readonly Joinery = new IndustryDisplayConfig(
        'joinery',
        'Joinery',
        'Right',
        'hammer_and_saw',
        'assets/images/industry/joinery.jpg',
        'assets/images/industry/placeholder/joinery.jpg',
        'assets/images/industry/home-page/joinery.jpg',
        'assets/images/industry/home-page/placeholder/joinery.jpg',
        'assets/images/industry/home-page/mobile/joinery.jpg',
        'assets/images/industry/home-page/mobile/placeholder/joinery.jpg',
        [
            'Rawlplug',
            'Heller',
            'Illbruck',
            'Rapierstar',
            'Kregg screws',
            'Woodscrews',
            'Coach Screws',
        ]
    )
    public static readonly Windows = new IndustryDisplayConfig(
        'windows',
        'Windows',
        'Left',
        'window-open',
        'assets/images/industry/windows.jpg',
        'assets/images/industry/placeholder/windows.jpg',
        'assets/images/industry/home-page/windows.jpg',
        'assets/images/industry/home-page/placeholder/windows.jpg',
        'assets/images/industry/home-page/mobile/windows.jpg',
        'assets/images/industry/home-page/mobile/placeholder/windows.jpg',
        [
            'Rawlplug',
            'Heller',
            'Illbruck',
            'Tyrolit',
            'Simpson',
            'Rapierstar'
        ]
    )

    public static readonly ALL = [
        IndustryConfig.Construction,
        IndustryConfig.Engineering,
        IndustryConfig.PPE,
        IndustryConfig.Joinery,
        IndustryConfig.Windows
    ]


}//Cls
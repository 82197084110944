import { ModuleWithProviders, Type } from '@angular/core';

//-----------------------------------------------------------------------------------------------------------//

export function ConfigurableLazyModule<T>(moduleWithProviders: ModuleWithProviders<T>): Type<T> {
  
  const { ngModule, providers } = moduleWithProviders;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore todo: https://stackoverflow.com/a/59687799/2331113
  const injections: NgModule = ngModule['ɵinj'];

  if (injections && providers) {
    injections.providers?.push(...providers);
  }

  return ngModule
  
} //ConfigurableLazyModule

//-----------------------------------------------------------------------------------------------------------//

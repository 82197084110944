import { InjectionToken } from '@angular/core';
import { TrouncerConfig } from '@inigo/my-loaders/trouncer';

//========================================================================================================//

export const TrouncerConfigService = new InjectionToken<PopUpTrouncerConfig>(
  'TrouncerConfig'
);

//========================================================================================================//

export class PopUpTrouncerConfig extends TrouncerConfig {
  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * Enter no paramaters to use default values on everything
   * @param useImages Use images instead of just background color
   * @param useShadow Add a shadow to create elevation. May not look good on some images with transpararnt parts.
   * @param imgPath1 Path to image for first trouncer - default "/assets/images/trouncer/trouncer1.png"
   * @param imgPath2 Path to image for second trounce - default "/assets/images/trouncer/trouncer2.png"
   * @param imgPath3 Path to image for third trouncer - default "/assets/images/trouncer/trouncer3.png"
   * @param color1 Color for first trouncer - default colorPrimary
   * @param color2 Color for second trouncer - default colorSecondary
   * @param color3 Color for third trouncer - default colorTertiarary
   * @param bounce Whether images should shrink back down (bounce) or fade out at the top
   * @param diameterPx  Width of the Circles - default 100
   * @param zIndex - default 1000
   * @param colors Array of colors for when more than 3 is required - default null
   * @param imagePaths Array of images for when more than 3 is required - default null
   */
  private constructor(
    useImages: boolean | null = null,
    useShadow: boolean | null = null,
    imgPath1: string = '',
    imgPath2: string = '',
    imgPath3: string = '',
    color1: string = '',
    color2: string = '',
    color3: string = '',
    bounce: boolean | null = null,
    diameterPx: number = 0,
    zIndex: number = 0,
    colors: string[] | null = null,
    imagePaths: string[] | null = null
  ) {
    super(
      useImages,
      useShadow,
      imgPath1,
      imgPath2,
      imgPath3,
      color1,
      color2,
      color3,
      bounce,
      diameterPx,
      zIndex,
      colors,
      imagePaths
    );
  } //ctor

  //---------------------------------------------------------------------//

  /**
   * Create new instance of TrouncerConfig
   * @param useImages Use images instead of just background color. Default = false
   * @param useShadow Add a shadow to create elevation. May not look good on some mages with transpararnt parts. Default = true
   */
  static override Create(
    useImages: boolean = false,
    useShadow: boolean = true
  ): PopUpTrouncerConfig {
    return new PopUpTrouncerConfig(useImages, useShadow);
  } //create

  //---------------------------------------------------------------------//

  /**
   * @param useImages Use images instead of just background color. Default = false
   * @returns Updated PopUpTrouncerConfig
   */
  override setUseImages(useImages: boolean): PopUpTrouncerConfig {
    super.setUseImages(useImages);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param useShadow Add a shadow to create elevation. May not look good on some images with transpararnt parts. Default (true
   * @returns Updated PopUpTrouncerConfig
   */
  override setUseShadow(useShadow: boolean): PopUpTrouncerConfig {
    super.setUseShadow(useShadow);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param imgPath1 Path to image for first trouncer - default "/assets/images/trouncer/trouncer1.png"
   * @returns Updated PopUpTrouncerConfig
   */
  override setImgPath1(imgPath1: string): PopUpTrouncerConfig {
    super.setImgPath1(imgPath1);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param  imgPath2 Path to image for second trounce - default "/assets/images/trouncer/trouncer2.png"
   * @returns Updated PopUpTrouncerConfig
   */
  override setImgPath2(imgPath2: string): PopUpTrouncerConfig {
    super.setImgPath2(imgPath2);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param imgPath3 Path to image for third trouncer - default "/assets/images/trouncer/trouncer3.png"
   * @returns Updated PopUpTrouncerConfig
   */
  override setImgPath3(imgPath3: string): PopUpTrouncerConfig {
    super.setImgPath3(imgPath3);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color1 Color for first trouncer - default colorPrimary
   * @returns Updated PopUpTrouncerConfig
   */
  override setColor1(color1: string): PopUpTrouncerConfig {
    super.setColor1(color1);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color2 Color for second trouncer - default colorSecondary
   * @returns Updated PopUpTrouncerConfig
   */
  override setColor2(color2: string): PopUpTrouncerConfig {
    super.setColor2(color2);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color3 Color for third trouncer - default colorTertiarary
   * @returns Updated PopUpTrouncerConfig
   */
  override setColor3(color3: string): PopUpTrouncerConfig {
    super.setColor3(color3);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param bounce Whether images should shrink back down (bounce) or fade out at the top
   * @returns Updated PopUpTrouncerConfig
   */
  override setBounce(bounce: boolean): PopUpTrouncerConfig {
    super.setBounce(bounce);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param diameterPx  Width of the Circles - default 100
   * @returns Updated PopUpTrouncerConfig
   */
  override setDiameterPx(diameterPx: number): PopUpTrouncerConfig {
    super.setDiameterPx(diameterPx);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param zIndex  Z-Index of spinners. Default 1000
   * @returns Updated PopUpTrouncerConfig
   */
  override setZIndex(zIndex: number): PopUpTrouncerConfig {
    super.setZIndex(zIndex);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param colors Array of colors for when more than 3 is required - default null
   * @returns Updated PopUpTrouncerConfig
   */
  override setColors(colors: string[]): PopUpTrouncerConfig {
    super.setColors(colors);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param imagePaths Array of images for when more than 3 is required - default null
   * @returns Updated PopUpTrouncerConfig
   */
  override setImagePaths(imagePaths: string[]): PopUpTrouncerConfig {
    super.setImagePaths(imagePaths);
    return this;
  }

  //---------------------------------------------------------------------//
} //Cls

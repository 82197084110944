import { FilterType, FilterListItem } from './filter-types';
import { FilterDataType } from './filter-data-types';

/**
 * Class for encapsulation a table column's filter and column entries
 */
export class DisplayColumn<T = any> {

  isFilterable: boolean
  useList = false
  multiSelect = false
  triggerMinTxtLength?:number
  handleNestedProperty?: (x: T) => any

  /**
   * Setup a DisplayColumn. Null or blank will fallback to default values.
   * @param name Name of column/header/title/object - This will become the header if the header is not supplied
   * @param filterType what to filter on (Equals, Contains, StartsWith, etc.) - default = EQUALS for Dates|Numbers|Booleans, CONTAINS for strings
   * @param filterDataType What type of data is it - 'string' | 'number' | 'date' | 'boolean' | 'list' - default = 'string'
   * @param header Description of column - default: name
   * @param handleNestedProperty A function used to access the relevant property. Default = null
   * @param show Show/Hide the column
   * @param filterList
   */
  private constructor(
    public name: string,
    public filterType?: FilterType,
    public filterDataType?: FilterDataType,
    public header?: string,
    public show = true,
    public filterList: FilterListItem[] = []
  ) {
    if (!filterDataType) this.filterDataType = 'string'

    this.isFilterable = this.filterType !== FilterType.NONE

    if (!header) this.header = name

    if (filterDataType !== 'string' && !filterType)
      this.filterType = FilterType.EQUALS

    if (this.filterDataType === 'string' && !filterType)
      this.filterType = FilterType.CONTAINS

    //Boolean is always equals as the table will have a checkbox
    if (filterDataType === 'boolean') this.filterType = FilterType.EQUALS

    //List is always equals
    if (filterList?.length) {
      this.filterType = FilterType.EQUALS
      this.useList = true
    } //if
  } //ctor

  //----------------------------- Builders -----------------------------//

  /**
   * Creates a DisplayColumn with default settings on everything but name, filterType and filterDatatype
   * @param name Name of column/header/title/object - This will become the header if the header is not supplied
   * @param filterType what to filter on (Equals, Contains, StartsWith, etc.) - default = EQUALS for Dates|Numbers|Booleans, CONTAINS for strings
   * @param filterDataType What type of data is it - 'string' | 'number' | 'date' | 'boolean' | 'list' - default = 'string'
   */
  static create<T>(
    name: string,
    filterType?: FilterType | null,
    filterDataType?: FilterDataType | null
  ): DisplayColumn<T> {

    return new DisplayColumn<T>(
      name,
      filterType ?? undefined,
      filterDataType ?? undefined
    )
  } //create

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  clone<T>(): DisplayColumn<T> {

    const clone = new DisplayColumn(
      this.name,
      this.filterType,
      this.filterDataType,
      this.header,
      this.show,
      this.filterList
    )

    clone.setUseList(this.useList, this.multiSelect)
    clone.handleNestedProperty = this.handleNestedProperty

    return clone
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Creates a blank DisplayColumn with default settings on everything but name set to 'Empty Table Column'
   */
  static empty = <T>(): DisplayColumn<T> => new DisplayColumn<T>('Empty Table Column')

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  setFilterType(filterType: FilterType): DisplayColumn<T> {

    if (!filterType) this.filterType = FilterType.EQUALS

    this.filterType = filterType

    this.isFilterable = this.filterType !== FilterType.NONE
    return this

  } //setFilterType

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  setFilterDataType(filterDataType: FilterDataType): DisplayColumn<T> {
    if (!filterDataType) this.filterDataType = 'string'

    //Boolean is always equals as the table will have a checkbox
    if (filterDataType === 'boolean') this.filterType = FilterType.EQUALS

    //Numbers can't use CONTAINS filter so start with EQUALS
    if (filterDataType === 'number') this.filterType = FilterType.EQUALS

    if (filterDataType === 'date') this.filterType = FilterType.EQUALS

    this.filterDataType = filterDataType

    return this
  } //setFilterDataType

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param header Description of column - default: name
   */
  setHeader(header: string): DisplayColumn<T> {
    if (!header) this.header = this.name
    // console.log('setHeader', header)

    this.header = header

    return this
  } //setHeader

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param handleNestedProperty A function used to access the relevant property.
   */
  setNestedPropertyHandler(handleNestedProperty: (x: T) => any): DisplayColumn<T> {

    this.handleNestedProperty = handleNestedProperty

    return this
  } //setNestedPropertyHandler

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param useList This column will be searched using a List (Not typing)
   */
  setUseList(useList: boolean, multiSelect: boolean = false): DisplayColumn<T> {

    this.useList = useList
    this.multiSelect = multiSelect
    return this
  } //setUseList

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   *
   * @param filterList The list of select options
   * @param multiSelect allow uuser to select more than one option. Default = false
   * @returns
   */
  setFilterList(filterList: FilterListItem[], multiSelect: boolean = false): DisplayColumn<T> {

    this.filterList = filterList
    //List is always equals or not-equals (start with equals)
    this.filterType = FilterType.EQUALS
    this.useList = true

    this.multiSelect = multiSelect
    //Multi-List is always 'IN' (start with 'IN')
    if (this.multiSelect) this.filterType = FilterType.IN

    return this

  } //setFilterList

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//
  

  /**
   * How many characters should be typed to trigger observable.
   * If number or list 0 will be used.
   * Default = 2
   * @param filterType
   */
  setTriggerMinTextLength(minTextLength: number): DisplayColumn<T> {

    this.triggerMinTxtLength = minTextLength
    return this

  } //setTriggerMinTextLength

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Sets show to false.
   */
  hideInitially(): DisplayColumn<T> {
    this.show = false

    return this
  } //hideInitially

  //---------------------------------------------------------------------//
} //Cls

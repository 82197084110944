import { Observable, Subject } from 'rxjs';

export class DialogRef {

  private readonly _afterClosed = new Subject<any>();
  afterClosed: Observable<any> = this._afterClosed.asObservable();

  private readonly _onError = new Subject<DialogError>();
  onError: Observable<DialogError> = this._onError.asObservable();

  private readonly _onData = new Subject<any>();
  onData: Observable<any> = this._onData.asObservable();

  //----------------------------------------------------------//???

  constructor() {}

  //----------------------------------------------------------//

  close(result?: any) {
    this._afterClosed.next(result);
  } //close

  //----------------------------------------------------------//

  emit(result?: any) {
    this._afterClosed.next(result);
  } //emit

  //----------------------------------------------------------//

  /** Emits data but stays open */
  emitData(result?: any) {
    this._onData.next(result);
  } //emitData

  //----------------------------------------------------------//

  emitError(errorMsg: string) {
    this._onError.next(new DialogError(errorMsg));
  } //emitError
} //Cls

//===============================================================//

export class DialogError {
  constructor(public errorMsg: string) {}
}

//===============================================================//

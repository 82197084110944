import { signal } from '@angular/core';

/**
 * Class for creating observables arrays.
 */
export class SignalArray<T>  {

    private _source = signal<T[]>([])
    signal = this._source.asReadonly()

    //----------------------------------------------------------------//
    /**
     * @param startVal first value to output (defaults to null)
     */
    constructor(startVal?: T[]) {

        this._source.set(startVal ?? [])

    }//ctor

    //----------------------------------------------------------------//

    /**Pass in a new value */
    set = (nextVal: T[]): void => this._source.set(nextVal)

    //----------------------------------------------------------------//


    /**Pass in a new value */
    push = (t: T): void => this._source.set(this._source().concat([t]))

    //----------------------------------------------------------------//

    /**
     * Replace the value at index, idx
     * @param t  New Item
     * @param idx Location of item to be replaced 
     */
    replace = (t: T, idx: number): void => {

        this.alterSourceArray(
            idx,
            (array, index) => {            
                array[index] = t
                return array
            })           
    }//replace

    //----------------------------------------------------------------//

    /**
     * Remove the value at index, idx
     * @param idx Location of item to be replaced 
     */
    remove = (idx: number): void => {

        this.alterSourceArray(
            idx,
            (array, index) => {
                array.splice(index, 1)
                return array
            })
    }//remove

    //----------------------------------------------------------------//

    /**
     * Current value
     */
    getValue = (): T[] | undefined => this._source()

    //----------------------------------------------------------------//

    private invalidIndex = (idx: number) => idx === null || idx === undefined || idx < 0
    private outsideRange = (idx: number, array: T[]) => !array.length || idx >= array.length

    //----------------------------------------------------------------//

    private alterSourceArray(idx: number, updateFn: (array: T[], index: number) => T[]) {

        if (this.invalidIndex(idx))
            return

        const array = this._source()
        if (this.outsideRange(idx, array)) //Nothing to replace
            return
            
        this._source.mutate(array => updateFn(array, idx))        

    }//alterSourcedArray

    //----------------------------------------------------------------//

}//Cls
import { CamelCaseToTitleCasePipe } from '@inigo/pipes/text';
import { FilterDataType } from './filter-data-types';
import { FilterTypes, GetFilterTypeSymbol, FilterType } from './filter-types';

/**Info on how to filter a query */
export class FilterRequest {
  /**
   * What field/column to sort by
   **/
  public field: string;

  /**
   * What type of filter to use (EQUALS, START_WITH, etc.)
   *
   * Defaults to EQUALS
   */
  public filterType: string = FilterTypes.EQUALS;

  /**
   * What to compare/filter with
   */
  public filterValue: string;

  /**
   * Use when you need to check if multiple values match
   */
  public filterValues: string[];

  /**
   * What type of data is it. Needed on the server side. Default = 'string'
   */
  public filterDataType: FilterDataType = 'string';

  //-------------------------------------------------------------------//

  /**
   * Genereates new filter request
   * @param field What field/column to sort by
   * @param filterValue What to compare/filter with
   * @param filterType What type of filter to use (EQUALS, START_WITH, etc.) Default = FilterTypes.EQUALS
   * @param filterDataType What type of data is it. Needed on the server side. Default = 'string'
   * @param filterValues What type of data is it. Needed on the server side. Default = 'string'
   */
  constructor(
    field: string,
    filterValue?: string,
    filterType: string | null = FilterTypes.EQUALS,
    filterDataType: FilterDataType = 'string',
    filterValues: string[] = []
  ) {
    this.field = field;
    this.filterValue = filterValue ?? '';
    this.filterType = filterType ?? FilterTypes.EQUALS;
    this.filterDataType = filterDataType;
    this.filterValues = filterValues;

    //Try and get the values from filterValue if the array is empty
    if (filterType === FilterTypes.IN && !this.filterValues?.length)
      this.filterValues = this.filterValue.split(',');
  } //ctor

  //-------------------------------------------------------------------//

  public description(): string {
    const cToTPipe = new CamelCaseToTitleCasePipe();

    return `${cToTPipe.transform(this.field)} ${GetFilterTypeSymbol(
      this.filterType as FilterType
    )} ${this.filterValue}`;
  } //toString

  //-------------------------------------------------------------------//

  public isEmpty(): boolean {
    
    //Should work for arrays and strings
    
    if (this.filterDataType === 'boolean' && this.filterValue === 'all')
      return true;
    if (!this.filterValue?.length)
      return true;
    else
      return false;
  } //isEmpty

  //-------------------------------------------------------------------//

  isEqual(that: FilterRequest): boolean {
    if (this.field !== that.field) return false;
    if (this.filterValue !== that.filterValue) return false;
    if (this.filterType !== that.filterType) return false;

    return true;
  } //isEqual

  //----------------------------------------------------------------------//
} //Cls

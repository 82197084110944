import {
  AfterViewInit,
  ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild, inject
} from '@angular/core';
import { DialogConfig } from '../../../utility/dialog-config';
import { DialogRef } from '../../../utility/dialog-ref';

@Component({
  selector: 'inigo-base-info',
  templateUrl: './base-info.component.html',
  styleUrls: ['./base-info.component.scss'],
})
export class BaseInfoComponent implements AfterViewInit {

  public dialogConfig = inject(DialogConfig)
  public dialogRef = inject(DialogRef)
  private _cd = inject(ChangeDetectorRef)

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  @ViewChild('buttOk') _buttOk?: ElementRef;

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  @Input('colorHeaderHex') _colorHeader: string = '';
  @Input('colorHeaderText') _colorHeaderText = 'white';

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  protected _opacity = 1;
  protected _colorButtonBg: string = '';
  protected _colorButtonTxt: string = '';
  protected _showCopyButton = false;
  protected _isDark = false;

  //--------------------------------------------------------//

  ngAfterViewInit(): void {

    setTimeout(() => {
      this._buttOk?.nativeElement?.focus();
      this._cd.detectChanges();
    }, 25)

  } //ngAfterViewInit

  //--------------------------------------------------------//

  onCloseClick() {

    setTimeout(() => {
      this.dialogRef.close('closed');
    }, 250)
    
  } //onClose

  //--------------------------------------------------------//

  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.key === 'Enter') {
      this.onCloseClick();
      event.stopPropagation();
    } //if
  } //onKeydownHandler

  //--------------------------------------------------------//
} //Cls

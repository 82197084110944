import { Injectable, Signal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavProgressBarService {

  private _showSgnl = signal(false)
  show: Signal<boolean> = this._showSgnl

  //--------------------------------------------------------//

  /**
   * Let someone know that we want to show/hide the progress bar
   * @param show show/hide = true/false
   */
  setProgress = (show: boolean) => this._showSgnl.set(show)

  //--------------------------------------------------------//

}//Cls

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CopyButtonComponent } from '@inigo/copy-button';
import { MyLoadersModule } from '@inigo/my-loaders';
import { DialogComponent } from './components/container/dialog.component';
import { InsertionDirective } from './components/container/insertion.directive';
import { BaseInfoComponent } from './components/info/base-info/base-info.component';
import { ErrorComponent } from './components/info/error/error.component';
import { SuccessComponent } from './components/info/success/success.component';
import { BouncerComponent } from './components/spinners/bouncer/bouncer.component';
import { DotSpinnerComponent } from './components/spinners/dot-spinner/dot-spinner.component';
import { TrouncerComponent } from './components/spinners/trouncer/trouncer.component';
import { ExampleComponent } from './example/example.component';
import { PopUpConfig } from './setup/pop-up-config';
import { PopUpSetup } from './setup/setup';


/**Use forRoot */
@NgModule({
  declarations: [
    DialogComponent,
    InsertionDirective,
    ExampleComponent,
    BaseInfoComponent,
    ErrorComponent,
    SuccessComponent,
    BouncerComponent,
    TrouncerComponent,
    BaseInfoComponent,
    DotSpinnerComponent,
  ],
  imports: [
    CommonModule,
    CopyButtonComponent,
    MyLoadersModule.configure(), //Use default values because they'll be overridden when we set the components' @Inputs
  ],
  exports: [],
})
export class PopUpModule {
  /**
   * Enter no paramaters to use default values on everything
   * @param config Configration file that contains the configurations for the
   * Spinner  and Dialogs
   */
  static configure(config: PopUpConfig = PopUpConfig.Create()): ModuleWithProviders<PopUpModule> {
    return {
      ngModule: PopUpModule,
      providers: PopUpSetup.getProviders(config),
    }
  } //configure

  //------------------------------------------------------------//
} //Cls

/**Route URLs to use if nothing is set in the config file  */
export class AccRoutes {
  static readonly ACCOUNT_FEATURES = 'accounts';
  static readonly APPS = 'apps';
  static readonly ACCESS_POINTS = 'accesspoints';
  static readonly AUTH_TEST = 'authtesting';
  static readonly CHANGE_PWD = 'changepassword';
  static readonly CONFIRM_EMAIL_CUSTOMER = 'confirmemailcustomer';
  static readonly CONFIRM_EMAIL_MNTC = 'confirmemailmntc';
  static readonly CUSTOMERS_ID_PARAM = 'customerId'
  static readonly CUSTOMERS = 'customers';
  static readonly DEVICES = 'devices';
  static readonly EMAIL_CONFIRMED_CUSTOMER = 'emailconfirmed';
  static readonly EMAIL_CONFIRMED_MNTC = 'emailconfirmedmntc';
  static readonly EMAIL_NOT_CONFIRMED_CUSTOMER = 'emailnotconfirmed';
  static readonly EMAIL_NOT_CONFIRMED_MNTC = 'emailnotconfirmedmntc';
  static readonly HOME = 'home';
  static readonly LOGIN = 'login';
  static readonly LIST = 'list';
  static readonly MY_INFO = 'myinfo';
  static readonly RESET_PWD = 'resetpassword';
  static readonly SUBSCRIPTIONS_DEFS = 'subdefs';
  static readonly SUBSCRIPTIONS = 'subs';
  static readonly SUBSCRIPTIONS_ID = `${this.SUBSCRIPTIONS}/:${this.CUSTOMERS_ID_PARAM}`
  static readonly TWO_FACTOR_VERIFICATION = 'twofactorverification';
  static readonly USERS = 'users';
  static readonly MNTC_USERS = 'mntcusers';
  static readonly SUPER_USERS = 'superusers';
} //Cls

//#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#//

/**
 * Must be lowercase
 */
export class AccParams {
  static ConfirmEmailCustomer = class {
    static readonly USER_ID = 'userid';
    static readonly CONFIRMATION_TOKEN = 'confirmationtoken';
  };

  static ConfirmEmailMntc = class {
    static readonly USER_ID = 'userid';
    static readonly CONFIRMATION_TOKEN = 'confirmationtoken';
  };

  static EmailConfirmedCustomer = class {
    static readonly USER_ID = 'userid';
    static readonly CONFIRMATION_TOKEN = 'confirmationtoken';
  };

  static ResetPwd = class {
    static readonly USER_ID = 'userid';
    static readonly USERNAME = 'confirmationtoken';
    static readonly EMAIL = 'email';
    static readonly RESET_TOKEN = 'resettoken';
  };
} //Cls

//#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#//

/**
 * Gets full path to route starting at ROUTE_ACCOUNT_FEATURES
 * @param route dstination route
 */
export function FullPathFromAccFeatures(route: string, prefix: string = '', absolutePath = false): string {

  if (prefix && !prefix.endsWith('/')) prefix += '/';
  if (absolutePath) prefix = '/' + prefix

  return prefix + AccRoutes.ACCOUNT_FEATURES + '/' + route

} //FullPathFromAccFeatures

//#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#//

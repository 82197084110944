import { isPlatformServer } from '@angular/common';
import { ElementRef, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { EasingFunctions, FunctionNames } from '@inigo/gen-helpers/ui';

@Injectable({
  providedIn: 'root'
})
export class SmoothScrollService {

  private _platformId = inject(PLATFORM_ID)

  //----------------------------------------------------------//

  /**
   * Scroll to @param targetTop smoothly
   * @param targetTop What distance from to of the screen should the scroll finish
   * @param duration How long should it take
   */
  smoothScrollTo(targetTop: number, duration: number) {

    if (isPlatformServer(this._platformId))
      return

    const startPosition = window.scrollY
    const distance = targetTop - startPosition
    let startTime: number = 0

    const easingFunctions = new EasingFunctions()
    const func = FunctionNames.easeInOutQuad
    const ease = easingFunctions.getFunction(func)

    function animation(currentTime: number) {
      if (!startTime)
        startTime = currentTime
      const timeElapsed = currentTime - startTime
      const run = ease(timeElapsed, startPosition, distance, duration)
      window.scrollTo(0, run)
      if (timeElapsed < duration)
        requestAnimationFrame(animation)
    }//scrollAnimation


    //start it off
    requestAnimationFrame(animation)

  }//smoothScrollTo

  //----------------------------------------------------------//

  /**
   * Scroll to @param targetTop smoothly
   * @param targetTop What distance from to of the screen should the scroll finish
   * @param duration How long should it take
   */
  smoothContainerScrollTo(targetTop: number, duration: number, scrollContainer: ElementRef) {

    const ntvEl = scrollContainer.nativeElement

    const startPosition = ntvEl.scrollTop
    const distance = targetTop - startPosition

    let startTime: number = 0

    const easingFunctions = new EasingFunctions()
    const ease = easingFunctions.getFunction(FunctionNames.easeInQuad)


    function animation(currentTime: number) {
      
      if (!startTime)
        startTime = currentTime
      const timeElapsed = currentTime - startTime
      const run = ease(timeElapsed, startPosition, distance, duration)
      // console.log(
      //   `startTime: ${startTime}`,
      //   `currentTime: ${currentTime}`,
      //   `duration: ${duration}`,
      //   `timeElapsed: ${timeElapsed}`,
      //   `run: ${run}`,
      //   );
      ntvEl.scrollTo(0, run)

      if (timeElapsed < duration)
        requestAnimationFrame(animation)
    }//scrollAnimation


    //start it off
    requestAnimationFrame(animation)

  }//smoothScrollTo

  //----------------------------------------------------------//

}//Cls

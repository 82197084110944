import { InjectionToken } from '@angular/core';
import { StringHelpers } from '@inigo/gen-helpers/text';

//===================================================================//

export const ContactFormConfigService = new InjectionToken<ContactFormConfig>('ContactFormConfig')

//===================================================================//

export class ContactFormConfig {


  public static readonly DEFAULT_URL = '/api/contact'
  public static readonly DEFAULT_ACTION = 'websiteenquiry'

  private _contactUrl = ContactFormConfig.DEFAULT_URL
  /** Where to send contact-us requests - default: '/api/contact' */
  public get contactUrl(): string {
    return StringHelpers.Clone(this._contactUrl)
  }

  private _contactUsAction = ContactFormConfig.DEFAULT_ACTION
  /** Which action on the ContactForm endpoint to send website contact us forms - default: 'websiteenquiry' */
  public get contactUsAction(): string {
    return StringHelpers.Clone(this._contactUsAction)
  }

  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * Enter no paramaters to use default values on everything
   * @param contactUrl Where to send contact-us requests - default: '/api/contact'
   * @param contactUsAction Which action on the ContactForm endpoint to send website contact us forms - default: 'websiteenquiry'
   */
  private constructor(
    contactUrl?: string,
    contactUsAction?: string
  ) {

    if (contactUrl)
      this._contactUrl = contactUrl

    if (contactUsAction)
      this._contactUsAction = contactUsAction

  } //ctor

  //---------------------------------------------------------------------//

  /**
   * @param apiKey Google Maps API version. - no default
   * Enter no paramaters to use default values on everything
   * @param contactUrl Where to send contact-us requests - default: '/api/contact'
   * @param contactUsAction Which action on the ContactForm endpoint to send website contact us forms - default: 'websiteenquiry'
   */
  static Create = (contactUrl?: string, contactUsAction?: string)
    : ContactFormConfig => new ContactFormConfig(contactUrl, contactUsAction)

  //---------------------------------------------------------------------//

  /**
   * @param contactUrl Where to send contact-us requests - default: '/api/contact'
   * @returns Updated ContactFormConfig
   */
  setContactUrl(contactUrl: string): ContactFormConfig {
    this._contactUrl = contactUrl
    return this
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param contactUsAction Which action on the ContactForm endpoint to send website contact us forms - default: 'websiteenquiry'
   * @returns Updated ContactFormConfig
   */
  setContactFormAction(contactUsAction: string): ContactFormConfig {
    this._contactUsAction = contactUsAction
    return this
  }

  //---------------------------------------------------------------------//
} //Cls

import { isPlatformServer } from '@angular/common';
import { PLATFORM_ID, inject } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export class DelayPreloadingStrategy implements PreloadingStrategy {

  private _platformId = inject(PLATFORM_ID)
  
  //----------------------------------------------------------//

  preload(route: Route, load: Function): Observable<any> {
    
    if (isPlatformServer(this._platformId))
    return of(null)
  
    return route.data && route.data['preload']
      ? this.loadRoute(
        route.data['delay'],
        route.data['increment'] ?? 150,
        load
      )
      : of(null)

  } //preload

  //----------------------------------------------------------//

  private loadRoute(delay: boolean, increment: number, load: Function): Observable<any> {

    if (delay)
      return timer(increment).pipe(mergeMap((_) => load()));
    else
      return load();

  } //loadRoute

  //----------------------------------------------------------//
} //Cls

//===========================================================================================//

/**
 * returns a data object for the route with preload set to true.
 * @param increment How long (milliseconds) to wait before loading. Default = 250
 * @param delay Wait a certain amount of time before loading or load straight away. Default = true
 */
export function DelayPreloader(increment: number = 250, delay: boolean = true) {
  
  return {
    preload: true,
    delay: delay,
    increment: increment,
  }

} //DelayPreloader

//===========================================================================================//

/**
 *
 * @param animation Noame of animation for this route transition
 * @param increment How long (milliseconds) to wait before loading. Default = 250
 * @param delay Wait a certain amount of time before loading or load straight away. Default = true
 * @param reuseComponent Keep compinnet in memory after navigating away. Speeds up return to page
 * @returns
 */
export function DelayPreloaderAnimation(
  animation: string,
  increment: number = 250,
  delay: boolean = true,
  reuseComponent: boolean = false
) {
  
  return {
    preload: true,
    delay: delay,
    increment: increment,
    reuseComponent: reuseComponent,
    animation: animation,
  }

} //DelayPreloaderAnimation

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StatusCodes } from '@inigo/gen-helpers/classes';
import { StringHelpers } from '@inigo/gen-helpers/text';
import { ReponseMsgs } from '@inigo/data/misc';

@Injectable()
export class NotFoundInterceptor implements HttpInterceptor {


  //-----------------------------------------------------------------------//

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    

    return next.handle(req).pipe(
      tap({
        next: (n) => { },
        error: (err: any) => {          
          if (err instanceof HttpErrorResponse) {
            if (err.status !== StatusCodes.NOT_FOUND)
              return
            this.handleNotFound(err);
          } //if
        }
      }) //tap
    ); //pipe
  } //intercept

  //-----------------------------------------------------------------------//

  /**
   * Make sure there's a human readable message
   */
  private handleNotFound(err: any) {
    

    //Intentional error messages will be in err.error.message
    if (!err) return;

    //if err.error is a String take it to be the message
    if (StringHelpers.IsString(err.error) && !this.isHtml(err.error)) {
      const errMsg = err.error;
      err.error = {};
      err.error.message = errMsg;
      return;
    } //if

    if (!err.error || !err.error?.message) err.error = {};

    if (!err.error?.message || this.isHtml(err.error.message))
      err.error.message = ReponseMsgs.NotFoundErrorMessage

  } //handleNotFound

  //-----------------------------------------------------------------------//

  private isHtml(message: string): boolean {

    if (!message || !StringHelpers.IsString(message))
      return false;

    const trimmedMessage = message.trim()

    return trimmedMessage.startsWith('<') && trimmedMessage.endsWith('>')

  } //isHtml

  //-----------------------------------------------------------------------//
} //Cls

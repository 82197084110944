import { Injectable } from '@angular/core';
import { Identifier } from '@inigo/data/misc';
import { APageStoreService, DefaultTemplateStoreService, SuccessInfo } from '@inigo/my-store';
import { Observable, ReplaySubject } from 'rxjs';
import { Brand } from '../../../dtos/brand';
import { BulkUploadDto } from '../../../dtos/bulk-upload';
import { BrandHttpService } from '../../http/brand/brand.service';

@Injectable({
  providedIn: 'root',
})
export class BrandStoreService extends APageStoreService<Brand, Brand> {

  private _httpService: BrandHttpService
  
  private _templateStore: DefaultTemplateStoreService
  protected _templateBs = new ReplaySubject<Blob>(1)
  /**Associated File (example: Excel) */
  template$: Observable<Blob> = this._templateBs.asObservable()

  //-------------------------------------------------------//

  constructor(httpService: BrandHttpService) {
    super(httpService);

    this._httpService = httpService
    this._templateStore = DefaultTemplateStoreService.Create(
      httpService.url,
      this._templateBs,
      this._isLoadingBs,
      this._errorSb,
      this._successSb
    )

    this.setItemTypeName('Brand')
    this.setItemNameLamda((p) => p.name ?? `Brand ${p.id}`)

  } //ctor

  //-------------------------------------------------------//

  addBulk(dto: BulkUploadDto, filename: string = 'upload_report.txt') {

    this._httpService.addBulk(dto).subscribe({
      next: (response) => {
        this._blobHandler.download(response, filename);
        this._successSb.next(
          SuccessInfo.Other(
            `Upload complete. Extra info wil be in downloaded file, "${filename}"`
          )
        );
      },
      error: (error) => this._errorSb.next(error),
    })

  } //addBulk

  //-------------------------------------------------------//

  getTemplate = (fileName: string = 'template.tmp', id?: Identifier): Observable<Blob> =>
    this._templateStore.getTemplate(fileName, id)

  //-------------------------------------------------------//

} //Cls

import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { InigoRouterEvent, NavigationService } from '@inigo/gen-helpers/services';
import { SwUpdatesService } from '@inigo/sw-helpers';
import { Observable } from 'rxjs';
import { ColorStoreService } from '../clr/color-store.service';
import { DeptStoreService } from '../depts/dept-store.service';
import { DimensionStoreService } from '../dmn/dmn-store.service';
import { StyleStoreService } from '../stl/style-store.service';
import { UnitStoreService } from '../unit/unit-store.service';
import { VariantStoreAdminService } from '../vars/variant-store-admin.service';
import { VariantStoreCustomerService } from '../vars/variant-store-customer.service';
import { ProdStoreCustomerService } from '../prods/prod-store-customer.service';
import { ProdStoreMntcService } from '../prods/prod-store-admin.service';
import { isPlatformServer } from '@angular/common';
import { IndustryImagePreloader } from '../../../../public/pages/industries/industry-image-preloader';
import { MsrDefsStoreService } from '../msr-defs/msr-defs-store.service';

@Injectable({
  providedIn: 'root',
})
export class AppStoreService {

  private _platformId = inject(PLATFORM_ID)
  public colors = inject(ColorStoreService)
  public depts = inject(DeptStoreService)
  public dmnDefs = inject(DimensionStoreService)
  public productsCustomer = inject(ProdStoreCustomerService)
  public productsMntc = inject(ProdStoreMntcService)
  public msrDefs = inject(MsrDefsStoreService)
  public styles = inject(StyleStoreService)
  public units = inject(UnitStoreService)
  public varsAdmin = inject(VariantStoreAdminService)
  public varsPublic = inject(VariantStoreCustomerService)
  private _navService = inject(NavigationService)
  private _swUpdates = inject(SwUpdatesService)
  private _preloader = inject(IndustryImagePreloader)

  //--------------------------------------------------------------//

  currentRoute = this._navService.currentRoute()
  currentRoute$ = this._navService.currentRoute$

  
  previousRoute = this._navService.previousRoute()
  previousRoute$ = this._navService.previousRoute$

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  /**
   * An event stream for NavigationEnd
   */
  get navigationEndEvent(): Observable<InigoRouterEvent> {
    return this._navService?.navigationEndEvent
  }

  //--------------------------------------------------------------//

  readonly fallbackImageUrl: string = `assets/images/fallback-img.svg`

  //--------------------------------------------------------------//

  constructor() {

    this.startup()

  } //ctor

  //--------------------------------------------------------------//

  startup() {

    this.depts.setupDeptList()

    if (isPlatformServer(this._platformId))
      return

    this._preloader.preloadPlc()

    // this._navService.scrollToTopOfPageOnNavigationChange(true)
    this._swUpdates.listenForNewVersions()

    setTimeout(() => {
      this._preloader.preloadMain()
      console.log('startup')
    }, 30000)

  }//startup

  //--------------------------------------------------------------//

  refreshMntc() {

    if (isPlatformServer(this._platformId))
      return

    this.depts.refreshDataIfNecessary?.()
    this.units.refreshDataIfNecessary?.()
    // this.colors.refreshDataIfNecessary?.()
    // this.dmnDefs.refreshDataIfNecessary?.()
    // this.productsMntc.refreshDataIfNecessary?.()
    // this.styles.refreshDataIfNecessary?.()
    // this.varsAdmin.refreshDataIfNecessary?.()

  }//refreshMntc

  //--------------------------------------------------------------//

} //Cls

import { Injectable, Type } from '@angular/core';
import { DialogConfig } from '../../utility/dialog-config';
import { DialogRef } from '../../utility/dialog-ref';
import { BaseDialogService } from '../base/base-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class DialogService extends BaseDialogService {

  //------------------------------------------------------//

  public open(componentType: Type<any>, config: DialogConfig): DialogRef {
    return this.openBase(componentType, config);
  } //open

  //------------------------------------------------------//
} //Cls

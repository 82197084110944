import { InjectionToken } from '@angular/core';
import { ContactFormConfig } from './form-config';
import { ContactMapConfig } from './map-config';

//===================================================================//

export const ContactMapFormConfigService = new InjectionToken<ContactMapFormConfig>('ContactMapFormConfig');

//===================================================================//

export class ContactMapFormConfig {

  private _formConfig: ContactFormConfig = ContactFormConfig.Create()
  /** Contact Info for the ContactService API (Urls & Actions) */
  public get formConfig(): ContactFormConfig {
    return this._formConfig;
  }

  private _mapConfig: ContactMapConfig
  /** Contact info (Location, MapIconUrl, ContactDetails for the icon pop-up)  */
  public get mapConfig(): ContactMapConfig {
    return this._mapConfig;
  }

  //---------------------------------------------------------------------//

  /**
   * set paramater to null to use default values
   * @param mapConfig Contact info (Location, MapIconUrl, ContactDetails for the icon pop-up) 
   * @param formConfig  Contact Info for the ContactService API (Urls & Actions)
   */
  protected constructor(
    mapConfig: ContactMapConfig,
    formConfig?: ContactFormConfig
  ) {
    this._mapConfig = mapConfig
    if (formConfig)
      this._formConfig = formConfig
  } //ctor

  //---------------------------------------------------------------------//

  /**
   * @param mapConfig Contact info (Location, MapIconUrl, ContactDetails for the icon pop-up) 
   * @param formConfig  Contact Info for the ContactService API (Urls & Actions)
   */
  static Create(mapConfig: ContactMapConfig, formConfig?: ContactFormConfig): ContactMapFormConfig {

    return new ContactMapFormConfig(mapConfig, formConfig)

  } //create

  //---------------------------------------------------------------------//

  /**
   * @param formConfig  Contact Info for the ContactService API (Urls & Actions)
   * @returns Updated ContactUsConfig
   */
  setFormConfig(formConfig: ContactFormConfig): ContactMapFormConfig {

    this._formConfig = formConfig;
    return this

  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param mapConfig Contact info (Location, MapIconUrl, ContactDetails for the icon pop-up) 
   * @returns Updated ContactUsConfig
   */
  setMapConfig(mapConfig: ContactMapConfig): ContactMapFormConfig {

    this._mapConfig = mapConfig;
    return this

  }

  //---------------------------------------------------------------------//
} //Cls

import { Component, Inject, Input } from '@angular/core';
import {
  PopUpConfig,
  PopUpConfigService,
} from '../../../setup/pop-up-config';
import { DialogConfig } from '../../../utility/dialog-config';
import { DialogRef } from '../../../utility/dialog-ref';

@Component({
  selector: 'app-bouncer',
  templateUrl: './bouncer.component.html',
  styleUrls: ['./bouncer.component.scss'],
})
export class BouncerComponent {
  @Input('color1') _color1?: string //= '#9BBDF9'
  @Input('color2') _color2?: string  //= '#67AAF9'
  @Input('isRunning') _isRunning: boolean = true

  //--------------------------------------------------------//

  constructor(
    public dialogConfig: DialogConfig,
    public dialogRef: DialogRef,
    @Inject(PopUpConfigService) config: PopUpConfig
  ) {
    const bncConfig = config.bouncerConfig;

    if (bncConfig.color1) this._color1 = bncConfig.color1;
    if (bncConfig.color2) this._color2 = bncConfig.color2;
  } //ctor

  //---------------------------------------------------------------------------------------------//
} //Cls

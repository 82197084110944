export class CaseInsensitiveSet extends Set {

    constructor(values: string[] = []) {
        super(Array.from(values, it => it.toLowerCase()));
    }

    //----------------------------------------------//

    override add(str: string) {
        return super.add(str.toLowerCase());
    }

    //----------------------------------------------//

    override has(str: string) {
        return super.has(str.toLowerCase());
    }

    //----------------------------------------------//

    override delete(str: string) {
        return super.delete(str.toLowerCase());
    }

    //----------------------------------------------//

}//Cls
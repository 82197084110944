import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DotSpinnerComponent } from './dot-spinner.component';
import { DotSpinnerConfig, DotSpinnerConfigService } from './dot-spinner-config';

@NgModule({
  declarations: [DotSpinnerComponent],
  imports: [CommonModule],
  exports: [DotSpinnerComponent],
})
export class LoaderDotSpinnerModule {

  static configure(config: DotSpinnerConfig = DotSpinnerConfig.Create()): ModuleWithProviders<LoaderDotSpinnerModule> {

    return {
      ngModule: LoaderDotSpinnerModule,
      providers: [
        DotSpinnerComponent,
        {
          provide: DotSpinnerConfigService,
          useValue: config,
        },
      ],
    };
  } //configure

} //Cls


import { AnimationEvent } from '@angular/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ToastConstants } from '../setup/toast-constants';
import { ToastAnimationState, toastAnimations } from '../toast-animations';
import { TOAST_CONFIG_TOKEN, ToastConfig } from '../toast-config';
import { ToastData } from '../toast-data';
import { ToastRef } from '../toast-ref';

@Component({
  selector: 'inigo-tst',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [toastAnimations.fadeToast],
  standalone: true,
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {



  private readonly data = inject(ToastData)
  private readonly ref = inject(ToastRef)
  protected _toastConfig: ToastConfig = inject(TOAST_CONFIG_TOKEN)
  protected _toastIconError = ToastConstants.IconNameError
  protected _toastIconWarn = ToastConstants.IconNameWarn
  protected _toastIconSuccess = ToastConstants.IconNameSuccess
  protected _toastIconInfo = ToastConstants.IconNameInfo
  protected _toastIconDefault = ToastConstants.IconNameDefault

  //----------------------------------------------------//

  _bgColor = signal(ToastConstants.ColorBgDefault)
  _animationState = signal<ToastAnimationState>('default')
  _iconType = signal('')
  _txtColor = signal(ToastConstants.ColorBgDefault)
  _txt = signal('')

  //----------------------------------------------------//

  constructor() {
    this.setUp(this.data)
  } //ctor

  //----------------------------------------------------//

  close = () => this.ref.close()

  //----------------------------------------------------//

  onFadeFinished(event: AnimationEvent) {
    
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this._animationState() === 'closing';


    if (isFadeOut && itFinished)
      this.close()

  } //onFadeFinished

  //----------------------------------------------------//

  setUp(data: ToastData) {
    this._iconType.set(data.getIconName())
    this._bgColor.set(data.getBackgroundColor(this._toastConfig))
    this._txtColor.set(this._toastConfig.colorText)
    this._txt.set(data.text ?? '')

  } //setUp

  //----------------------------------------------------//

} //Cls

import { EMPTY, Observable, fromEvent, merge, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { FilterDataType } from './models/filter-data-types';
import { FilterRequest } from './models/filter-request';
import { FilterType, FilterTypes } from './models/filter-types';

//====================================================================//

/**Prefix for the id of the text-input - set html element to INPUT_PREFIX + columnName */
export const CF_INPUT_FILTER_PREFIX = 'input-filter-'
/**Prefix for the id of the text-input - set html element to INPUT_PREFIX + columnName */
export const CF_SELECT_FILTER_PREFIX = 'select-filter-'
/**Prefix for the id of the clear button  - set html element to BUTTON_CLEAR_PREFIX + columnName */
export const CF_BUTTON_CLEAR_PREFIX = 'button-clear-filter-'

/**
 * The default behavior is to focus on this input after close is clicked.
 * To disable this behaviour, add this attribute to your input and set it to true.
 */
export const CF_NO_FOCUS_AFTER_CLOSE = 'no-focus-after-close'

const DEFAULT_MIN_TEXT_LENGTH = 2

//====================================================================//

/**
 * Class for encapsulating the filtering of a table column.
 * Html id for input box should be CF_BUTTON_CLEAR_PREFIX + columnName.
 * Html id for clear button should be CF_INPUT_PREFIX + columnName.
 * Html id for filter type should be CF_FILTER_TYPE_PREFIX + columnName.
 *
 * How it works:
 * Send in the column/field/property name and the class will find the
 * corresponding input and clear button in the DOM.
 * Alternatively use the static method, generateColumnFilterArray to send in an array of column names
 * and get an array of Column filters returned.
 *
 * Then call call asObservale on the object to get an observable that will send out an FilterRequest when
 * a text or button event occurs.
 */
export class ColumnFilter {

  private _input: HTMLInputElement
  private _buttonClear: HTMLButtonElement
  private _selectFilterType: HTMLSelectElement | HTMLInputElement
  private _columnName: string
  private _filterDataType: FilterDataType
  /**True when it's a dropdown/select */
  private _isListFilter: boolean = false

  //Used when _selectFilterType is NOT being used
  private _filterType?: FilterType// = FilterType.EQUALS

  private _uniqueSuffix: string = ''
  private _minTxtLength = DEFAULT_MIN_TEXT_LENGTH

  //------------------------------------------------------------------//

  /**
   * @param columnName The name of the column/property getting filtered
   * @param filterDataType What type of data are we filtering ('string' | 'number' | 'date' | 'boolean' ). Default = 'string'
   * @param isListFilter Is it a list filter (selectable options).  (This will affect the FilterTypes and min text length to trigger events).
   *
   * True when it's a dropdown/select.
   * Default = false
   * @param uniqueSuffix Something to distinguish columns if multiple tables are on the same page
   */
  constructor(
    columnName: string,
    filterDataType: FilterDataType = 'string',
    isListFilter = false,
    uniqueSuffix = ''
  ) {

    this._input = document.getElementById(CF_INPUT_FILTER_PREFIX + columnName + uniqueSuffix) as HTMLInputElement
    this._selectFilterType = document.getElementById(CF_SELECT_FILTER_PREFIX + columnName + uniqueSuffix) as HTMLSelectElement
    this._buttonClear = document.getElementById(CF_BUTTON_CLEAR_PREFIX + columnName + uniqueSuffix) as HTMLButtonElement

    this._columnName = columnName
    this._filterDataType = filterDataType
    this._isListFilter = isListFilter

    this._uniqueSuffix = uniqueSuffix

  } //ctor

  //------------------------------------------------------------------//

  getColumnName = (): string => `${this._columnName}`//Send a copy
  getFilterText = (): string => `${this._input}` //Send a copy

  //----------------------------------------------------------------------//

  /**
   * Create an Observable of FilterRequest that reacts to changes in the ColumnFilter (button & text)
   * @param onChange What do do when the filter changes
   * @param minTextLength how many characters should we have before notifying observers
   */
  asObservable(minTextLength: number = -1): Observable<FilterRequest> {

    if (minTextLength < 0)
      minTextLength = this.calculateMinTextLength()

    //This will not be available if the user leaves the page before it's set up.
    //This should be caught in Development and ignored in Production
    if (!this._input) {
      if (this._filterDataType != 'action')
        console.log(this.inputNotFound(this._columnName))
      return of(new FilterRequest('', ''))
    } //if

    //this can be falsey if the button was in a modal that was closed
    if (this._buttonClear) {
      this.getButttClick$()
        .subscribe(() => this.dispatchInputEvent())
    } //if

    const inputChange$ = this.getInputChange$(this._input, minTextLength)

    const inputTypeChange$: Observable<FilterRequest> = this.getInputFilterType$()

    return merge(inputChange$, inputTypeChange$).pipe(
      distinctUntilChanged(),
      catchError((e) => {
        console.log(e)
        return of(new FilterRequest('', '')) //Send out a blank one
      })
    ) //pipe

  } //asObservable

  //------------------------------------------------------------------//

  /**
   * Set the type of filter that will be used on this value  (EQUALS , STARTS_WITH, CONTAINS, etc.)
   * Use this when NOT using a dropdown to choose the filterType
   * @param filterType
   */
  setFilterType(filterType: FilterType): ColumnFilter {

    this._filterType = filterType
    return this

  } //setFilterType

  //------------------------------------------------------------------//

  /**
   * How many characters should be typed to trigger observable.
   * If number or list 0 will be used.
   * Default = 2
   * @param filterType
   */
  setTriggerMinTextLength(minTextLength?: number): ColumnFilter {

    this._minTxtLength = minTextLength ?? DEFAULT_MIN_TEXT_LENGTH
    return this

  } //setTriggerMinTextLength

  //------------------------------------------------------------------//

  /**
   * Set the input to '' and return a call to onChange
   */
  clearFilter() {
    if (!this._input) return

    this._input.value = ''
    this.dispatchInputEvent()

  } //clearFilter

  //------------------------------------------------------------------//

  private calculateMinTextLength(): number {

    if (this._filterDataType === 'number' || this._isListFilter)
      return 0

    return this._minTxtLength
  } //calculateMinTextLength

  //------------------------------------------------------------------//

  /**
   * Generate an observable that reacts to button click
   */
  private getButttClick$ = (): Observable<Event> => fromEvent(this._buttonClear, 'click')

  //------------------------------------------------------------------//

  /**
   * Generate an observable that reacts to text change
   * @param minTextLength how many characters should we have before notifying observers
   */
  private getInputChange$(input: HTMLInputElement, minTextLength: number): Observable<FilterRequest> {

    return fromEvent(input, 'input')
      .pipe(
        startWith({}),
        //When using 'combineLatest' each observable must emit once before the whoe thing is emitted. This gets it started
        map(() => this.getCurrentFilterRequest()),
        filter(
          (result) =>
            result.filterValue.length >= minTextLength
            ||
            result.filterValue.length === 0
        ), // === 0 to handle clear filter
        debounceTime(500),
        distinctUntilChanged((prev, crnt) => prev.isEqual(crnt))
      ) //pipe

  } //getIngetInputChangeputKeyup$

  //------------------------------------------------------------------//

  /**
   * Generate an observable that reacts to text change
   */
  private getInputFilterType$(): Observable<FilterRequest> {

    if (!this._selectFilterType) return EMPTY

    //'input' applies to HtmlSelect and HtmlInput

    return fromEvent(this._selectFilterType, 'input').pipe(
      map(() => this.getCurrentFilterRequest()),
      filter(() => !!this._input.value), //Ignore this if there is no value to filter on
      debounceTime(500),
      distinctUntilChanged((prev, crnt) => prev.isEqual(crnt))
    ) //pipe

  } //getInputKeyup$

  //------------------------------------------------------------------//

  /**
   * Get a new FilterRequest that represents the current state
   */
  private getCurrentFilterRequest(): FilterRequest {

    // console.log('getCurrentFilterRequest', this._input.value, this._filterDataType, this._filterType)

    return new FilterRequest(
      this._columnName,
      this._input.value,
      this._filterType ?? FilterTypes.asEnum(this._selectFilterType?.value),
      this.getFilterDataType()
    )

  } //getCurrentFilterRequest

  //------------------------------------------------------------------//

  private getFilterDataType = () => this._filterDataType === 'color' ? 'string' : this._filterDataType

  //------------------------------------------------------------------//

  /**
   * Tell the input that it's changed
   * @param newValue What to set the input value to. Default = ''
   */
  dispatchInputEvent(newValue: string = '') {

    this._input.value = newValue

    //Let it get handled through the input so that distinctUntilChanged knows about it
    const evt = new CustomEvent('input')

    const skipFocus = this._input.getAttribute(CF_NO_FOCUS_AFTER_CLOSE)
    if (!skipFocus) this._input.focus()

    this._input.dispatchEvent(evt)

  } //dispatchEvent

  //------------------------------------------------------------------//

  private inputNotFound = (colName?: string) => `Input, ${CF_INPUT_FILTER_PREFIX + colName + this._uniqueSuffix}, was not found!!!`

  //------------------------------------------------------------------//

} //Cls

import { InjectionToken } from '@angular/core';
import { StringHelpers } from '@inigo/gen-helpers/text';

export const DotSpinnerConfigService = new InjectionToken<DotSpinnerConfig>(
  'DotSpinnerConfig'
);

type DotSpinnerTheme = 'primary' | 'accent' | 'warn' | 'mixed' | 'none';

export class DotSpinnerConfig {
  /** Color theme. ('primary' | 'accent' | 'warn'| 'none' ) -  Default = 'mixed' */
  private _theme: DotSpinnerTheme = 'mixed';
  public get theme(): DotSpinnerTheme {
    return this._theme;
  }

  private _color1: string;
  /** Color for first dot - default 'colorPrimary' */
  public get color1(): string {
    return StringHelpers.Clone(this._color1);
  }

  private _color2: string;
  /** Color for second dot - default 'colorSecondary' */
  public get color2(): string {
    return StringHelpers.Clone(this._color2);
  }

  private _color3: string;
  /** Color for third dot - default 'colorTertiarary' */
  public get color3(): string {
    return StringHelpers.Clone(this._color3);
  }

  private _color4: string;
  /** Color for fourth dot - default 'colorPrimary' */
  public get color4(): string {
    return StringHelpers.Clone(this._color4);
  }

  private _color5: string;
  /** Color for fifth dot - default 'colorPrimary' */
  public get color5(): string {
    return StringHelpers.Clone(this._color5);
  }

  private _color6: string;
  /** Color for sixth dot - default 'colorSecondary' */
  public get color6(): string {
    return StringHelpers.Clone(this._color6);
  }

  private _color7: string;
  /** Color for seventh dot - default 'colorPrimary' */
  public get color7(): string {
    return StringHelpers.Clone(this._color7);
  }

  private _color8: string;
  /** Color for eight dot - default 'colorSecondary' */
  public get color8(): string {
    return StringHelpers.Clone(this._color8);
  }

  private _colors: string[] = [];
  /** An array of all the colors in this config file */
  public get colors(): string[] {
    return [...this._colors];
  }

  private _animationDuration = 1.2;
  /** How long will the animation take in seconds (1 rotation + fade in/out) - default 1.2 */
  public get animationDuration(): number {
    return this._animationDuration;
  }

  private _message: string = '';
  /** Color for eight dot - default 'colorPrimary' */
  public get message(): string {
    return StringHelpers.Clone(this._message);
  }

  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * Enter no paramaters to use default values on everything
   * @param color1 Color for first dot - default colorPrimary
   * @param color2 Color for second dot - default colorSecondary
   * @param color3 Color for third dot - default colorPrimary
   * @param color4 Color for fourth dot - default colorSecondary
   * @param color5 Color for fifth dot - default colorPrimary
   * @param color6 Color for sixth dot - default colorSecondary
   * @param color7 Color for seventh dot - default colorPrimary
   * @param color8 Color for eight dot - default colorSecondary
   * @param animationDuration How long will the animation take in seconds (1 rotation + fade in/out) - default 1.2
   * @param message Optional message at bottom of spinner - default no messasge
   */
  protected constructor(
    color1: string = '',
    color2: string = '',
    color3: string = '',
    color4: string = '',
    color5: string = '',
    color6: string = '',
    color7: string = '',
    color8: string = '',
    animationDuration: number | null = null,
    message: string = ''
  ) {
    //This may be more convenient for end user
    this._colors.push(color1);
    this._colors.push(color2);
    this._colors.push(color3);
    this._colors.push(color4);
    this._colors.push(color5);
    this._colors.push(color6);
    this._colors.push(color7);
    this._colors.push(color8);

    this._color1 = color1;
    this._color2 = color2;
    this._color3 = color3;
    this._color4 = color4;
    this._color5 = color5;
    this._color6 = color6;
    this._color7 = color7;
    this._color8 = color8;

    if (animationDuration) this._animationDuration = animationDuration;

    if (message) this._message = message;
  } //ctor

  //---------------------------------------------------------------------//

  /**
   * Create new instance of DotSpinnerConfig with default settings
   * @param color1 Color for first dot - default colorPrimary
   * @param color2 Color for second dot - default colorSecondary
   * @param color3 Color for third dot - default colorPrimary
   * @param color4 Color for fourth dot - default colorSecondary
   * @param color5 Color for fifth dot - default colorPrimary
   * @param color6 Color for sixth dot - default colorSecondary
   * @param color7 Color for seventh dot - default colorPrimary
   * @param color8 Color for eight dot - default colorSecondary
   */
  static Create(
    color1: string = '',
    color2: string = '',
    color3: string = '',
    color4: string = '',
    color5: string = '',
    color6: string = '',
    color7: string = '',
    color8: string = ''
  ): DotSpinnerConfig {
    return new DotSpinnerConfig(
      color1,
      color2,
      color3,
      color4,
      color5,
      color6,
      color7,
      color8
    );
  } //create

  //---------------------------------------------------------------------//

  /**
   * @param color1 Color for first dot - default colorPrimary
   * @returns Updated DotSpinnerConfig
   */
  setColor1(color1: string): DotSpinnerConfig {
    this._color1 = color1;
    this.colors[0] = color1;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color2 Color for second dot - default colorSecondary
   * @returns Updated DotSpinnerConfig
   */
  setColor2(color2: string): DotSpinnerConfig {
    this._color2 = color2;
    this.colors[1] = color2;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color3 Color for third dot - default colorTertiarary
   * @returns Updated DotSpinnerConfig
   */
  setColor3(color3: string): DotSpinnerConfig {
    this._color3 = color3;
    this.colors[2] = color3;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color4 Color for fourth dot - default colorSecondary
   * @returns Updated DotSpinnerConfig
   */
  setColor4(color4: string): DotSpinnerConfig {
    this._color4 = color4;
    this.colors[3] = color4;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color5 Color for fifth dot - default colorPrimary
   * @returns Updated DotSpinnerConfig
   */
  setColor5(color5: string): DotSpinnerConfig {
    this._color5 = color5;
    this.colors[4] = color5;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color6 Color for sixth dot - default colorSecondary
   * @returns Updated DotSpinnerConfig
   */
  setColor6(color6: string): DotSpinnerConfig {
    this._color6 = color6;
    this.colors[5] = color6;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color7 Color for sevent dot - default colorPrimary
   * @returns Updated DotSpinnerConfig
   */
  setColor7(color7: string): DotSpinnerConfig {
    this._color7 = color7;
    this.colors[6] = color7;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color8 Color for eight dot - default colorSecondary
   * @returns Updated DotSpinnerConfig
   */
  setColor8(color8: string): DotSpinnerConfig {
    this._color8 = color8;
    this.colors[7] = color8;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//
} //Cls

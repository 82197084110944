import { StringHelpers } from '@inigo/gen-helpers/text';
import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_MAX_LENGTH = 25;

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {


  transform(value?: string | null, args?: number): string {

    if (value === null || value === undefined) value = '';

    const limit = args ? args : DEFAULT_MAX_LENGTH;

    return StringHelpers.Truncate(value, limit)

  } //transform


} //Cls

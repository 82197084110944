import { Injectable } from "@angular/core"

@Injectable({
    providedIn: 'root'
  })
export class EasingFunctions {

    private PI = 3.14159265359

 
    //-----------------------------------------------------------------------//

    getFunction(name: FunctionNames) {
        let func: Function

        switch (name) {

            case FunctionNames.linearTween:
                func = this.linearTween
                break
            case FunctionNames.easeInQuad:
                func = this.easeInQuad
                break
            case FunctionNames.easeOutQuad:
                func = this.easeOutQuad
                break
            case FunctionNames.easeInOutQuad:
                func = this.easeInOutQuad
                break
            case FunctionNames.easeInCubic:
                func = this.easeInCubic
                break
            case FunctionNames.easeOutCubic:
                func = this.easeOutCubic
                break
            case FunctionNames.easeInOutCubic:
                func = this.easeInOutCubic
                break
            case FunctionNames.easeInQuart:
                func = this.easeInQuart
                break
            default:
                return this.linearTween

        }//switch

        return func

    }//getFunction

    //-----------------------------------------------------------------------//

    /**
     * simple linear tweening - no easing, no acceleration
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private linearTween(t: number, b: number, c: number, d: number) {
        console.log(t, b, c, d);
        
        return c * t / d + b;
    }

    //-----------------------------------------------------------------------//

    /**   quadratic easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInQuad(t: number, b: number, c: number, d: number) {
        t /= d;
        return c * t * t + b;
    }

    //-----------------------------------------------------------------------//

    /**   
     * quadratic easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutQuad(t: number, b: number, c: number, d: number) {
        t /= d;
        return -c * t * (t - 2) + b;
    }

    //-----------------------------------------------------------------------//

    /**  
     *  quadratic easing in/out - acceleration until halfway, then deceleration
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutQuad(t: number, b: number, c: number, d: number) {
        t /= d / 2
        if (t < 1) return c / 2 * t * t + b
        t--
        return -c / 2 * (t * (t - 2) - 1) + b
    }

    //-----------------------------------------------------------------------//

    /**   cubic easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */

    private easeInCubic(t: number, b: number, c: number, d: number) {
        t /= d;
        return c * t * t * t + b;
    }

    //-----------------------------------------------------------------------//

    /**   cubic easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutCubic(t: number, b: number, c: number, d: number) {
        t /= d;
        t--;
        return c * (t * t * t + 1) + b;
    }

    //-----------------------------------------------------------------------//

    /**   cubic easing in/out - acceleration until halfway, then deceleration
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutCubic(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t * t + b;
        t -= 2;
        return c / 2 * (t * t * t + 2) + b;
    }

    //-----------------------------------------------------------------------//

    /**   quartic easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInQuart(t: number, b: number, c: number, d: number) {
        t /= d;
        return c * t * t * t * t + b;
    }

    //-----------------------------------------------------------------------//

    /**   quartic easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutQuart(t: number, b: number, c: number, d: number) {
        t /= d;
        t--;
        return -c * (t * t * t * t - 1) + b;
    }

    //-----------------------------------------------------------------------//

    /**   quartic easing in/out - acceleration until halfway, then deceleration
    * @param t timeElapsed
    * @param b startPosition
    * @param c distance
    * @param d duration
    */
    private easeInOutQuart(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t * t * t + b;
        t -= 2;
        return -c / 2 * (t * t * t * t - 2) + b;
    }

    //-----------------------------------------------------------------------//

    /**   quintic easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInQuint(t: number, b: number, c: number, d: number) {
        t /= d;
        return c * t * t * t * t * t + b;
    }

    //-----------------------------------------------------------------------//

    /**   quintic easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutQuint(t: number, b: number, c: number, d: number) {
        t /= d;
        t--;
        return c * (t * t * t * t * t + 1) + b;
    }

    //-----------------------------------------------------------------------//

    /**   quintic easing in/out - acceleration until halfway, then deceleration
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutQuint(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t * t * t * t + b;
        t -= 2;
        return c / 2 * (t * t * t * t * t + 2) + b;
    }

    //-----------------------------------------------------------------------//

    /**   sinusoidal easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */

    private easeInSine(t: number, b: any, c: number, d: number) {
        return -c * Math.cos(t / d * (this.PI / 2)) + c + b;
    }

    //-----------------------------------------------------------------------//

    /**   sinusoidal easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutSine(t: number, b: number, c: number, d: number) {
        return c * Math.sin(t / d * (this.PI / 2)) + b;
    }

    //-----------------------------------------------------------------------//

    /**   sinusoidal easing in/out - accelerating until halfway, then decelerating
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutSine(t: number, b: number, c: number, d: number) {
        return -c / 2 * (Math.cos(this.PI * t / d) - 1) + b;
    }

    //-----------------------------------------------------------------------//

    /**   exponential easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInExpo(t: number, b: number, c: number, d: number) {
        return c * Math.pow(2, 10 * (t / d - 1)) + b;
    }

    //-----------------------------------------------------------------------//

    /**   exponential easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutExpo(t: number, b: number, c: number, d: number) {
        return c * (-Math.pow(2, -10 * t / d) + 1) + b;
    }

    //-----------------------------------------------------------------------//

    /**   exponential easing in/out - accelerating until halfway, then decelerating
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutExpo(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
        t--;
        return c / 2 * (-Math.pow(2, -10 * t) + 2) + b;
    }

    //-----------------------------------------------------------------------//

    /**   circular easing in - accelerating from zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInCirc(t: number, b: number, c: number, d: number) {
        t /= d;
        return -c * (Math.sqrt(1 - t * t) - 1) + b;
    }

    //-----------------------------------------------------------------------//

    /**   circular easing out - decelerating to zero velocity
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeOutCirc(t: number, b: number, c: number, d: number) {
        t /= d;
        t--;
        return c * Math.sqrt(1 - t * t) + b;
    }

    //-----------------------------------------------------------------------//

    /**   circular easing in/out - acceleration until halfway, then deceleration
     * @param t timeElapsed
     * @param b startPosition
     * @param c distance
     * @param d duration
     */
    private easeInOutCirc(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
        t -= 2;
        return c / 2 * (Math.sqrt(1 - t * t) + 1) + b;
    }

}//Cls

//####################################################################################################//
//####################################################################################################//

export enum FunctionNames {
    linearTween,
    easeInQuad,
    easeOutQuad,
    easeInOutQuad,
    easeInCubic,
    easeOutCubic,
    easeInOutCubic,
    easeInQuart
}//Enum

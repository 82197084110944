import { isPlatformServer } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Identifier } from '@inigo/data/misc';
import { BadRequestError, HttpError, INTERNAL_SERVER_ERROR_MESSAGE, ModelStateErrors, NotFoundError, UnreadableResponseError, } from '@inigo/errors';
import { StatusCodes } from '@inigo/gen-helpers/classes';
import { DateHelpers } from '@inigo/gen-helpers/date-time';
import { HttpServicesConfig, HttpServicesConfigService } from '@inigo/http-services/config';
import { PagedRequest, PagedResponse } from '@inigo/pagination';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/**Base Service for all httping */
export class DataService {

  protected _http = inject(HttpClient)
  private _httpConfig: HttpServicesConfig = inject(HttpServicesConfigService)
  private _dsPlatformId = inject(PLATFORM_ID)

  //---------------------------------------------------------------------//

  protected _url: string = ''

  public get url(): string {
    if (isPlatformServer(this._dsPlatformId) && this._httpConfig.ssrUrl) {
      console.log('ssrUrl???', this._httpConfig.ssrUrl);
      return this._httpConfig.ssrUrl + '/' + this._url.replace(/^\/+/g, '')  //Remove first slash
    }
    return `${this._url}`
  }

  public set url(url: string) {
    this._url = url
  }



  //---------------------------------------------------------------------//

  /**
   * Get some resources - Action = 'get'
   * @param opts http options
   */
  getAll(opts = {}) {
    return this._http.get(this.url, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //getAll

  //---------------------------------------------------------------------//

  /**
   * Get some resources
   * @param action The method on the controller
   * @param opts http options
   */
  getAllAction(action: string, opts = {}) {
    // console.log('getAllAction');
    return this._http.get(this.url + '/' + action, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //getAllAction

  //---------------------------------------------------------------------//

  /**
   * Adds params to url then GETs
   * @param params QueryP Paramaters as string
   * @param opts http options
   * @returns Observable<?>
   */
  getAllQueryParams(params: string, opts = {}) {
    return this._http.get(this.url + params, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //getAllAction

  //---------------------------------------------------------------------//

  getAllUrl(url: string, opts = {}) {
    return this._http.get(url, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //getAllUrl

  //---------------------------------------------------------------------//

  /**
   * Get a resource
   * @param action The method on the controller
   * @param name resource identifier
   * @param opts http options
   */
  getActionName(action: string, name: Identifier, opts = {}) {
    const url = this.url + '/' + action + '/' + name;

    return this._http.get(url, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //getActionName

  //---------------------------------------------------------------------//

  /**
   * Get a resource
   * @param action The method on the controller
   * @param date resource identifier / date
   * @param opts http options
   */
  getActionDate(action: string, date: Date, opts = {}) {
    const url = this.url + '/' + action + '/' + DateHelpers.To_dd_MMM_YYYY(date);

    return this._http.get(url, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //getActionName

  //---------------------------------------------------------------------//

  getName(name: string, opts = {}) {
    const url = this.url + '/' + name;

    return this._http.get(url, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //getName

  //---------------------------------------------------------------------//

  getAction(action: string, opts = {}) {
    const url = this.url + '/' + action;

    return this._http.get(url, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //getAction

  //---------------------------------------------------------------------//

  getActionTimeout(action: string, timeoutMillis: number = 60000, opts = {}) {
    const url = this.url + '/' + action;

    return this._http.get(url, opts ?? {}).pipe(
      map(this.extractData),
      timeout(timeoutMillis),
      catchError((error) => this.handleError(error))
    );
  } //getAction

  //---------------------------------------------------------------------//

  /** Same as getActionName with action set to 'Get' */
  getGetActionName(name: string | number, opts = {}) {
    return this.getActionName('get', name, opts);
  } //getActionName

  //---------------------------------------------------------------------//

  get(id: string | number, opts = {}) {
    const url = this.url + '/' + id;

    return this._http.get(url, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //get

  //---------------------------------------------------------------------//

  getTimeout(id: string | number, timeoutMillis: number = 60000, opts = {}) {
    const url = this.url + '/' + id;

    return this._http.get(url, opts ?? {}).pipe(
      map(this.extractData),
      timeout(timeoutMillis),
      catchError((error) => this.handleError(error))
    );
  } //getTimeout

  //---------------------------------------------------------------------//

  post(resource: any, opts = {}) {
    return this._http.post(this.url, resource, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //post

  //---------------------------------------------------------------------//

  /**
   * Post a resource
   * @param action The method on the controller
   * @param resource The payload
   * @param opts http options
   * @p
   */
  postAction(action: string, resource: any, opts = {}) {
    const url = this.url + '/' + action;

    return this._http.post(url, resource, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //post

  //---------------------------------------------------------------------//

  protected postActionTimeout(action: string, resource: any, timeoutMillis: number = 60000, opts = {}) {
    const url = this.url + '/' + action;

    return this._http.post(url, resource, opts ?? {}).pipe(
      map(this.extractData),
      timeout(timeoutMillis),
      catchError((error) => this.handleError(error))
    );
  } //postAction

  //---------------------------------------------------------------------//

  /**
   *  Post - action  = 'add'
   * @param resource The payload
   * @param opts http options
   */
  add = (resource: any, opts = {}): Observable<any> =>
    this.postAction('add', resource, opts)

  //---------------------------------------------------------------------//

  getPage = <T>(pagedRequest: PagedRequest, opts = {}): Observable<PagedResponse<T>> =>
    this.postAction('page', pagedRequest, opts)

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  getPageById = <T>(pagedRequest: PagedRequest, id: Identifier, opts = {}): Observable<PagedResponse<T>> =>
    this.postAction(`page/${id}`, pagedRequest, opts)

  //---------------------------------------------------------------------//

  getPagePkg = <Pkg>(pagedRequest: PagedRequest, opts = {}): Observable<Pkg> =>
    this.postAction('pagePackage', pagedRequest, opts)

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  getPagePkgById = <Pkg>(pagedRequest: PagedRequest, id: Identifier, opts = {}): Observable<Pkg> =>
    this.postAction(`pagePackage/${id}`, pagedRequest, opts)

  //---------------------------------------------------------------------//

  /**
   * Gets paged data restricted by id
   * @param action
   * @param id
   * @param pagedRequest
   * @param opts
   * @returns
   */
  getPageActionById<T>(action: string, id: Identifier, pagedRequest: PagedRequest, opts = {}): Observable<T> {

    const url = `${this.url}/${action}/${id}`

    return this._http.post(url, pagedRequest, opts).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //getPageActionById

  //---------------------------------------------------------------------//

  patchAction(action: string, resource: any, opts = {}) {
    return this._http.patch(this.url + '/' + action, resource, opts).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //patchAction

  //---------------------------------------------------------------------//

  /**
   * Patch a resource with the resources property as query param
   * @param action The method on the controller
   * @param name resource identifier
   * @param opts http options
   */
  patchActionName(action: string, name: Identifier, opts = {}) {
    const url = this.url + '/' + action + '/' + name;

    return this._http.patch(url, opts ?? {}).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //patchActionName

  //---------------------------------------------------------------------//

  /**
   * Same as patchAction with action set to 'edit'
   * @param resource object with the new data/settings. Must contains an id or some other identifier.
   * @param opts http options
   */
  edit = (resource: any, opts = {}) =>
    this.patchAction('edit', resource, opts);

  //---------------------------------------------------------------------//

  update(id: string | number, resource: any, opts = {}) {
    const patchUrl = this.url + '/' + id;

    return this._http.patch(patchUrl, resource, opts).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //update

  //---------------------------------------------------------------------//

  delete(id: string | number, opts = {}): Observable<any> {
    const deleteUrl = this.url + '/' + id;

    return this._http.delete(deleteUrl, opts).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //delete

  //---------------------------------------------------------------------//

  /**
   * Same as deleteAction with actions set to 'delete'
   * @param id resource identifier
   * @param opts http options
   */
  deleteDelete(id: Identifier, opts = {}): Observable<any> {
    return this.deleteAction('delete', id, opts);
  } //delete

  //---------------------------------------------------------------------//

  /**
   * Delete a resource
   * @param action The method on the controller
   * @param id resource identifier
   * @param opts http options
   */
  deleteAction(action: string, id: Identifier, opts = {}) {
    const deleteUrl = this.url + '/' + action + '/' + id;

    return this._http.delete(deleteUrl, opts).pipe(
      map(this.extractData),
      catchError((error) => this.handleError(error))
    );
  } //deleteAction

  //---------------------------------------------------------------------//

  protected handleError(httpErrorResponse: HttpErrorResponse) {
    // console.log('httpErrorResponse', httpErrorResponse)

    //Do we have any idea what happened
    if (
      !httpErrorResponse ||
      (!httpErrorResponse.headers && !httpErrorResponse.error)
    )
      return throwError(
        () => new HttpError(httpErrorResponse, 666, 'Server Error')
      );

    const statusCode = httpErrorResponse.status
      ? httpErrorResponse.status
      : 666;
    const statusText = httpErrorResponse.statusText
      ? httpErrorResponse.statusText
      : 'Unknown Error';
    //This is where the model state errors would go
    const error = httpErrorResponse?.error;



    //This means we're having a problem reading the response
    if (statusCode === StatusCodes.OK)
      return throwError(() => new UnreadableResponseError(error));

    if (statusCode === StatusCodes.NOT_FOUND)
      return throwError(() => new NotFoundError(error));

    if (statusCode === StatusCodes.BAD_REQUEST) {
      const modelStateErrors = this.lookForBadRequestErrors(error);

      if (modelStateErrors.getList().length > 0)
        return throwError(
          () =>
            new BadRequestError(
              modelStateErrors.getFirstErrorMessage(),
              modelStateErrors,
              httpErrorResponse.error
            )
        );
    } //if

    //Known error?
    const nonBadRequestHttpError =
      HttpError.getNonBadRequestErrorFromStatusCode(statusCode);

    //if error IS the message, get it.
    if (error && typeof error === 'string')
      nonBadRequestHttpError.message = error;

    //if error HAS a message, get it.
    if (error?.message) nonBadRequestHttpError.message = error.message;

    // //if httpErrorResponse has a message, use it.
    // if (httpErrorResponse?.message)
    //     nonBadRequestHttpError.message = httpErrorResponse.message

    //If it's an InternalServerError and still no message: Add our own.
    //use '==' in case stasusCode is a string
    // tslint:disable-next-line: triple-equals
    if (
      !nonBadRequestHttpError?.message &&
      statusCode == StatusCodes.INTERNAL_SERVER_ERROR
    )
      nonBadRequestHttpError.message = INTERNAL_SERVER_ERROR_MESSAGE;

    //Just pass on Non-BadRequestErrors
    if (nonBadRequestHttpError) return throwError(() => nonBadRequestHttpError);

    //Look for custom sever error header
    let applicationErrorMsg =
      httpErrorResponse.headers.get('Application-Error') ?? '';

    //Either applicationError in header or model error in body
    if (applicationErrorMsg)
      return throwError(
        () =>
          new HttpError(
            httpErrorResponse,
            statusCode,
            statusText,
            applicationErrorMsg
          )
      );

    //If error is a string just pass it on as a message
    if (typeof error === 'string')
      return throwError(
        () => new HttpError(httpErrorResponse, statusCode, statusText, error)
      );

    //If we got an embedded error message use it
    if (httpErrorResponse?.error?.message)
      return throwError(
        () =>
          new HttpError(
            httpErrorResponse,
            statusCode,
            statusText,
            httpErrorResponse?.error?.message
          )
      );

    //If it's not there look for other message
    if (!applicationErrorMsg)
      applicationErrorMsg = httpErrorResponse?.message
        ? httpErrorResponse.message
        : '';

    //Last resort
    return throwError(
      () =>
        new HttpError(
          httpErrorResponse,
          statusCode,
          statusText,
          applicationErrorMsg
        )
    );
  } //handleError

  //---------------------------------------------------------------------//

  /**Checks for model state errors and packages them up and returns the package */
  private lookForBadRequestErrors(error: any): ModelStateErrors {
    const modelStateErrors = new ModelStateErrors();

    //Multile ModelStateErrors will be placed in an 'errors' object
    if (error?.errors) error = error.errors;

    //Concatanate all errors.
    if (error) {
      // console.log(error);
      for (const key in error) {
        if (error[key]) {
          modelStateErrors.addModelStateError(key, error[key]);
        }
      } //For
    } //If

    return modelStateErrors;
  } //lookForBadRequestErrors

  //---------------------------------------------------------------------//

  protected extractData(res: any) {
    return res;
    // return res.text() ? res.json() : {};
  } //extractData

  //---------------------------------------------------------------------//
} //Cls

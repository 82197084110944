import { StringHelpers } from '@inigo/gen-helpers/text';
/// <summary>
/// Different ways to filter data
/// </summary>
export enum FilterType {
  EQUALS = 'equals',
  STARTS_WITH = 'starts_with',
  ENDS_WITH = 'ends_with',
  CONTAINS = 'contains',

  GREATER_THAN = 'greater_than',
  LESS_THAN = 'less_than',

  LESS_THAN_OR_EQUAL_TO = 'less_than_or_equal_to',
  GREATER_THAN_OR_EQUAL_TO = 'greater_than_or_equal_to',
  NOT_EQUAL_TO = 'not_equal_to',
  BETWEEN = 'between',
  IS_NULL = 'is_null',
  IS_EMPTY = 'is_empty',
  IN = 'in',
  IS_NOT_NULL = 'is_not_null',
  IS_NOT_EMPTY = 'is_not_empty',
  IS_NULL_OR_WHITE_SPACE = 'is_null_or_white_space',
  IS_NOT_NULL_OR_WHITE_SPACE = 'is_not_null_or_white_space',

  NONE = 'none',
} //Enm

//#################################################################################################//
//#################################################################################################//

/// <summary>
/// Different ways to filter data
/// </summary>
export class FilterTypes {
  public static readonly EQUALS = 'equals';
  public static readonly STARTS_WITH = 'starts_with';
  public static readonly ENDS_WITH = 'ends_with';
  public static readonly CONTAINS = 'contains';
  public static readonly GREATER_THAN = 'greater_than';
  public static readonly LESS_THAN = 'less_than';

  public static readonly LESS_THAN_OR_EQUAL_TO = 'less_than_or_equal_to';
  public static readonly GREATER_THAN_OR_EQUAL_TO = 'greater_than_or_equal_to';
  public static readonly NOT_EQUAL_TO = 'not_equal_to';
  public static readonly BETWEEN = 'between';
  public static readonly IS_NULL = 'is_null';
  public static readonly IS_EMPTY = 'is_empty';
  public static readonly IN = 'in';
  public static readonly IS_NOT_NULL = 'is_not_null';
  public static readonly IS_NOT_EMPTY = 'is_not_empty';
  public static readonly IS_NULL_OR_WHITE_SPACE = 'is_null_or_white_space';
  public static readonly IS_NOT_NULL_OR_WHITE_SPACE =
    'is_not_null_or_white_space';

  private static _stringToEnumMap: Map<string, FilterType> = new Map([
    [FilterTypes.EQUALS, FilterType.EQUALS],
    [FilterTypes.STARTS_WITH, FilterType.STARTS_WITH],
    [FilterTypes.ENDS_WITH, FilterType.ENDS_WITH],
    [FilterTypes.CONTAINS, FilterType.CONTAINS],
    [FilterTypes.GREATER_THAN, FilterType.GREATER_THAN],
    [FilterTypes.LESS_THAN, FilterType.LESS_THAN],
    [FilterTypes.LESS_THAN_OR_EQUAL_TO, FilterType.LESS_THAN_OR_EQUAL_TO],
    [FilterTypes.GREATER_THAN_OR_EQUAL_TO, FilterType.GREATER_THAN_OR_EQUAL_TO],
    [FilterTypes.NOT_EQUAL_TO, FilterType.NOT_EQUAL_TO],
    [FilterTypes.BETWEEN, FilterType.BETWEEN],
    [FilterTypes.IS_NULL, FilterType.IS_NULL],
    [FilterTypes.IS_EMPTY, FilterType.IS_EMPTY],
    [FilterTypes.IN, FilterType.IN],
    [FilterTypes.IS_NOT_EMPTY, FilterType.IS_NOT_EMPTY],
    [FilterTypes.IS_NULL_OR_WHITE_SPACE, FilterType.IS_NULL_OR_WHITE_SPACE],
    [FilterTypes.IS_EMPTY, FilterType.IS_EMPTY],
    [
      FilterTypes.IS_NOT_NULL_OR_WHITE_SPACE,
      FilterType.IS_NOT_NULL_OR_WHITE_SPACE,
    ],
  ]);

  //--------------------------------------------------------------------//

  static asEnum(filterType: string): FilterType | null {
    //Equals should work on all types
    if (!filterType || !this._stringToEnumMap.has(filterType))
      return FilterType.EQUALS;

    return this._stringToEnumMap.get(filterType) ?? null;
  } //asEnum

  //--------------------------------------------------------------------//
} //Cls

//#################################################################################################//
//#################################################################################################//

export interface FilterListDropDownItem {
  value: FilterType;
  viewValue: string;
} //Cls

//#################################################################################################//

export const FILTER_TYPES_STRING: FilterListDropDownItem[] = [
  { value: FilterType.EQUALS, viewValue: '=' },
  { value: FilterType.NOT_EQUAL_TO, viewValue: '<>' },
  { value: FilterType.CONTAINS, viewValue: 'contains' },
  { value: FilterType.STARTS_WITH, viewValue: 'starts with' },
  { value: FilterType.ENDS_WITH, viewValue: 'ends with' },
];

//#################################################################################################//

export const FILTER_TYPES_DATE: FilterListDropDownItem[] = [
  { value: FilterType.EQUALS, viewValue: '=' },
  { value: FilterType.NOT_EQUAL_TO, viewValue: '<>' },
  { value: FilterType.GREATER_THAN, viewValue: '>' },
  { value: FilterType.GREATER_THAN_OR_EQUAL_TO, viewValue: '≥' },
  { value: FilterType.LESS_THAN, viewValue: '<' },
  { value: FilterType.LESS_THAN_OR_EQUAL_TO, viewValue: '≤' },
  { value: FilterType.BETWEEN, viewValue: 'between' },
];

//#################################################################################################//

export const FILTER_TYPES_NUMBER: FilterListDropDownItem[] = [
  { value: FilterType.EQUALS, viewValue: '=' },
  { value: FilterType.NOT_EQUAL_TO, viewValue: '<>' },
  { value: FilterType.GREATER_THAN, viewValue: '>' },
  { value: FilterType.GREATER_THAN_OR_EQUAL_TO, viewValue: '≥' },
  { value: FilterType.LESS_THAN, viewValue: '<' },
  { value: FilterType.LESS_THAN_OR_EQUAL_TO, viewValue: '≤' },
];

//#################################################################################################//

export const FILTER_TYPES_BOOLEAN: FilterListDropDownItem[] = [
  { value: FilterType.EQUALS, viewValue: '=' },
  // { value: FilterType.NOT_EQUAL_TO, viewValue: '<>' }
];

//#################################################################################################//

export const FILTER_TYPES_LIST: FilterListDropDownItem[] = [
  { value: FilterType.EQUALS, viewValue: '=' },
  { value: FilterType.NOT_EQUAL_TO, viewValue: '<>' },
];

//#################################################################################################//

export const FILTER_TYPES_LIST_MULTI_SELECT: FilterListDropDownItem[] = [
  { value: FilterType.IN, viewValue: 'In Selection' },
];

//#################################################################################################//

export const FILTER_TYPES_ALL_MAP: Map<FilterType, string> = new Map([
  [FilterType.EQUALS, '='],
  [FilterType.NOT_EQUAL_TO, '<>'],
  [FilterType.GREATER_THAN, '>'],
  [FilterType.GREATER_THAN_OR_EQUAL_TO, '≥'],
  [FilterType.LESS_THAN, '<'],
  [FilterType.LESS_THAN_OR_EQUAL_TO, '≤'],
  [FilterType.BETWEEN, 'between'],
  [FilterType.CONTAINS, 'contains'],
  [FilterType.STARTS_WITH, 'starts with'],
  [FilterType.ENDS_WITH, 'ends with'],
]);

//#################################################################################################//

export function GetFilterTypeSymbol(filterType: FilterType): string | null {
  if (!FILTER_TYPES_ALL_MAP.has(filterType)) return '=';
  else return FILTER_TYPES_ALL_MAP.get(filterType) ?? null;
} //getFilterTypeSymbol

//#################################################################################################//

export class FilterListItem implements FilterListDropDownItem {
  value: any;
  viewValue: string;

  /**
   * @param value Underlying item
   * @param viewValue What to show in the drop down list
   */
  constructor(value: any, viewValue?: string | number) {
    this.value = value;
    this.viewValue = `${viewValue ?? StringHelpers.ToTitleCase(value)}`
  } //ctor
} //Cls

//#################################################################################################//

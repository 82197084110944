import { InjectionToken } from '@angular/core';
import { StringHelpers } from '@inigo/gen-helpers/text';

export const ContactDetailsConfigService = new InjectionToken<ContactDetailsConfig>('ContactDetailsConfig');

//=======================================================================================//

export class ContactDetailsConfig {
  
  private _companyName: string = 'Inigo';
  /** Company name . Default = TEST_DATA*/
  public get companyName(): string {
    return StringHelpers.Clone(this._companyName);
  }

  private _latitude: number = 53.447767;
  /** Latitude of location. Default = TEST_DATA*/
  public get latitude(): number {
    return this._latitude;
  }

  private _longitude: number = -6.587618;
  /** Longitude of location. Default = TEST_DATA*/
  public get longitude(): number {
    return this._longitude;
  }

  private _address: string[] = [
    'Inigo Softaware Solutions Ltd.',
    'Greenfield House',
    'Maynooth',
    'Co. Kildare',
    'A86 KA66',
  ];
  /** . Default = TEST_DATA*/
  public get address(): string[] {
    return this._address;
  }

  private _phone: string = '087 6645947';
  /** Contact phone. Default = TEST_DATA*/
  public get phone(): string {
    return StringHelpers.Clone(this._phone);
  }

  private _phone2: string = '';
  /** Contact phone - Alternate. Default = ""*/
  public get phone2(): string {
    return StringHelpers.Clone(this._phone2);
  }

  private _email: string = 'info@inigo.ie';
  /** Contact email. Default = TEST_DATA*/
  public get email(): string {
    return StringHelpers.Clone(this._email);
  }

  private _insta?: string;
  /** Instagram address. Default = TEST_DATA*/
  public get insta(): string {
    return StringHelpers.Clone(this._insta);
  }

  private _twtr?: string;
  /** Twitter address. Default = TEST_DATA*/
  public get twtr(): string {
    return StringHelpers.Clone(this._twtr);
  }

  private _fBook: string = '';
  /** Facebook address. Default = TEST_DATA*/
  public get fBook(): string {
    return StringHelpers.Clone(this._fBook);
  }

  private _mapIconUrl = '/assets/images/brand/brand.png';
  /** Path to map icon - default: '/assets/images/brand/brand.png'*/
  public get mapIconUrl(): string {
    return StringHelpers.Clone(this._mapIconUrl);
  }

  /**
   * @param companyName Company name. Default = TEST_DATA
   * @param latitude Latitude of location. Default = TEST_DATA
   * @param longitude Longitude of location. Default = TEST_DATA
   * @param address Location address. Default = TEST_DATA
   * @param phone Contact phone. Default = TEST_DATA
   * @param email Contact email. Default = TEST_DATA
   * @param insta Instagram address. Default = TEST_DATA
   * @param twtr Twitter address. Default = TEST_DATA
   * @param fBook Facebook address. Default = TEST_DATA
   * @param mapIconUrl Path to map icon - default: '/assets/images/brand/brand.png'
   */
  private constructor(
    companyName: string = 'Inigo',
    latitude: number = 53.447767,
    longitude: number = -6.587618,
    address: string[] = [
      'Inigo Softaware Solutions Ltd.',
      'Greenfield House',
      'Maynooth',
      'Co. Kildare',
      'A86 KA66',
    ],
    phone: string = '087 6645947',
    email: string = 'info@inigo.ie',
    insta?: string,
    twtr?: string,
    fBook: string = '',
    mapIconUrl = '/assets/images/brand/brand.png'
  ) {
    this._companyName = companyName;
    
    this._latitude = latitude;
    this._longitude = longitude;
    this._address = address;
    this._phone = phone;
    this._email = email;
    this._insta = insta;
    this._twtr = twtr;
    this._fBook = fBook;
    this._mapIconUrl = mapIconUrl;
  } //ctor

  //---------------------------------------------------------------------//

  /**
   * Create new instance of ContactDetailsConfig
   * @param companyName Company name. Default = TEST_DATA
   */
  static Create(companyName: string = 'Inigo'): ContactDetailsConfig {
    return new ContactDetailsConfig(companyName);
  } //create

  //---------------------------------------------------------------------//

  /**
   * @param companyName Company name. Default = TEST_DATA
   * @returns Updated ContactDetailsConfig
   */
  setCompanyName(companyName: string): ContactDetailsConfig {
    this._companyName = companyName;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param latitude Latitude of location. Default = TEST_DATA
   * @returns Updated ContactDetailsConfig
   */
  setLatitude(latitude: number): ContactDetailsConfig {
    this._latitude = latitude;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param longitude Longitude of location. Default = TEST_DATA
   * @returns Updated ContactDetailsConfig
   */
  setLongitude(longitude: number): ContactDetailsConfig {
    this._longitude = longitude;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param address Location address. Default = TEST_DATA
   * @returns Updated ContactDetailsConfig
   */
  setAddress(address: string[]): ContactDetailsConfig {
    this._address = address;
    return this;
  }
  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param phone Contact phone. Default = TEST_DATA
   * @returns Updated ContactDetailsConfig
   */
  setPhone(phone: string): ContactDetailsConfig {
    this._phone = phone;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param phone2 Contact phone. Default = ""TEST_DATA""
   * @returns Updated ContactDetailsConfig
   */
  setPhone2(phone2: string): ContactDetailsConfig {
    this._phone2 = phone2;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param email Contact email. Default = TEST_DATA
   * @returns Updated ContactDetailsConfig
   */
  setEmail(email: string): ContactDetailsConfig {
    this._email = email;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param insta Instagram address. Default = TEST_DATA
   * @returns Updated ContactDetailsConfig
   */
  setInsta(insta: string): ContactDetailsConfig {
    this._insta = insta;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param twtr Twitter address. Default = TEST_DATA
   * @returns Updated ContactDetailsConfig
   */
  setTwtr(twtr: string): ContactDetailsConfig {
    this._twtr = twtr;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param fBook Facebook address. Default = TEST_DATA
   * @returns Updated ContactDetailsConfig
   */
  setFBook(fBook: string): ContactDetailsConfig {
    this._fBook = fBook;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param mapIconUrl Path to map icon - default: '/assets/images/brand/brand.png'
   * @returns Updated ContactDetailsConfig
   */
  setMapIconUrl(mapIconUrl: string): ContactDetailsConfig {
    this._mapIconUrl = mapIconUrl;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//
} //Cls

//=======================================================================================//

export const TEST_CONTACT_DETAILS = ContactDetailsConfig.Create(
  'Inigo Software Solutions'
)
  .setLatitude(53.447767)
  .setLongitude(-6.587618)
  .setPhone('087 666666')
  .setPhone2('01 123 456')
  .setMapIconUrl('assets/images/location/map-icon.png')
  .setEmail('info@inigo.ie')
  .setTwtr('https://twitter.com/grangegeethinn')
  .setFBook(
    'https://www.facebook.com/pages/category/Product-Service/Conecto-1170157216456282/'
  )
  .setInsta('https://www.instagram.com/thegrangegeethinn/')
  .setAddress([
    'Inigo',
    'Greenfield House',
    'Maynooth',
    'Co. Kildare',
    'A86 KA66',
  ]);

// = new ContactDetailsConfig(
//     'Inigo Software Solutions',
//     53.447767,
//     -6.587618,
//     ['Inigo', 'Greenfield House', 'Maynooth', 'Co. Kildare', 'A86 KA66'],
//     '087 666666',
//     'info@inigo.ie',
//     null,
//     null,
//     'https://www.facebook.com/pages/category/Product-Service/Conecto-1170157216456282/',
//     '/assets/images/location/map-icon.png',
// )

import { InjectionToken } from '@angular/core';
import { ThemeOption } from './theme-option.model';

//===================================================================//

export const ThemeConfigService =  new InjectionToken<ThemeConfig>('ThemeConfig');

//===================================================================//

export class ThemeConfig {
  /** Different themes to choose from - Default = all themes*/
  themeOptions: ThemeOption[] = [
    {
      isDarkMode: false,
      label: 'blue-peach',
      value: 'blue-peach',
      primaryColor: '#0d79d8',
      secondaryColor: '#eea47f',
      classIdx: 1,
    },
    {
      isDarkMode: true,
      label: 'independent-china',
      value: 'independent-china',
      primaryColor: '#e36397',
      secondaryColor: '#344866',
      classIdx: 2,
    },
    {
      isDarkMode: false,
      label: 'mountbatton-oj',
      value: 'mountbatton-orange',
      primaryColor: '#ff784f',
      secondaryColor: '#90708c',
      classIdx: 3,
    },
  ];

  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * Enter no paramaters to use default values on everything
   * @param themeOptions Different themes to choose from - Default = all themes
   */
 private constructor(themeOptions?: ThemeOption[]) {
    if (themeOptions) this.themeOptions = themeOptions;
  } //ctor

  //---------------------------------------------------------------------//

    /**
     * Create new instance of BgAngledSplitConfig
     * @param colorLhs Color for Left Hand Side of background - default colorPrimary
     * @param colorRhs Color for Right Hand Side of background - default colorPrimaryLight
     */
    static Create(themeOptions?: ThemeOption[]): ThemeConfig {

      return new ThemeConfig(themeOptions)

  }//create

  //---------------------------------------------------------------------//    



} //Cls

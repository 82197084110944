import { TrouncerService } from '../../services/spinners/trouncer/trouncer.service';
import { Injectable, inject } from '@angular/core';
import { SuccessDialogService } from '../dialogs/success/success-dialog.service';
import { BouncerService } from '../spinners/bouncer/bouncer.service';
import { DialogService } from '../basic/dialog.service';
import { ErrorDialogService } from '../dialogs/error/error-dialog.service';
import { DotSpinnerService } from '../spinners/dot-spinner/dot-spinner.service';

/**
 * Utility service to combine all types of pop-up in one place.
 */
@Injectable({
  providedIn: 'root',
})
export class PopUpService {

    public trouncer= inject( TrouncerService)
    public bouncer= inject( BouncerService)
    public dotSpinner= inject( DotSpinnerService)
    public errorDialog= inject( ErrorDialogService)
    public successDialog= inject( SuccessDialogService)
    public dialog= inject( DialogService)

} //Cls

//################################################################################//

import { InsertedDto } from '@inigo/data/insert';

export type Operation =
  | 'add'
  | 'delete'
  | 'edit'
  | 'export'
  | 'template'
  | 'other';

//################################################################################//

export class StoreResultInfo {
  constructor(public type: Operation, public msg: string) {}
} //Cls

//################################################################################//

export class SuccessInfo<T> extends StoreResultInfo {
  item?: T;

  //----------------------------------------------------//

  constructor(type: Operation, msg: string) {
    super(type, msg);
  }

  //----------------------------------------------------//

  static Add<T>(itemType: string, itemName: string, item?: T): SuccessInfo<T> {

    const info = new SuccessInfo<T>(
      'add',
      `${itemType ?? 'item'}, ${itemName ?? ''} has been added!`
    );
    info.item = item;
    
    return info

  }//Add

  //- - - - - - - - - - - - - - - - - - - - - - - - - - //

  static Edit<T>(itemType: string, itemName: string, item?: T): SuccessInfo<T> {
    const info = new SuccessInfo<T>(
      'edit',
      `${itemType ?? 'item'}, ${itemName ?? ''} has been updated!`
    );
    info.item = item;
    return info

  }//Edit

  //- - - - - - - - - - - - - - - - - - - - - - - - - - //

  static Delete<T>(itemType: string, itemName: string): SuccessInfo<T> {
  
    return new SuccessInfo<T>(
      'delete',
      `${itemType ?? 'item'}, ${itemName ?? ''} has been deleted!`
    );
  
  }//Delete

  //- - - - - - - - - - - - - - - - - - - - - - - - - - //

  static Export<T>(fileName: string): SuccessInfo<T> {

    return new SuccessInfo<T>(
      'export',
      `Export, ${fileName} is ready, check downloads folder!`
    )

  }//Export

  //- - - - - - - - - - - - - - - - - - - - - - - - - - //

  static Template<T>(fileName: string): SuccessInfo<T> {

    return new SuccessInfo<T>(
      'template',
      `Template, ${fileName} is ready, check downloads folder!`
    )

  }//Template

  //- - - - - - - - - - - - - - - - - - - - - - - - - - //

  static Other<T>(msg: string, item?: any): SuccessInfo<T> {

    const info = new SuccessInfo<T>('other', msg);
    info.item = item;
    return info

  }//Other

  //----------------------------------------------------//
} //Cls

//################################################################################//

export class ErrorInfo extends StoreResultInfo {
  constructor(type: Operation, msg: string) {
    super(type, msg);
  }

  //- - - - - - -  - - - - - -  - - - - - - - - - - - - - //
  static Add = (message: string): ErrorInfo => new ErrorInfo('add', message);
  static Edit = (message: string): ErrorInfo => new ErrorInfo('edit', message);
  static Delete = (message: string): ErrorInfo =>
    new ErrorInfo('delete', message);
  static Export = (message: string): ErrorInfo =>
    new ErrorInfo('export', message);
  static Template = (message: string): ErrorInfo =>
    new ErrorInfo('template', message);
  static Other = (msg: string): ErrorInfo => new ErrorInfo('other', msg);
} //Cls

//################################################################################//

export class DuplicateErrorInfo<T> extends StoreResultInfo {
  constructor(
    type: Operation,
    public dto: InsertedDto<T>,
    public originalItem: T
  ) {
    super(type, 'Possible Duplicates Found');
  }

  //- - - - - - -  - - - - - -  - - - - - - - - - - - - - //

  static Add<T>(dto: InsertedDto<T>, originalItem: T): DuplicateErrorInfo<T> {
    return new DuplicateErrorInfo('add', dto, originalItem);
  }

  static Edit<T>(dto: InsertedDto<T>, originalItem: T): DuplicateErrorInfo<T> {
    return new DuplicateErrorInfo('edit', dto, originalItem);
  }
} //Cls

//################################################################################//

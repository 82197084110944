import { Identifier } from '@inigo/data/misc';

export class ShopFile {
  
  /** Database Identifier*/
  public id: Identifier    

  /** Where the image is stored. */
  public url?: string;

} //Cls

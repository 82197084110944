import { Identifier } from '@inigo/data/misc';
import { ShopDocument } from './shop-doc';
import { ShopImg as ShopImg } from './shop-img';

export class ShopItem {
  /** Database Identifier */
  public id: Identifier;

  /** More info */
  public description?: string;

  /** Display name */
  public name?: string;

  /** Id of main image representing this item */
  public imgId: Identifier;

  /** Main Image representing this item */
  public img?: ShopImg;

  /** Path to display image */
  public imgUrl?: string;

  /** Id of image representing this item  */
  public img2Id: Identifier;

  /** Image representing this item */
  public img2?: ShopImg;

  /** Path to display image */
  public img2Url?: string;

  /** Id of image representing this item */
  public img3Id: Identifier;

  /** Image representing this item  */
  public img3?: ShopImg;

  /** Path to display image */
  public img3Url?: string;
  

  /** External link to video file */
  public linkVideo?: string;

  /** External link to video file */
  public linkVideo2?: string;

  /** External link to video file */
  public linkVideo3?: string;

  /** External link to download document file */
  public linkDocument?: string;

} //Cls

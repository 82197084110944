<div
  class="toast"
  [style.background-color]="_bgColor()"
  [@fadeAnimation]="{
    value: _animationState(),
    params: {
      fadeIn: _toastConfig.animationConfig.fadeInMs,
      fadeOut: _toastConfig.animationConfig.fadeOutMs
    }
  }"
  (@fadeAnimation.done)="onFadeFinished($event)">
  
  <div id="button-close" [style.color]="_txtColor()" (click)="close()">
    <ng-container [ngTemplateOutlet]="iconTemplate" [ngTemplateOutletContext]="{ iconType: _iconType()}" /> 
  </div>

  <div class="txt-container">
    <div class="toast-text" [style.color]="_txtColor()">{{ _txt() }}</div>
  </div>

  <div id="button-close" [style.color]="_txtColor()" (click)="close()">
    <ng-container [ngTemplateOutlet]="closeButtonTemplate" /> 
  </div>

</div>

<!-- ------------------------------------------------------------------------------------------------------------- -->

<ng-template #closeButtonTemplate>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="currentColor"/>
      <path d="M0 0h24v24H0z" fill="none" />
  </svg>
</ng-template>

<!-- ------------------------------------------------------------------------------------------------------------- -->

<ng-template #iconTemplate let-iconType="iconType">
  
  <ng-container [ngSwitch]="iconType">
    <svg *ngSwitchCase="_toastIconWarn"
       xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" />
    </svg>
      <svg *ngSwitchCase="_toastIconError"
        xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
      </svg>

      <svg *ngSwitchCase="_toastIconSuccess"
        xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
      </svg>

      <svg *ngSwitchCase="_toastIconInfo"
        xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path  d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
      </svg>

      <svg *ngSwitchDefault
        xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
      </svg>
  </ng-container>

</ng-template>

<!-- ------------------------------------------------------------------------------------------------------------- -->
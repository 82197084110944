import { InjectionToken } from '@angular/core';
import { StringHelpers } from '@inigo/gen-helpers/text';
import { DB_BACKUP_CREATION_INFO, JobCreationInfo } from './job-creation-info';

export const HangfireConfigService = new InjectionToken<HangfireConfig>('HangfireConfig')
export const HF_ROUTE_DEFAULT = '/inigoadminhangfire'

//===================================================================//

export class HangfireConfig {

  private _hangfireRoute = HF_ROUTE_DEFAULT
  /** Route for HangFire page - default: '/inigoadminhangfire' */
  public get hangfireRoute(): string { return StringHelpers.Clone(this._hangfireRoute) }

  private _apiUrlPrefix = 'api'
  /** Where the controllers live - default: '/api' */
  public get apiUrlPrefix(): string { return StringHelpers.Clone(this._apiUrlPrefix) }

  private _apiControllerFull = `${this.apiUrlPrefix}/hangfire`
  /** Url for HangFire controller (including api prefix) - default: '/api/hangfire' */
  public get apiControllerFull(): string { return StringHelpers.Clone(this._apiControllerFull) }

  private _apiDbBackupControllerFull = `${this.apiUrlPrefix}/dbBackup`
  /** Url for HangFire controller (including api prefix) - default: '/api/hangfire' */
  public get apiDbBackupControllerFull(): string { return StringHelpers.Clone(this._apiDbBackupControllerFull) }

  private _apiCreateUrl = `${this.apiUrlPrefix}/hangfireJobs`
  /** Url for HangFire controller - default: '/api/jobs' */
  public get apiCreateUrl(): string { return StringHelpers.Clone(this._apiCreateUrl) }

  private _jobCreationInfos: JobCreationInfo[] = [DB_BACKUP_CREATION_INFO]
  /** Url for HangFire controller - default: [] */
  public get jobCreationInfos(): JobCreationInfo[] { return [...this._jobCreationInfos] }


  private _cancelJobAction = 'cancelJob'
  /** Url for any controller - default: '/cancelJob' */
  public get cancelJobAction(): string { return StringHelpers.Clone(this._cancelJobAction) }

  private _createJobAction = 'createJob'
  /** Url for any controller - default: '/createJob' */
  public get createJobAction(): string { return StringHelpers.Clone(this._createJobAction) }

  private _cancelProcessingJobAction = 'cancelProcessingJob'
  /** Url for HangFire controller - default: '/cancelProcessingJob' */
  public get cancelProcessingJobAction(): string { return StringHelpers.Clone(this._cancelProcessingJobAction) }

  private _cancelScheduledJobAction = 'cancelScheduledJob'
  /** Url for HangFire controller - default: '/cancelScheduledJob' */
  public get cancelScheduledJobAction(): string { return StringHelpers.Clone(this._cancelScheduledJobAction) }

  private _cancelRecurringJobAction = 'cancelRecurringJob'
  /** Url for HangFire controller - default: 'cancelRecurringJob' */
  public get cancelRecurringJobAction(): string { return StringHelpers.Clone(this._cancelRecurringJobAction) }


  private _getRecurringJobsAction = 'recurringJobs'
  /** Url for HangFire controller - default: 'recurringJobs' */
  public get getRecurringJobsAction(): string { return StringHelpers.Clone(this._getRecurringJobsAction) }

  private _getprocessingJobsAction = 'processingJobs'
  /** Url for HangFire controller - default: 'processingJobs' */
  public get getprocessingJobsAction(): string { return StringHelpers.Clone(this._getprocessingJobsAction) }

  private _getScheduledJobsAction = 'scheduledJobs'
  /** Url for HangFire controller - default: 'scheduledJobs' */
  public get getScheduledJobsAction(): string { return StringHelpers.Clone(this._getScheduledJobsAction) }


  private _getRecurringPageAction = 'recurringPage'
  /** Url for HangFire controller - default: 'recurringPage' */
  public get getRecurringPageAction(): string { return StringHelpers.Clone(this._getRecurringPageAction) }

  private _getProccessingPageAction = 'proccessingPage'
  /** Url for HangFire controller - default: 'proccessingPage' */
  public get getProccessingPageAction(): string { return StringHelpers.Clone(this._getProccessingPageAction) }

  private _getScheduledPageAction = 'scheduledPage'
  /** Url for HangFire controller - default: 'scheduledPage' */
  public get getScheduledPageAction(): string { return StringHelpers.Clone(this._getScheduledPageAction) }

  
  private _triggerRecurringJobAction = 'triggerRecurringJob'
  /** Url for HangFire controller - default: 'triggerRecurringJob' */
  public get triggerRecurringJobAction(): string { return StringHelpers.Clone(this._triggerRecurringJobAction) }


  private _dbBackupJobAction = 'getJob'
  /** Url for HangFire controller - default: 'scheduledPage' */
  public get dbBackupJobAction(): string { return StringHelpers.Clone(this._dbBackupJobAction) }

  private _getDbBackupJobId = 'job-id-db-backup-inigo-3215'
  /** Url for HangFire controller - default: 'scheduledPage' */
  public get getDbBackupJobId(): string { return StringHelpers.Clone(this._getDbBackupJobId) }

  private _dbBackupBackupDirAction = 'getBackupDir'
  /** Url for HangFire controller - default: 'scheduledPage' */
  public get dbBackupBackupDirAction(): string { return StringHelpers.Clone(this._dbBackupBackupDirAction) }

  private _dbBackupConnStringAction = 'getConnString'
  /** Url for HangFire controller - default: 'scheduledPage' */
  public get dbBackupConnStringAction(): string { return StringHelpers.Clone(this._dbBackupConnStringAction) }

  private _backToSiteRoute = '/admin/home'
  /** Url for HangFire controller - default: 'scheduledPage' */
  public get backToSiteRoute(): string { return StringHelpers.Clone(this._backToSiteRoute) }

  private _canEditCallback: () => boolean = () => false
  /** Url for HangFire controller - default: 'scheduledPage' */
  public get canEditCallback(): () => boolean { return this._canEditCallback  }


  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * @param hangfireRoute Route to Hangfire page- default: '/inigoadminhangfire'
   */
  constructor(hangfireRoute: string | null = HF_ROUTE_DEFAULT) {

    if (hangfireRoute) this._hangfireRoute = hangfireRoute

  } //ctor

  //---------------------------------------------------------------------//

  static Create(hangfireUrl?: string | null): HangfireConfig {

    const config = new HangfireConfig(hangfireUrl)
    return config

  }//Create

  //---------------------------------------------------------------------//

  setHangfireRoute(hangfireUrl: string): HangfireConfig {

    this._hangfireRoute = hangfireUrl
    return this

  }//setHangfireUrl


  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Set the place where the controllers live. Default = 'api'
   */
  setApiUrlPrefix(apiUrlPfx: string): HangfireConfig {

    this._apiUrlPrefix = apiUrlPfx
    return this

  }//setApiUrlPrefix

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Where to go when finished. Defauit: '/admin/home'
   */
  setBackToSiteRoute(backToSiteRoute: string): HangfireConfig {

    this._backToSiteRoute = backToSiteRoute
    return this

  }//setBackToSiteRoute

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Where to go when finished. Defauit: '/admin/home'
   */
  setCanEditCallback(callback: () => boolean): HangfireConfig {

    this._canEditCallback = callback
    return this

  }//setCanReadCallback

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * Set the main hangfire controller. Default = 'hangfire'
   */
  setHangfireController(controller: string): HangfireConfig {

    this._apiControllerFull = `${this.apiUrlPrefix}/${controller}`
    return this

  }//setHangfireController

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  setCancelRecurringJobAction(cancelRecurringJobAction: string): HangfireConfig {

    this._cancelRecurringJobAction = cancelRecurringJobAction
    return this

  }//setCancelRecurringJobAction

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  setCancelScheduledJobAction(cancelScheduledJobAction: string): HangfireConfig {

    this._cancelScheduledJobAction = cancelScheduledJobAction
    return this

  }//setCancelScheduledJobAction

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  setGetRecurringJobsAction(getRecurringJobs: string): HangfireConfig {

    this._getRecurringJobsAction = getRecurringJobs
    return this

  }//getRecurringJobs

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  setGetprocessingJobsAction(getprocessingJobs: string): HangfireConfig {

    this._getprocessingJobsAction = getprocessingJobs
    return this

  }//getprocessingJobs

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  setGetScheduledJobsAction(getScheduledJobs: string): HangfireConfig {

    this._getScheduledJobsAction = getScheduledJobs
    return this

  }//getScheduleJobs

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  setStartJobActions(actions: JobCreationInfo[]): HangfireConfig {

    this._jobCreationInfos = actions
    return this

  }//setStartJobActions

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  setTriggerRecurringJobAction(triggerRecurringJobAction: string): HangfireConfig {

    this._triggerRecurringJobAction = triggerRecurringJobAction
    return this

  }//setTriggerRecurringJobAction

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  appendStartJobActions(actions: JobCreationInfo[]): HangfireConfig {

    this._jobCreationInfos = this._jobCreationInfos.concat(actions);
    return this

  }//setStartJobActions

  //---------------------------------------------------------------------//

} //Cls

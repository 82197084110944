import { InjectionToken } from '@angular/core';
import { StringHelpers } from '@inigo/gen-helpers/text';

export const StripeConfigService = new InjectionToken<StripeConfig>('StripeConfig')

//===================================================================//

export class StripeConfig {

  private _apiUrlPrefix = 'api'
  /** Where the controllers live - default: '/api' */
  public get apiUrlPrefix(): string { return StringHelpers.Clone(this._apiUrlPrefix) }

  private _apiController = 'stripe'
  /** Url for HangFire controller (including api prefix) - default: '/api/stripe' */
  public get apiControllerFull(): string { return `${this.apiUrlPrefix}/${this._apiController}` }


  private _publishableKey?: string
  /** Url for any controller - default: undefined*/
  public get publishableKey(): string { return StringHelpers.Clone(this._publishableKey) }

  private _getClientSecretAction = 'getClientSecret'
  /** Url for any controller - default: '/getClientSecret' */
  public get getClientSecretAction(): string { return StringHelpers.Clone(this._getClientSecretAction) }

  private _getPaymentIntentAction = 'getPaymentIntent'
  /** Url for any controller - default: '/getClientSecret' */
  public get getPaymentIntentAction(): string { return StringHelpers.Clone(this._getPaymentIntentAction) }
  
  private _getPaymentIntentActionWithEmail = 'getPaymentIntentWithEmail'
  /** Url for any controller - default: '/getClientSecret' */
  public get getPaymentIntentActionWithEmail(): string { return StringHelpers.Clone(this._getPaymentIntentActionWithEmail) }
  
  private _getCheckoutSessionAction = 'getCheckoutSession'
  /** Url for any controller - default: '/getClientSecret' */
  public get getCheckoutSessionAction(): string { return StringHelpers.Clone(this._getCheckoutSessionAction) }


  
  private _paymentElementParam = 'session_id'
  /** Url for any controller - default: '/getClientSecret' */
  public get paymentElementParam(): string { return StringHelpers.Clone(this._paymentElementParam) }


  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * @param stripeController Name of the Stripe controller on the server. Default = 'Stripe'
   */
  constructor(publishableKey: string, stripeController?: string | null) {
    this._publishableKey = publishableKey
    if (stripeController) this._apiController = stripeController

  } //ctor

  //---------------------------------------------------------------------//

  /**
 * Set paramater to null to use default values.
 * @param stripeController Name of the Stripe controller on the server. Default = 'Stripe'
 */
  static Create(publishableKey: string, stripeController?: string | null): StripeConfig {

    const config = new StripeConfig(publishableKey, stripeController)
    return config

  }//Create

  //---------------------------------------------------------------------//

  /**
   * Set the place where the controllers live. Default = 'api'
   */
  setApiUrlPrefix(apiUrlPfx: string): StripeConfig {

    this._apiUrlPrefix = apiUrlPfx
    return this

  }//setApiUrlPrefix

  //---------------------------------------------------------------------//

  /**
   * Query param in the succesful Payment Element Session (Contains the Payment Intent Id)
   */
  setPaymentElementParam(paymentElementParam: string): StripeConfig {

    this._paymentElementParam = paymentElementParam
    return this

  }//setPublishableKey

  //---------------------------------------------------------------------//

  /**
   * Stripe Auth KEy
   */
  setPublishableKey(publishableKey: string): StripeConfig {

    this._publishableKey = publishableKey
    return this

  }//setPublishableKey

  //---------------------------------------------------------------------//

  /**
   * Action for Creating a Checkout Session and returning it's client secret
   */
  setGetCheckoutSessionAction(getCheckoutSessionAction: string): StripeConfig {

    this._getCheckoutSessionAction = getCheckoutSessionAction
    return this

  }//setPublishableKey

  //---------------------------------------------------------------------//


} //Cls

<div
  class="dot-container"
  [class]="[_theme]"
  [class.custom-colors]="_colors.length && _colors[0]"
>
  <div id="dot-spinner" [style.width.px]="_width" [style.height.px]="_width">
    <div
      *ngFor="let color of _colors; index as i; odd as odd"
      class="dot"
      [class]="'dot' + (i + 1)"
      [style.transform]="'rotate(' + ((i + 1) * 360) / 8 + 'deg)'"
    >
      <div
        class="bfr"
        [class.dot-shadow]="_useShadow"
        [class.even]="!odd"
        [style.--clrDot]="_colors[i]"
        [style.animation-delay]="(i * _animationDuration) / 8 + 's'"
        [style.animation-duration]="_animationDuration + 's'"
        [style.width.px]="_width / 2.5"
        [style.height.px]="_width / 2.5"
      ></div>
    </div>

    <div class="loading" *ngIf="_message">
      <div class="spinner-message">
        {{ _message }}
      </div>
    </div>
  </div>
</div>

import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { DisplayColumn } from './models/display-column';
import { ColumnFilter } from './column-filter';
import { Observable, combineLatest, of } from 'rxjs';
import { FilterRequest } from './models/filter-request';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ColumnFilterService {

  private _platformId = inject(PLATFORM_ID)

  //------------------------------------------------//

  generateFilterObservableArray(displayColumns: Array<DisplayColumn>, uniqueSuffix = ''): Observable<FilterRequest[]> {

    if (isPlatformServer(this._platformId))
      return of([])

    const columnFilters = this.generateColumnFilterArray(displayColumns, uniqueSuffix)
    return this.toFilterObservableArray(columnFilters)

  } //generateFilterObservableArray

  //------------------------------------------------//

  generateColumnFilterArray(displayColumns: Array<DisplayColumn>, uniqueSuffix = ''): Array<ColumnFilter> {

    if (isPlatformServer(this._platformId))
      return []

    const columnFilters: Array<ColumnFilter> = []

    displayColumns.forEach(dc => {
      columnFilters.push(
        new ColumnFilter(dc.name, dc.filterDataType, dc.useList, uniqueSuffix)
        .setTriggerMinTextLength(dc.triggerMinTxtLength)
      )
    }) //foreach

    return columnFilters

  } //generateColumnFilterArray

  //------------------------------------------------//

  toFilterObservableArray(columnFilters: Array<ColumnFilter>): Observable<FilterRequest[]> {

    if (isPlatformServer(this._platformId))
      return of([])

    const filterInputs$: Array<Observable<FilterRequest>> = []

    columnFilters.forEach((cf) => filterInputs$.push(cf.asObservable()))

    //Combine them
    return combineLatest([...filterInputs$])

  } //toFilterObservableArray

  //------------------------------------------------//

}//Cls

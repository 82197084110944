import {
  Component,
  OnInit,
  Inject,
  Input,
  Optional,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  DotSpinnerConfigService,
  DotSpinnerConfig,
} from './dot-spinner-config';

@Component({
  selector: 'inigo-loaders-dot-spinner',
  templateUrl: './dot-spinner.component.html',
  styleUrls: ['./dot-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotSpinnerComponent {
  /** Color theme. ('primary' | 'accent' | 'warn'| 'none' ) -  Default = 'none' */
  @Input('theme') _theme: 'primary' | 'accent' | 'warn' | 'mixed' | 'none' =
    'none';

  @Input('colors') _colors: Array<string> = new Array(8);
  @Input('animationDuration') _animationDuration: number = 1.2;
  @Input('message') _message: string | undefined; //= "Loading"
  @Input('useShadow') _useShadow = true;
  @Input('widthPx') _width = 200;

  private _color1: string = '#FF355E';
  private _color2: string = '#FF6037';
  private _color3: string = '#FF9933';
  private _color4: string = '#FFFF66';
  private _color5: string = '#CCFF00';
  private _color6: string = '#66FF66';
  private _color7: string = '#50BFE6';
  private _color8: string = '#EE34D2';

  //---------------------------------------------------------------------------------//

  constructor(
    @Optional() @Inject(DotSpinnerConfigService) config: DotSpinnerConfig
  ) {
    //If @Inputs are used all this may be overridden
    if (config?.theme === 'none') {
      this._colors[0] = config.color1 ?? this._color1;
      this._colors[1] = config.color2 ?? this._color2;
      this._colors[2] = config.color3 ?? this._color3;
      this._colors[3] = config.color4 ?? this._color4;
      this._colors[4] = config.color5 ?? this._color5;
      this._colors[5] = config.color6 ?? this._color6;
      this._colors[6] = config.color7 ?? this._color7;
      this._colors[7] = config.color8 ?? this._color8;
    }

    if (config) {
      this._theme = config.theme;
      this._animationDuration = config.animationDuration;
      this._message = config.message;
    } //if
  } //ctor

  //---------------------------------------------------------------------------------//
} //Cls

//==========================================================//

export class IndustryDisplayConfig {

  
  public textPosition: string = 'Right'


  constructor(
    public productRoute:string = 'all',
    public title: string = 'Construction',
    textPosition: string = 'Right',
    public productIcon: string = 'products',
    public imgSrcMain: string = 'assets/images/industry/construction.jpg',
    public imgSrcMainPlc: string = 'assets/images/industry/placeholder/construction.jpg',
    public imgSrc: string = 'assets/images/industry/home-page/construction.jpg',
    public imgSrcPlc: string = 'assets/images/industry/home-page/placeholder/construction.jpg',
    public imgSrcMobile: string = 'assets/images/industry/home-page/mobile/construction.jpg',
    public imgSrcMobilePlc: string = 'assets/images/industry/home-page/mobile/placeholder/construction.jpg',
    public brands: string[]
    // public blurb: string = `Pommy ipsum squiffy one would like muck about what a doddle, bugger scones it's spitting Geordie. Stiff upper lip red telephone box baffled scrote cheerio up North Sherlock macca, conked him one on the nose completely crackers chaps flabbergasted the chippy. `
  ) {

    this.textPosition = textPosition?.toLowerCase()
   }

} //Cls


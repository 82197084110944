import { Routes } from '@angular/router';
import { DelayPreloader } from '@inigo/lazy-load-helpers/preload';

export class AppRoutes {

    public static readonly ID_PARAM = '/:id'
    public static readonly NOT_FOUND = 'notfound'
    public static readonly PUBLIC_AREA = '' //'pa'
    public static readonly MNTC_AREA = 'admin'
    public static readonly HOME = 'home'

    //---------------------------------------------------------//

    static PublicArea = class {

        public static readonly BASE = AppRoutes.PUBLIC_AREA

        public static readonly ABOUT = 'about'
        public static readonly BRAND = 'brand'
        public static readonly CONTACT = 'contact'
        public static readonly CHECKOUT = 'checkout'
        public static readonly CUSTOMERS = 'customers'
        public static readonly HOME = AppRoutes.HOME //'home'
        public static readonly INDUSTRIES = 'industries'
        public static readonly NOT_FOUND = 'notfound'
        public static readonly DETAIL = 'detail'
        public static readonly MY_ORDERS_CUSTOMER_PARAM = 'customerId'
        public static readonly MY_ORDERS = 'my-orders'
        public static readonly MY_ACCOUNT = 'my-account'
        public static readonly MY_ORDERS_CUSTOMER_ID = `${this.MY_ORDERS}/:${this.MY_ORDERS_CUSTOMER_PARAM}`
        public static readonly MY_INFO_CUSTOMER = `${this.MY_ACCOUNT}/:${this.MY_ORDERS_CUSTOMER_PARAM}`
        public static readonly PAYMENT_RESULT_PARAM = 'orderId'
        public static readonly PAYMENT_SESSION_PARAM = 'session_id'
        public static readonly PAYMENT_COMPLETE = `payment-complete`
        public static readonly PAYMENT_ERROR = `payment-error`
        public static readonly PAYMENT_SUCCESS = `payment-success`
        public static readonly PAYMENT_ERROR_ORDER_ID = `${this.PAYMENT_ERROR}/:${this.PAYMENT_RESULT_PARAM}`
        public static readonly PAYMENT_COMPLETE_ORDER_ID = `${this.PAYMENT_COMPLETE}/:${this.PAYMENT_RESULT_PARAM}`
        public static readonly PRODUCTS = 'products'
        public static readonly PRODUCT = 'product'
        public static readonly PRODUCTS_PARAM = 'dept'
        public static readonly PRODUCT_DETAIL_PARAM = 'prodId'
        public static readonly PRODUCT_VARIANTS = 'vars'
        public static readonly PRODUCTS_DEPT = `${this.PRODUCTS}/:${this.PRODUCTS_PARAM}`
        public static readonly PRODUCTS_ALL = `${this.PRODUCTS}/all`
        public static readonly PRODUCTS_DETAIL = `${this.PRODUCT}/:${this.PRODUCT_DETAIL_PARAM}`
        public static readonly ORDER_SUMMARY = 'order'
        public static readonly USERS = 'users'
        public static readonly REGISTER = 'register'
        public static readonly UPLOAD = 'upload'


    }//Cls


    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

    static PublicAreaFullPath = class {

        public static readonly ABOUT = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.ABOUT}`
        public static readonly BRAND = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.BRAND}`
        public static readonly CHECKOUT = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.CHECKOUT}`
        public static readonly CONTACT = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.CONTACT}`
        public static readonly CUSTOMERS = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.CUSTOMERS}`
        public static readonly HOME = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.HOME}`
        public static readonly INDUSTRIES = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.INDUSTRIES}`
        public static readonly NOT_FOUND = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.NOT_FOUND}`
        public static readonly PRODUCTS = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.PRODUCTS}`
        public static readonly DETAIL = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.DETAIL}`
        public static readonly MY_INFO_CUSTOMER = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.MY_INFO_CUSTOMER}`
        public static readonly MY_ORDERS = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.MY_ORDERS}`
        public static readonly PRODUCT = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.PRODUCT}`
        public static readonly PAYMENT_ERROR = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.PAYMENT_ERROR_ORDER_ID}`
        public static readonly PAYMENT_SUCCESS = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.PAYMENT_COMPLETE_ORDER_ID}`
        public static readonly PRODUCTS_PARAM = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.PRODUCTS_PARAM}`
        public static readonly PRODUCT_DETAIL_PARAM = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.PRODUCT_DETAIL_PARAM}`
        public static readonly PRODUCT_VARIANTS = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.PRODUCT_VARIANTS}`
        public static readonly PRODUCTS_DEPT = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.PRODUCTS_DEPT}`
        public static readonly PRODUCTS_ALL = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.PRODUCTS_ALL}`
        public static readonly PRODUCTS_DETAIL = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.PRODUCTS_DETAIL}`
        public static readonly USERS = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.USERS}`
        public static readonly UPLOAD = `${AppRoutes.PUBLIC_AREA}/${AppRoutes.PublicArea.UPLOAD}`


    }//Cls


    //---------------------------------------------------------//


    static MntcArea = class {

        public static readonly BASE = AppRoutes.MNTC_AREA //'admin'
        public static readonly BRANDS = 'brands'
        public static readonly HOME = AppRoutes.HOME
        public static readonly UPLOAD = 'upload'
        public static readonly DOWNLOADS = 'downloads'
        public static readonly TEST = 'testse'
        public static readonly INDUSTRIES = 'industries'
        public static readonly CUSTOMERS = 'customers'
        public static readonly PRODUCTS = 'products'
        public static readonly NOT_FOUND = 'notfound'
        public static readonly PAYMENTS = 'payments'
        public static readonly DETAIL = 'detail'
        public static readonly PRODUCT_DETAIL_PARAM = 'prodId'
        public static readonly PRODUCTS_DETAIL = `${this.DETAIL}/:${this.PRODUCT_DETAIL_PARAM}`
        public static readonly PRODUCT_VARIANTS = 'prod-vars'
        public static readonly PRODUCT_GALLERY = 'gallery'
        public static readonly COLORS = 'prod-colors'
        public static readonly ORDERS = 'orders'
        public static readonly DIMENS = 'prod-dimens'
        public static readonly STYLES = 'prod-styles'
        public static readonly UNITS = 'prod-units'
        public static readonly MEASUREMENTS = 'prod-msrs'

    }//Cls


    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - -//


    static MntcAreaFullPath = class {

        public static readonly HOME = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.HOME}`
        public static readonly BRANDS = `${AppRoutes.MNTC_AREA}/${AppRoutes.PublicArea.BRAND}`
        public static readonly UPLOAD = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.UPLOAD}`
        public static readonly DOWNLOADS = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.DOWNLOADS}`
        public static readonly TEST = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.TEST}`
        public static readonly INDUSTRIES = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.INDUSTRIES}`
        public static readonly CUSTOMERS = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.CUSTOMERS}`
        public static readonly PRODUCTS = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.PRODUCTS}`
        public static readonly NOT_FOUND = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.NOT_FOUND}`
        public static readonly PAYMENTS = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.PAYMENTS}`
        public static readonly DETAIL = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.DETAIL}`
        public static readonly PRODUCT_DETAIL_PARAM = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.PRODUCT_DETAIL_PARAM}`
        public static readonly PRODUCTS_DETAIL = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.PRODUCTS_DETAIL}`
        public static readonly PRODUCT_VARIANTS = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.PRODUCT_VARIANTS}`
        public static readonly PRODUCT_GALLERY = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.PRODUCT_GALLERY}`
        public static readonly ORDERS = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.ORDERS}`
        public static readonly COLORS = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.COLORS}`
        public static readonly DIMENS = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.DIMENS}`
        public static readonly STYLES = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.STYLES}`
        public static readonly UNITS = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.UNITS}`
        public static readonly MEASUREMENTS = `${AppRoutes.MNTC_AREA}/${AppRoutes.MntcArea.MEASUREMENTS}`

    }//Cls
 
    //---------------------------------------------------------//


}//Cls


//###########################################################################################################//

export const APP_ROUTES: Routes = [
    //Admin goes first because  if AppRoutes.PUBLIC_AREA is '' then MntcAreaRoutes.BASE will send us to NOtFound in Pulic Area
    {
        path: AppRoutes.MntcArea.BASE,
        loadChildren: () => import('./admin/admin-routes').then((m) => m.MNTC_ROUTES),
        data: DelayPreloader(300000),
        // canActivate: [MaintenanceMgrMinimumGuard],

    },
    {
        path: AppRoutes.PUBLIC_AREA,
        loadChildren: () => import('./public/public-routes').then((m) => m.PUBLIC_ROUTES),
        data: DelayPreloader(1),
    },
    {
        path: '',
        redirectTo: `${AppRoutes.PUBLIC_AREA}`,
        pathMatch: 'full',
    },
    {
      path: '**',
      redirectTo: AppRoutes.PublicArea.NOT_FOUND,
      pathMatch: 'full',
    },
];
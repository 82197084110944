import { ActivatedRouteSnapshot, DetachedRouteHandle, BaseRouteReuseStrategy, Route, } from '@angular/router';
/**
 * This will reuse a route if it has "reuseComponent: true" in the "data" section of the route config.
 * 
 * Example:
 *  
        path: ROUTE_HOME,   
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),   
        data: {   
            reuseComponent: true   
        }
   
 */
export class DataRouteReuseStrategy extends BaseRouteReuseStrategy {
  //--------------------------------------------------------//

  // storedRouteHandles = new Map<string, DetachedRouteHandle>();
  storedRouteHandles = new Map<Route, DetachedRouteHandle>();

  // Decides if the route should be stored  --> store
  override shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data['reuseComponent'];
  }

  //--------------------------------------------------------//

  //Store the information for the route we're destructing
  override store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle
  ): void {
    // console.log('store', route.routeConfig, route)

    //!route.data['reuseComponent'] ||
    if (!route.routeConfig) return;
    this.storedRouteHandles.set(route.routeConfig, handle);
  }

  //--------------------------------------------------------//

  //Return true if we have a stored route object for the next route  --> retrieve
  override shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return (
      !!route.routeConfig && !!this.storedRouteHandles.get(route.routeConfig)
    );
  }

  //--------------------------------------------------------//

  //If we returned true in shouldAttach(), now return the actual route data for restoration
  override retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig || !this.storedRouteHandles.has(route.routeConfig))
      return null;
    return this.storedRouteHandles.get(route.routeConfig)!; //Checkecd above
  }

  //--------------------------------------------------------//

  //Reuse the route if we're going to and from the same route
  override shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  //--------------------------------------------------------//
} //Cls

import { Provider, EnvironmentProviders } from "@angular/core";
import { TextHelpersConfig, TextHelpersConfigService } from "./text-helpers-config";

export class TextSetup {


  static  getProviders(config: TextHelpersConfig = new TextHelpersConfig()): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: TextHelpersConfigService,
                useValue: config
            }
        ]

    }//getProviders

}//Cls
export class AppError {

  constructor(
    public originalError?: any,
    public statusCode?: number,
    public title?: string,
    public message?: string

  ) { } //ctor

  //-------------------------------------------------------------------------------//

  public equals(otherError: AppError): boolean {

    if (this.title === otherError?.title && this.message === otherError?.message)
      return true
    else
      return false

  } //equals

  //-------------------------------------------------------------------------------//
} //Cls

import { Injectable } from '@angular/core';
import { AppControllers } from '../../../../app-controllers';
import { AProductHttpService } from './a-prod.service';

@Injectable({
  providedIn: 'root',
})
export class ProductCustomerService extends AProductHttpService {

  //---------------------------------------------------------------------//

  constructor() {
    super();
    this.url = AppControllers.FullPath.PRODUCTS
  } //ctor

} //service

import { Injectable } from '@angular/core';
import { Identifier } from '@inigo/data/misc';
import { MyBSArray } from '@inigo/gen-helpers/classes';
import { APageStoreService, DefaultTemplateStoreService, SuccessInfo } from '@inigo/my-store';
import { Observable, ReplaySubject, Subscription, finalize } from 'rxjs';
import { BulkUploadDto } from '../../../dtos/bulk-upload';
import { VariantColor } from '../../../dtos/color';
import { ColorHttpService } from '../../http/color/color.service';

@Injectable({
  providedIn: 'root',
})
export class ColorStoreService extends APageStoreService<VariantColor, VariantColor>{


  protected _presetsBs: MyBSArray<VariantColor> = new MyBSArray<VariantColor>()
  presets$: Observable<VariantColor[]> = this._presetsBs.obs$
  protected _presetsSub?: Subscription

  private _httpService: ColorHttpService
  private _templateStore: DefaultTemplateStoreService
  
  protected _templateBs = new ReplaySubject<Blob>(1)
  /**Associated File (example: Excel) */
  template$: Observable<Blob> = this._templateBs.asObservable()

  //-------------------------------------------------------//

  constructor(httpService: ColorHttpService) {
    super(httpService);

    this._httpService = httpService
    this._templateStore = DefaultTemplateStoreService.Create(
      httpService.url,
      this._templateBs,
      this._isLoadingBs,
      this._errorSb,
      this._successSb
    )

    this.setItemTypeName('Color')
    this.setItemNameLamda(p => p.name ?? `${p.id}`)


  } //ctor

  //-------------------------------------------------------//

  refreshPresets(): void {

    this._isLoadingBs.next(true)

    this._presetsSub?.unsubscribe()
    this._presetsSub = (this.httpService as ColorHttpService)
      .getAllPresets()
      .pipe(finalize(() => this._isLoadingBs.next(false)))
      .subscribe({
        next: (data: VariantColor[]) => {
          this._presetsBs.next(data)
        },
        error: (error) => this._errorSb.next(error),
      }) //subscribe

  } //filter

  //-------------------------------------------------------//


  addBulk(dto: BulkUploadDto, filename: string = 'upload_report.txt') {

    this._httpService.addBulk(dto).subscribe({
      next: (response) => {
        this._blobHandler.download(response, filename);
        this._successSb.next(
          SuccessInfo.Other(
            `Upload complete. Extra info wil be in downloaded file, "${filename}"`
          )
        );
      },
      error: (error) => this._errorSb.next(error),
    });
  } //addBulk

  //-------------------------------------------------------//

  getTemplate = (fileName: string = 'template.tmp', id?: Identifier): Observable<Blob> =>
    this._templateStore.getTemplate(fileName, id)

  //-------------------------------------------------------//

} //Cls

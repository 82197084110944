import { Observable } from 'rxjs';
import { BsArrayHelper } from './bs-array-helper';
import { MyBS } from './my-bs';

/**
 * Class for creating observables arrays.
 */
export class MyBSArray<T>  {


    private _helper: BsArrayHelper<T>
    private _source: MyBS<T[]>
    obs$: Observable<T[]>

    //----------------------------------------------------------------//
    /**
     * @param startVal first value to output (defaults to null)
     */
    constructor(startVal?: T[]) {

        this._source = new MyBS<T[]>(startVal)

        this.obs$ = this._source.obs$

        this._helper = new BsArrayHelper<T>(this._source)

    }//ctor

    //----------------------------------------------------------------//

    /**Pass in a new value */
    next = (nextVal: T[]): void => this._source.next(nextVal)

    //----------------------------------------------------------------//


    /**Pass in a new value */
    push = (t: T): void => this._helper.push(t)

    //----------------------------------------------------------------//

    /**
     * Replace the value at index, idx
     * @param t  New Item
     * @param idx LOcation of item to be replaced 
     */
    replace = (t: T, idx: number): void => this._helper.replace(t, idx)

    //----------------------------------------------------------------//

    /**
     * Remove the value at index, idx
     * @param idx Location of item to be replaced 
     */
    remove = (idx: number): void => this._helper.remove(idx)

    //----------------------------------------------------------------//

    /**
     * Current value
     */
     getValue = (): T[]|undefined => this._source.getValue()

    //----------------------------------------------------------------//

}//Cls
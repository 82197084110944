import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { PopUpConfig, PopUpConfigService, } from '../../../setup/pop-up-config';
import { BaseInfoComponent } from '../base-info/base-info.component';

@Component({
  selector: 'inigo-success',
  templateUrl: './../base-info/base-info.component.html',
  styleUrls: ['./../base-info/base-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessComponent extends BaseInfoComponent {
  
  //--------------------------------------------------------//

  constructor(@Inject(PopUpConfigService) puConfig: PopUpConfig) {
    super();

    if (puConfig.colorSuccessHeader)
      this._colorHeader = puConfig.colorSuccessHeader;

    if (puConfig.colorSuccessHeaderText)
      this._colorHeaderText = puConfig.colorSuccessHeaderText;

    this._colorButtonTxt = this._colorHeader;
    
  } //ctor

  //--------------------------------------------------------//
} //Cls

import { Type } from '@angular/core';
import { DialogConfig } from '../../utility/dialog-config';
import { DialogRef } from '../../utility/dialog-ref';
import { BaseDialogService } from '../base/base-dialog.service';

export class BaseSpinner extends BaseDialogService{
  
  /** How many spinners are open right now */
  private _count = 0;
  private _dialogRef: DialogRef | undefined;

  //------------------------------------------------------//

  protected openSpinner(componentType: Type<any>): DialogRef | undefined {
    if (this._count > 0) return undefined;

    const config = new DialogConfig('auto', {}, true);

    this._dialogRef = this.openBase(componentType, config);
    this._count++;

    const sub = this._dialogRef.afterClosed.subscribe(() => {
      this._count--;
      sub.unsubscribe();
    });

    return this._dialogRef;
  } //open

  //------------------------------------------------------//

  close() {
    //Make sure we have one to close
    if (!this._dialogRef) return;

    this._dialogRef.close();

    this._count--;
  } //close

  //------------------------------------------------------//
} //Cls

import { EnvironmentProviders, Provider } from "@angular/core"
import { StripeConfig, StripeConfigService } from "./config"

export class StripeHelpersSetup {

    //--------------------------------------------------------------//

    static getProviders(config: StripeConfig): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: StripeConfigService,
                useValue: config,
            }
        ]

    } //getProviders

    //--------------------------------------------------------------//

    static getProvidersBasic(publishableKey: string): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: StripeConfigService,
                useValue: StripeConfig.Create(publishableKey),
            }
        ]

    } //getProvidersBasic

    //--------------------------------------------------------------//

} //Cls
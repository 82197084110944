import { Identifier } from "@inigo/data/misc"

export class JobCreationInfo {

    //-----------------------------------------------//

    constructor(
        public jobId: Identifier,
        public action?: string,
        public controller: string = 'hangfireJobs'
    ) { }

    //-----------------------------------------------//

    static Create(jobId: Identifier, action?: string, controller: string = 'hangfireJobs'): JobCreationInfo {

        const info = new JobCreationInfo(jobId, action, controller)
        return info

    }//Create

    //-----------------------------------------------//

    setAction(action: string): JobCreationInfo {

        this.action = action
        return this

    }//setAction

    //- - - - - - - - - - - - - - - - - - - - - - - -//

    setController(controller: string): JobCreationInfo {

        this.controller = controller
        return this

    }//setAction

    //- - - - - - - - - - - - - - - - - - - - - - - -//

    setJobId(jobId: Identifier): JobCreationInfo {

        this.jobId = jobId
        return this

    }//setJobId

    //-----------------------------------------------//

}//Cls

//============================================================//

export const DB_BACKUP_CREATION_INFO =
    JobCreationInfo.Create('job-id-db-backup-inigo-3215', 'createJob', 'DbBackup')
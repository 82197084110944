import { Injectable } from '@angular/core';
import { APageHttpService } from '@inigo/my-store';
import { Observable } from 'rxjs';
import { AppActions } from '../../../../app-actions';
import { AppControllers } from '../../../../app-controllers';
import { Department } from '../../../dtos/dept';
import { DepartmentUpload } from '../../../dtos/dept-upload';

@Injectable({
  providedIn: 'root',
})
export class DeptService extends APageHttpService<Department, DepartmentUpload> {

  //---------------------------------------------------------------------//

  constructor() {
    super();
    this.url = AppControllers.FullPath.DEPARTMENTS
  } //ctor

  //---------------------------------------------------------------------//

  getAllPresets = (opts?: any): Observable<Department[]> =>
    this.getAllAction(AppActions.Departments.GET_ALL_PRESETS, opts ?? {})

  //---------------------------------------------------------------------//
} //Cls

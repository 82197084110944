/**Info on how to sort a query */
export class SortRequest {
  /**
   * @param field What field/column to sort by
   * @param sortDescending Whether to sort by descending or not. Defaults to false.
   */
  constructor(public field: string, public sortDescending: boolean) {}
} //Cls

export const DESC_MATERIAL_DESIGN = 'desc';

import { Component, Inject } from '@angular/core';
import { PopUpConfig, PopUpConfigService } from '../../../setup/pop-up-config';
import { BaseInfoComponent } from '../base-info/base-info.component';

@Component({
  selector: 'inigo-error',
  templateUrl: './../base-info/base-info.component.html',
  styleUrls: ['./../base-info/base-info.component.scss'],
})
export class ErrorComponent extends BaseInfoComponent {
  //--------------------------------------------------------//

  constructor(@Inject(PopUpConfigService) puConfig: PopUpConfig) {
    super()

    if (puConfig.colorErrorHeader)
      this._colorHeader = puConfig.colorErrorHeader;

    if (puConfig.colorErrorHeaderText)
      this._colorHeaderText = puConfig.colorErrorHeaderText;

    this._colorButtonTxt = this._colorHeader;
    this._showCopyButton = true;
  } //ctor

  //--------------------------------------------------------//
} //Cls

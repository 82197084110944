import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TrouncerConfig, TrouncerConfigService } from './trouncer-config';

@Component({
  selector: 'inigo-loaders-trouncer',
  templateUrl: './trouncer.component.html',
  styleUrls: ['./trouncer.component.scss'],
})
export class LoaderTrouncerComponent implements AfterViewInit, OnChanges {
  @Input('colors') _colors: Array<string | undefined> = [];
  @Input('images') _images: string[] = [];

  @Input('useImages') _useImages = false;
  @Input('imgPath1') _imgPath1 = '/assets/images/trouncer/trouncer1.png';
  @Input('imgPath2') _imgPath2 = '/assets/images/trouncer/trouncer2.png';
  @Input('imgPath3') _imgPath3 = '/assets/images/trouncer/trouncer3.png';
  @Input('bounce') _bounce = true;
  @Input('spin') _spin = true;
  @Input('show') _show = true;
  @Input('color1') _color1?: string; //= 'pink'
  @Input('color2') _color2?: string; //= 'blue'
  @Input('color3') _color3?: string; //= 'red'

  @Input('ignoreConfig') _ignoreConfig = false;
  @Input('useShadow') _useShadow = true;

  @Input('diameterPx') _diameterPx = 100;

  readonly colorTransparent = 'transparent';

  _bounceTimeUnit = 1;

  //----------------------------------------------//

  constructor(
    @Inject(TrouncerConfigService) private _config: TrouncerConfig,
    private _cd: ChangeDetectorRef
  ) {
    this.setConfig();
  } //ctor

  //----------------------------------------------//

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['_color1'] || changes['_color2'] || changes['_color3']) {
      const newColors = new Array<string | undefined>(3);

      if (changes['_color1']) newColors[0] = this._color1;

      if (changes['_color2']) newColors[1] = this._color2;

      if (changes['_color3']) newColors[2] = this._color3;

      this._colors = newColors;
    } //if

    if (changes['_imgPath1'] || changes['_imgPath2'] || changes['_imgPath3']) {
      const newImages = new Array<string>(3);

      if (changes['_imgPath1']) newImages[0] = this._imgPath1;

      if (changes['_imgPath2']) newImages[1] = this._imgPath2;

      if (changes['_imgPath3']) newImages[2] = this._imgPath3;

      this._images = newImages;
    } //if
  } //ngOnChanges

  //----------------------------------------------//

  ngAfterViewInit(): void {
    // this.refreshArray()

    //Prevent ExpressionChangedAfterCheckedExpression
    this._cd.detectChanges();
  } //ngAfterViewInit

  //----------------------------------------------//

  private setConfig() {
    this._bounce = this._config.bounce;

    this._useImages = this._config.useImages;

    this.setColors();

    this.setImages();

    this.setDimensions();

    this.setArrays();
  } //setConfig

  //----------------------------------------------//

  /** Set colors if they've been provided' */
  private setColors() {
    if (this._config.color1) this._color1 = this._config.color1;
    if (this._config.color2) this._color2 = this._config.color2;
    if (this._config.color3) this._color3 = this._config.color3;
  } //setColors

  //----------------------------------------------//

  /** Set images if they've been provided */
  private setImages() {
    if (this._config.imgPath1) this._imgPath1 = this._config.imgPath1;
    if (this._config.imgPath2) this._imgPath2 = this._config.imgPath2;
    if (this._config.imgPath3) this._imgPath3 = this._config.imgPath3;
  } //setImages

  //----------------------------------------------//

  /** Set Dimensions if they've been provided */
  private setDimensions() {
    if (this._config.diameterPx) this._diameterPx = this._config.diameterPx;
  } //setDimensions

  //----------------------------------------------//

  /** Put valueas into arrays for the DOM */
  private setArrays() {
    this._colors.push(this._color1);
    this._colors.push(this._color2);
    this._colors.push(this._color3);

    this._images.push(this._imgPath1);
    this._images.push(this._imgPath2);
    this._images.push(this._imgPath3);

    //Override arrays if we custom array was was provided
    if (this._config.colors?.length) this._colors = this._config.colors;

    if (this._config.images?.length) this._images = this._config.images;
  } //setArrayss

  //----------------------------------------------//

  //For some reason this prevents images from blurring ?????
  private refreshArray() {
    if (this._images?.length) {
      const originalImages = Object.assign([], this._images);
      this._images = [];

      setTimeout(() => {
        this._images = Object.assign([], originalImages);
        this._cd.detectChanges();
      }, 200);
    } else {
      const originalColors = Object.assign([], this._colors);
      this._colors = [];

      setTimeout(() => {
        this._colors = Object.assign([], originalColors);
        this._cd.detectChanges();
      }, 200);
    } //else
  } //refreshImage

  //----------------------------------------------//
} //Cls

import { Inject, Injectable, inject } from '@angular/core';
import { Params, Router } from '@angular/router';
import { AccRoutes } from '@inigo/acc-n-auth-data/routes';
import { UrlHelpers } from '@inigo/gen-helpers/url';
import { AuthConfig, AuthConfigService } from '@inigo/authentication/config';

/**
 * Class for Routing araound inside Auth Zones
 */
@Injectable({
  providedIn: 'root',
})
export class AuthRouter {

  private _router    = inject( Router)
  private _urlHelper = inject( UrlHelpers)
  private _config: AuthConfig = inject(AuthConfigService)

  //------------------------------------------------------//

  private get currentUrl(): string {
    return this._router.url;
  }

  //------------------------------------------------------//

  /**
   * Navigate to AuthBase/AccountBase/targetRoute from currentUrl. Stays inside the Accounts area which stays inside the Auth area
   * @param targetRelativePath The page we want to go to
   * @param queryParams Any query paramaters
   */
  navigateRelativeToAccountBase = (targetRelativePath: string, queryParams?: Params | null) =>
    this.navigateRelativeToAuthBase(`${AccRoutes.ACCOUNT_FEATURES}/${targetRelativePath}`, queryParams);

  //------------------------------------------------------//

  /**
   * Navigate to targetRoute from currentUrl
   * @param targetRelativePath The page we want to go to
   * @param queryParams Any query paramaters
   */
  navigateRelativeToAuthBase(targetRelativePath: string, queryParams?: Params | null) {

    let fullPath: string = this.getBaseRelativeUrl(targetRelativePath)


    if (queryParams)
      this._router.navigate([fullPath], { queryParams: queryParams });
    else
      this._router.navigate([fullPath])

  } //navigateRelativeToAuthBase

  //------------------------------------------------------//

  /**
   * Navigate to Login from currentUrl and set that to be the return Url
   * @param returnToPage Go back to the page from where login was requested. Default = true
   */
  navigateToLogin(returnToPage: boolean = true) {

    let loginPath: string = this.getLoginUrl()

    console.log(loginPath)
    

    if (returnToPage && !this.startsWithLoginPath())
      this._router.navigate([loginPath], { queryParams: { returnurl: this.currentUrl } })
    else this._router.navigate([loginPath])

  } //navigateToLogin

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - //

  /**
   * Navigate to MyInfo from currentUrl
   */
  navigateToMyInfo = () => this.navigateRelativeToAccountBase(AccRoutes.MY_INFO);

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - //

  /**
   * Navigate to ChangePassword from currentUrl
   */
  navigateToChangePwd = () => this.navigateRelativeToAccountBase(AccRoutes.CHANGE_PWD);

  //------------------------------------------------------//

  /**
   *Get an UrlTree for navigating to Login from currentUrl withthe returnUrl set to currentUrl
   */

  getLoginUrlTree(returnUrl?: string) {

    let fullPath: string = this.getLoginUrl(returnUrl)

    return this._router.createUrlTree([fullPath], {
      queryParams: { returnurl: returnUrl ?? this.currentUrl },
    })

  } //getLoginUrlTree

  //------------------------------------------------------//

  private getLoginUrl = (returnUrl?: string): string =>
    this.getBaseRelativeUrl(this._config.loginRelativePath, returnUrl);

  //------------------------------------------------------//

  /**
   * Creates url to this path that starts with the current Base Segment
   * @param relativePath Where we want to go in the current Area
   * @returns Full path in lowercase
   */
  private getBaseRelativeUrl(relativePath: string, returnUrl?: string): string {

    const baseRoutes = this._config.baseRoutes
    
    const firstSegment = this._urlHelper.getFirstSegment(returnUrl ?? this.currentUrl)
    let fullPath: string

    if (!firstSegment)
      fullPath = relativePath;
    else if (baseRoutes.has(firstSegment))
      fullPath = `${firstSegment}/${relativePath}`;
    else
      fullPath = relativePath;

    return fullPath.toLowerCase()

  } //getBaseRelativeUrl

  //------------------------------------------------------//

  private startsWithLoginPath = () => this.currentUrl.toLowerCase().startsWith(this.getLoginUrl())

  //------------------------------------------------------//

} //Cls

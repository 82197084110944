import { Injectable } from '@angular/core';
import { TrouncerComponent } from '../../../components/spinners/trouncer/trouncer.component';
import { DialogRef } from '../../../utility/dialog-ref';
import { BaseSpinner } from '../base-spinner';
import { ISpinner } from '../i-spinner';

@Injectable({
  providedIn: 'root',
})
export class TrouncerService extends BaseSpinner  implements ISpinner{


  open = (): DialogRef | undefined => this.openSpinner(TrouncerComponent);



} //Cls

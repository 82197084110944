import { Injectable } from '@angular/core';
import { Identifier } from '@inigo/data/misc';
import { APagePkgHttpService } from '@inigo/my-store';
import { PagedRequest, PagedResponse } from '@inigo/pagination';
import { Observable } from 'rxjs';
import { AppActions } from '../../../../app-actions';
import { AppControllers } from '../../../../app-controllers';
import { Product } from '../../../dtos/product';
import { ProductVariant } from '../../../dtos/variant';
import { VariantByProductPagePackageCustomer } from '../../../dtos/vars-by-prod-page-package-customer';

@Injectable({
  providedIn: 'root',
})
export class CustomerVariantHttpService extends APagePkgHttpService<ProductVariant, ProductVariant, VariantByProductPagePackageCustomer> {
  
  //---------------------------------------------------------------------//

  constructor() {
    super();
    this.url = AppControllers.FullPath.VARIANTS
  } //ctor

  //---------------------------------------------------------------------//

  override getAllItemsPagePkg = (pagedRequest: PagedRequest, prodId: Identifier, opts = {}): Observable<VariantByProductPagePackageCustomer> =>
    this.getPageActionById<VariantByProductPagePackageCustomer>(AppActions.Variants.PAGE_PACKAGE_BY_PRODUCT_CUSTOMER, prodId, pagedRequest, opts);

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  override getAllItemsPaged = (pagedRequest: PagedRequest, prodId: Identifier, opts = {}): Observable<PagedResponse<ProductVariant>> =>
    this.getPageActionById(AppActions.Variants.PAGE_BY_PRODUCT, prodId, pagedRequest, opts);

  //---------------------------------------------------------------------//

  getUnderlyingProduct(id: Identifier, opts?: any): Observable<Product> {
    return this.getActionName('getUnderlyingProduct', id, opts ?? {})
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//
} //service

import { InjectionToken } from '@angular/core';
import { DotSpinnerConfig } from '@inigo/my-loaders/dot-spinner';

export const DotSpinnerConfigService =
  new InjectionToken<PopUpDotSpinnerConfig>('DotSpinnerConfig');

export class PopUpDotSpinnerConfig extends DotSpinnerConfig {
  //---------------------------------------------------------------------//

  /**
   * @param color1 Color for first dot
   * @param color2 Color for second dot
   * @param color3 Color for third dot
   * @param color4 Color for fourth dot
   * @param color5 Color for fifth dot
   * @param color6 Color for sixth dot
   * @param color7 Color for seventh dot
   * @param color8 Color for eight dot
   * @param animationDuration How long will the animation take in seconds (1 rotation + fade in/out) - default 1.2
   * @param message Optional message at bottom of spinner - default to no message
   */
  private constructor(
    color1: string = '',
    color2: string = '',
    color3: string = '',
    color4: string = '',
    color5: string = '',
    color6: string = '',
    color7: string = '',
    color8: string = '',
    animationDuration = 1.2,
    message: string = ''
  ) {
    super(
      color1,
      color2,
      color3,
      color4,
      color5,
      color6,
      color7,
      color8,
      animationDuration,
      message
    );
  } //ctor

  //---------------------------------------------------------------------//

  /**
   * Create new instance of  PopUpDotSpinnerConfig with default settings
   */

  static override Create(
    color1: string = '',
    color2: string = '',
    color3: string = '',
    color4: string = '',
    color5: string = '',
    color6: string = '',
    color7: string = '',
    color8: string = ''
  ): PopUpDotSpinnerConfig {
    return new PopUpDotSpinnerConfig(
      color1,
      color2,
      color3,
      color4,
      color5,
      color6,
      color7,
      color8
    );
  } //create

  //---------------------------------------------------------------------//

  /**
   * @param color1 Color for first dot - default colorPrimary
   * @returns Updated  PopUpDotSpinnerConfig
   */
  override setColor1(color1: string): PopUpDotSpinnerConfig {
    super.setColor1(color1);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color2 Color for second dot - default colorSecondary
   * @returns Updated  PopUpDotSpinnerConfig
   */
  override setColor2(color2: string): PopUpDotSpinnerConfig {
    super.setColor2(color2);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color3 Color for third dot - default colorTertiarary
   * @returns Updated  PopUpDotSpinnerConfig
   */
  override setColor3(color3: string): PopUpDotSpinnerConfig {
    super.setColor3(color3);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color4 Color for fourth dot - default colorSecondary
   * @returns Updated  PopUpDotSpinnerConfig
   */
  override setColor4(color4: string): PopUpDotSpinnerConfig {
    super.setColor4(color4);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color5 Color for fifth dot - default colorPrimary
   * @returns Updated  PopUpDotSpinnerConfig
   */
  override setColor5(color5: string): PopUpDotSpinnerConfig {
    super.setColor5(color5);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color6 Color for sixth dot - default colorSecondary
   * @returns Updated  PopUpDotSpinnerConfig
   */
  override setColor6(color6: string): PopUpDotSpinnerConfig {
    super.setColor6(color6);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color7 Color for sevent dot - default colorPrimary
   * @returns Updated  PopUpDotSpinnerConfig
   */
  override setColor7(color7: string): PopUpDotSpinnerConfig {
    super.setColor7(color7);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color8 Color for eight dot - default colorSecondary
   * @returns Updated  PopUpDotSpinnerConfig
   */
  override setColor8(color8: string): PopUpDotSpinnerConfig {
    super.setColor8(color8);
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//
} //Cls

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderTrouncerComponent } from './trouncer.component';
import { TrouncerConfig, TrouncerConfigService } from './trouncer-config';

@NgModule({
  declarations: [LoaderTrouncerComponent],
  imports: [CommonModule],
  exports: [LoaderTrouncerComponent],
})
export class LoaderTrouncerModule {


  static configure(config: TrouncerConfig = TrouncerConfig.Create()): ModuleWithProviders<LoaderTrouncerModule> {
    return {
      ngModule: LoaderTrouncerModule,
      providers: [
        {
          provide: TrouncerConfigService,
          useValue: config,
        },
      ],
    }
  } //configure


}//Cls

import { DOCUMENT, isPlatformServer } from '@angular/common';
import { ErrorHandler, inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { StatusCodes } from '@inigo/gen-helpers/classes';
import { DateHelpers } from '@inigo/gen-helpers/date-time';
import { ErrorHelpers } from '@inigo/gen-helpers/errors';
import { BlobDownloadService } from '@inigo/gen-helpers/services';
import { RegExes, StringHelpers } from '@inigo/gen-helpers/text';
import { ErrorService } from '@inigo/http-services';
import { ToastService } from '@inigo/toast';
import { CoreConfig, CoreConfigService } from '../core-config';

declare const is: any;

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  //---------------------------------------------------------------------//

  // private _ngZone = inject(NgZone)
  private _toastService = inject(ToastService)
  private _errorService = inject(ErrorService)
  private _blobHandler = inject(BlobDownloadService)
  private _coreConfig: CoreConfig = inject(CoreConfigService)
  private _errorhelpers = inject(ErrorHelpers)
  private _doc = inject(DOCUMENT)
  private _platformId = inject(PLATFORM_ID)

  //---------------------------------------------------------------------//

  handleError(error: any): void {

    if (isDevMode()) {
      console.log('HandleError-DEV?', isDevMode(), error)
      console.trace()
    }

    if (isPlatformServer(this._platformId)) {
      console.log('SSR-Error?', error)
      return
    }


    //Chunk load errors are fixed by reloading
    if (this.isChunkLoadError(error)) {
      this.showToast('Something went wrong. Try refreshing!')
      this._doc.location.reload()
      return
    } //if

    // error.statusCode might be a string  ('404')
    // tslint:disable-next-line: triple-equals
    if (error.statusCode != StatusCodes.NOT_FOUND)
      this.showToast(error.message)

    //Post Error
    //Don't report unauthorized
    // error.statusCode might be a string ('401')
    // tslint:disable-next-line: triple-equals
    if (error.statusCode != StatusCodes.UNAUTHORIZED) {
      const errorInfoObject = this._errorhelpers.CreateErrorInfoObject(error);

      if (isDevMode())
        this.downloadTxtFile(errorInfoObject);

      if (this._coreConfig.skipEmailErrors) return;

      this.logError(errorInfoObject)

    } //If

  } //handleError

  //---------------------------------------------------------------------//

  private downloadTxtFile(errorInfoObject: any) {

    const errorString = JSON.stringify(errorInfoObject)
      .replace(new RegExp('\\\\n', 'g'), '\r\n')

    this._blobHandler.downloadText(errorString, `Errors_${DateHelpers.FormatYearMonthDay()}.txt`)

  } //downloadTxtFile

  //---------------------------------------------------------------------//

  private logError(errorInfoObject: any) {



    this._errorService.websiteError(errorInfoObject).subscribe({
      next: (v) => console.log('Error report sent.'),
      error: (err) => console.log('Error report sending failure.', err),
    })

  } //logError

  //---------------------------------------------------------------------//

  private isChunkLoadError(error: any): boolean {

    if (`${error.name}`?.toLowerCase().trim() === "chunkloaderror")
      return true

    return RegExes.ChunkFailedMessage.test(`${error.message}`)

  }//isChunkLoadError

  //---------------------------------------------------------------------//

  private showToast(msg: string) {

    console.log('showToast', msg)

    const safeMsg = StringHelpers.Truncate(msg, 60)

    this._toastService.showMsg(safeMsg)

  }//showToast

  //---------------------------------------------------------------------//

} //Cls

import { Injectable } from '@angular/core';
import { ToFormData } from '@inigo/gen-helpers/objects';
import { APagePkgHttpService } from '@inigo/my-store';
import { Observable } from 'rxjs';
import { AppActions } from '../../../../app-actions';
import { AppControllers } from '../../../../app-controllers';
import { Unit } from '../../../dtos/unit';
import { UnitPagePackage } from '../../../dtos/unit-page-package';

@Injectable({
  providedIn: 'root',
})
export class UnitHttpService extends APagePkgHttpService<Unit, Unit, UnitPagePackage> {

  //-----------------------------------------------------------//

  constructor() {
    super();
    this.url = AppControllers.FullPath.UNITS
  } //ctor

  //-----------------------------------------------------------//

  upload = (dept: Unit): Observable<any> =>
    this.postAction(AppActions.Units.UPLOAD, ToFormData(dept))

  //-----------------------------------------------------------//

  getAllPresets = (opts?: any): Observable<Unit[]> =>
    this.getAllAction(AppActions.Units.ALL_PRESETS, opts ?? {})

  //-----------------------------------------------------------//

} //service

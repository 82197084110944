import { PagedRequest } from './paged-request';

/** Encapsulates the Response from a page request */
export class PagedResponse<T> {

  /**The payload */
  public data: Array<T> = [];

  /**What page this is */
  public pageNumber = 0;

  /**How many items in this page */
  public pageSize = 0;

  /**How many pages are available */
  public totalPages = 0;

  /**How many itemss are available (This is necessary because last page might not be full)*/
  public totalItems = 0;

  /**Pre-prepared request for the next page (Query Param) */
  public nextPageParam?: string;

  /**Pre-prepared request for the previous page (Query Param)*/
  public previousPageParam?: string;

  /**Pre-prepared request for the next page (Post Body) */
  public nextPageBody?: PagedRequest;

  /**Pre-prepared request for the previous page (Post Body)*/
  public previousPageBody?: PagedRequest;

  //--------------------------------------------------------//

  public static Empty<T>(): PagedResponse<T> {
    const pr = new PagedResponse<T>();
    pr.data = [];
    return pr;
  } //Empty

  //--------------------------------------------------------//

  public static FromPagedRequest<T>(request: PagedRequest): PagedResponse<T> {
    const pr = new PagedResponse<T>()
    pr.pageNumber = request.pageNumber
    pr.pageSize = request.pageSize
    pr.data = []
    return pr;
  } //Empty


  //--------------------------------------------------------//


} //Cls

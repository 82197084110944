<div
  [hidden]="!isDelayedRunning"
  class="spinner"
  [style.width.px]="_widthPx"
  [style.height.px]="_heightPx"
>
  <div
    class="double-bounce1"
    [class.bnc-shadow]="_useShadow"
    [style.background-color]="_color1"
  ></div>
  <div
    class="double-bounce2"
    [class.bnc-shadow]="_useShadow"
    [style.background-color]="_color2"
  ></div>
</div>

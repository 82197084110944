import { InjectionToken } from '@angular/core';
import { StringHelpers } from '@inigo/gen-helpers/text';
import { PopUpBouncerConfig } from '../components/spinners/bouncer/bouncer-config';
import { PopUpDotSpinnerConfig } from '../components/spinners/dot-spinner/dot-spinner-config';
import { PopUpTrouncerConfig } from '../components/spinners/trouncer/trouncer-config';
import { openAnimation } from './animation-type';

export const PopUpConfigService = new InjectionToken<PopUpConfig>('PopUpConfig');

export class PopUpConfig {
  private _colorErrorHeader = '#ED4337';
  /** Color on the Erro Popup Header - Default '#ED4337' */
  public get colorErrorHeader(): string {
    return StringHelpers.Clone(this._colorErrorHeader);
  }

  private _colorErrorHeaderText = 'white';
  /** Color on the Error Popup Header Text - Default 'white' */
  public get colorErrorHeaderText(): string {
    return StringHelpers.Clone(this._colorErrorHeaderText);
  }

  private _colorSuccessHeader = '';
  /** Color on the Erro Popup Header - Default '#f1760f' */
  public get colorSuccessHeader(): string {
    return StringHelpers.Clone(this._colorSuccessHeader);
  }

  private _colorSuccessHeaderText = 'white';
  /** Color on the Success Popup Header Text - Default 'white' */
  public get colorSuccessHeaderText(): string {
    return StringHelpers.Clone(this._colorSuccessHeaderText);
  }

  private _openAnimation: openAnimation = 'none';
  /** Animation to use when opening the Modal*/
  public get openAnimation(): openAnimation {
    return this._openAnimation;
  }

  /** Config for Bouncer - default new PopUpBouncerConfig.Create() */
  bouncerConfig: PopUpBouncerConfig = PopUpBouncerConfig.Create();

  /** Config for Trouncer - default new PopUpTrouncerConfig.Create() */
  trouncerConfig: PopUpTrouncerConfig = PopUpTrouncerConfig.Create();

  /** Config for DotSpinner - default new DotSpinnerConfig() */
  dotSpinnerConfig: PopUpDotSpinnerConfig =
    PopUpDotSpinnerConfig.Create();

  //---------------------------------------------------------------------//

  /**
   * Enter no paramaters to use default values on everything
   * @param colorErrorHeader Color for Error Dialog Header - default #f44336
   * @param colorErrorHeaderText   Color for Error Dialog Header Text - default -white
   * @param colorSuccessHeader Color for Success Dialog Header - default #f1760f
   * @param colorSuccessHeaderText Color for Success Dialog Header Text - default -white
   * @param bouncerConfig Config for Bouncer - default PopUpBouncerConfig.Create()
   * @param trouncerConfig Config for Trouncer - default  PopUpTrouncerConfig.Create()
   * @param dotSpinnerConfig Config for DotSpinner - default DotSpinnerConfig.Create()
   */
  private constructor(
    colorErrorHeader: string | null = null,
    colorErrorHeaderText: string | null = null,
    colorSuccessHeader: string | null = null,
    colorSuccessHeaderText: string | null = null,
    bouncerConfig: PopUpBouncerConfig | null = null,
    trouncerConfig: PopUpTrouncerConfig | null = null,
    dotSpinnerConfig: PopUpDotSpinnerConfig | null = null
  ) {
    if (colorErrorHeader) this._colorErrorHeader = colorErrorHeader;

    if (colorErrorHeaderText) this._colorErrorHeaderText = colorErrorHeaderText;

    if (colorSuccessHeader) this._colorSuccessHeader = colorSuccessHeader;

    if (colorSuccessHeaderText)
      this._colorSuccessHeaderText = colorSuccessHeaderText;

    if (bouncerConfig) this.bouncerConfig = bouncerConfig;

    if (trouncerConfig) this.trouncerConfig = trouncerConfig;

    if (dotSpinnerConfig) this.dotSpinnerConfig = dotSpinnerConfig;
  } //ctor

  //---------------------------------------------------------------------//

  /**
   * Create new instance of PopupConfig
   */
  static Create(
    colorErrorHeader: string = '#ED4337',
    colorErrorHeaderText: string = 'white',
    colorSuccessHeader?: string,
    colorSuccessHeaderText?: string
  ): PopUpConfig {
    return new PopUpConfig(
      colorErrorHeader,
      colorErrorHeaderText,
      colorSuccessHeader,
      colorSuccessHeaderText
    );
  } //create
  //---------------------------------------------------------------------//

  /**
   * @param color Color for Error Dialog Header - default #f44336
   * @returns Updated PopUpConfig
   */
  setColorErrorHeader(color: string): PopUpConfig {
    this._colorErrorHeader = color;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color Color for Error Dialog Header Text - default - white
   * @returns Updated PopUpConfig
   */
  setColorErrorHeaderText(color: string): PopUpConfig {
    this._colorErrorHeaderText = color;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color Color for Success Dialog Header - default #f1760f
   * @returns Updated PopUpConfig
   */
  setColorSuccessHeader(color: string): PopUpConfig {
    this._colorSuccessHeader = color;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color Color for Success Dialog Header Text - default white
   * @returns Updated PopUpConfig
   */
  setColorSuccessHeaderText(color: string): PopUpConfig {
    this._colorSuccessHeaderText = color;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param config Config for Trouncer - default PopUpTrouncerConfig.Create()
   * @returns Updated PopUpConfig
   */
  setTrouncerConfig(config: PopUpTrouncerConfig): PopUpConfig {
    this.trouncerConfig = config;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param config Config for Bouncer - default PopUpBouncerConfig.Create()
   * @returns Updated PopUpConfig
   */
  setBouncerConfig(config: PopUpBouncerConfig): PopUpConfig {
    this.bouncerConfig = config;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param config Config for DotSpinner - default PopUpDotSpinnerConfig.Create()
   * @returns Updated PopUpConfig
   */
  setDotSpinnerConfig(config: PopUpDotSpinnerConfig): PopUpConfig {
    this.dotSpinnerConfig = config;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param animationType Animation to use when opening the Modal
   * @returns Updated PopUpConfig
   */
  setOpenAnimationType(animationType: openAnimation): PopUpConfig {
    this._openAnimation = animationType;
    return this;
  }

  //---------------------------------------------------------------------//
} //Cls

import { EnvironmentProviders, Provider } from "@angular/core"
import { HangfireConfig, HangfireConfigService } from "./hangfire-config"

export class HangfireHelpersSetup {

    static getProviders(config: HangfireConfig): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: HangfireConfigService,
                useValue: config,
            }
        ]

    } //getProviders

} //Cls
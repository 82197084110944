import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, HostBinding, HostListener, Inject, OnDestroy, Type, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { openAnimation } from '../../setup/animation-type';
import { PopUpConfig, PopUpConfigService } from '../../setup/pop-up-config';
import { DialogConfig } from '../../utility/dialog-config';
import { DialogRef } from '../../utility/dialog-ref';
import { InsertionDirective } from './insertion.directive';

const ANIMATION_PREFIX = 'animation-'
@Component({
  selector: 'inigo-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterViewInit, OnDestroy {


  private _popupConfig: PopUpConfig = inject(PopUpConfigService)
  protected _config = inject(DialogConfig)
  private _componentFactoryResolver = inject(ComponentFactoryResolver)
  private _cd = inject(ChangeDetectorRef)
  private _dialogRef = inject(DialogRef)

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  @ViewChild(InsertionDirective) insertionPoint?: InsertionDirective;

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private readonly _onClose = new Subject<any>()
  protected componentRef?: ComponentRef<any>
  childComponentType?: Type<any>;
  onClose = this._onClose.asObservable()

  _show = false;
  @HostBinding('class.is-spinner') _isSpinner = false;
  @HostBinding('class') _animationClass: string = `${ANIMATION_PREFIX}none`
  public set animationClass(oa: openAnimation) {
    this._animationClass = `${ANIMATION_PREFIX}${oa}`
  }

  _width = '';
  _maxWidth = 'unset';
  _maxHeight = 'unset';

  _zIndex = 999;
  _isTransparentDialog = false;

  //---------------------------------------------------------------------//

  constructor() {

    setTimeout(() => {
      this._show = true;
    }, 50)

    this._dialogRef.afterClosed
      .subscribe(() => this._show = false)

    if (this._config?.maxWidth) this._maxWidth = this._config.maxWidth;

    if (this._config?.width) this._width = this._config.width;

    if (this._config?.maxHeight) this._maxHeight = this._config.maxHeight;

    if (this._config?.isSpinner) this._isSpinner = this._config.isSpinner;

    if (this._config?.zIndex) this._zIndex = this._config.zIndex;

    if (this._config?.transparentBg)
      this._isTransparentDialog = this._config.transparentBg;

    if (this._popupConfig?.openAnimation)
      this.animationClass = this._popupConfig.openAnimation

  } //ctor

  //---------------------------------------------------------------------//

  ngAfterViewInit() {

    this.loadChildComponent(this.childComponentType)
    this._cd.detectChanges()

  } //ngAfterViewInit

  //---------------------------------------------------------------------//

  ngOnDestroy() {

    if (this.componentRef)
      this.componentRef.destroy()

  } //ngOnDestroy

  //---------------------------------------------------------------------//

  onOverlayClicked(evt: MouseEvent) {
    console.log('onOverlayClicked');


    if (this._isSpinner || !this._config.closeOnOverlayClick)
      return

    this._dialogRef.close()
    // close the dialog
    this._show = false

  } //onOverlayClicked

  //---------------------------------------------------------------------//

  onDialogClicked(evt: MouseEvent) {

    evt.stopPropagation()

  } //onDialogClicked

  //---------------------------------------------------------------------//

  loadChildComponent(componentType?: Type<any>) {

    if (!componentType) return;

    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.insertionPoint!.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory)

    this._cd.markForCheck()


  } //loadChildComponent

  //---------------------------------------------------------------------//

  close = () => this._onClose.next(true);

  //---------------------------------------------------------------------//

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {

    if (event.key === 'Escape') {
      console.log('Escape');

      this._onClose.next(true);
      event.stopImmediatePropagation();
    } //if

  } //onKeydownHandler

  //---------------------------------------------------------------------//
} //Cls

import { Pipe, PipeTransform } from '@angular/core';
import { FileHelpers } from '@inigo/gen-helpers/files';
import { StringHelpers } from '@inigo/gen-helpers/text';

@Pipe({
  name: 'filename',
  standalone: true,
})
export class FileNamePipe implements PipeTransform {

  //----------------------------------------------------//

  transform(value?: string, maxLength?: number): string {
    
    if (!value) return ''

    if (typeof value !== 'string') return `${value}`

    var nameAndExt = FileHelpers.GetFilenameFromPath(value)

    if (!maxLength) return nameAndExt ?? ''

    var name = FileHelpers.GetFilenameFromPath(nameAndExt, false)
    var ext = FileHelpers.GetFileExtensionFromPath(nameAndExt)

    return `${StringHelpers.Truncate(name, maxLength)}.${ext}`

  } //transform

  //----------------------------------------------------//

} //Cls

import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { MatEverythingModule } from '@inigo/material/everything';

@Component({
  selector: 'inigo-copy-button',
  standalone: true,
  imports: [CommonModule, MatEverythingModule,  ClipboardModule],
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyButtonComponent {

private _cd = inject(ChangeDetectorRef)

  /** What to copy */
  @Input('copyText') _copyText: string = '';

  /** Icon color */
  @Input('color') _color: string | undefined;

  @Input('tooltipBeforeCopyText') _tooltipBeforeCopyText = 'Copy';
  @Input('tooltipAfterCopyText') _tooltipAfterCopyText = 'Copied!';

  _tooltipText = this._tooltipBeforeCopyText;
  _showTooltip = false

  //-----------------------------------------------//

  confirmCopy() {

    this._tooltipText = this._tooltipAfterCopyText;
    this._showTooltip = true

    setTimeout(() => {
      this._tooltipText = this._tooltipBeforeCopyText;
      this._showTooltip = false
      console.log('copied', this._tooltipText );
      this._cd.detectChanges()
    }, 1000)
    
  } //confirmCopy

  //-----------------------------------------------//
} //Cls

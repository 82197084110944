import { Injectable } from '@angular/core';
import { Identifier } from '@inigo/data/misc';
import { PagedRequest, PagedResponse } from '@inigo/pagination';
import { Observable } from 'rxjs';
import { AppActions } from '../../../../app-actions';
import { AppControllers } from '../../../../app-controllers';
import { ProductPagePackage } from '../../../dtos/prod-page-package';
import { Product } from '../../../dtos/product';
import { AProductHttpService } from './a-prod.service';

@Injectable({
  providedIn: 'root',
})
export class ProductMntcService extends AProductHttpService {

  //---------------------------------------------------------------------//

  constructor() {
    super();
    this.url = AppControllers.FullPath.PRODUCTS
  } //ctor

  //---------------------------------------------------------------------//

  override getAllItemsPaged = (pagedRequest: PagedRequest, id?: Identifier, opts?: any): Observable<PagedResponse<Product>> =>
    this.postAction(AppActions.Products.GET_PAGE_MNTC, pagedRequest, opts ?? {})

  //---------------------------------------------------------------------//

  override getAllItemsPagePkg = (pagedRequest: PagedRequest, id?: Identifier, opts?: any): Observable<ProductPagePackage> =>
    this.postAction(AppActions.Products.GET_PAGE_PKG_MNTC, pagedRequest, opts);

  //---------------------------------------------------------------------//

  getMntc(id: Identifier, opts?: any): Observable<Product> {
    return this.getActionName('getMntc', id, opts ?? {})
  }

  //---------------------------------------------------------------------//

} //service

import { Injectable, inject } from '@angular/core';
import { JWT_AUTH_KEY } from '@inigo/authentication/config';
import { Jwt } from '@inigo/authentication/jwt';
import { AuthRouter } from './auth-router.service';
import { SsrLocalStorage } from '@inigo/ssr/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthMiniService {

  //------------------------------------------------------------------//

  private _localStorage = inject(SsrLocalStorage)
  private _router = inject(AuthRouter)

  //------------------------------------------------------------------//

  /**Get jwt */
  getJwt(): Jwt | null {
    
    const auth = this._localStorage.getItem(JWT_AUTH_KEY);

    if (auth)
      return JSON.parse(auth);
    else
      return null

  } //getAuth

  //------------------------------------------------------------------//

  /**Log out current user - delete jwt and reset all fields*/
  logOut(navigateToLogin: boolean = true): void {

    this._localStorage.removeItem(JWT_AUTH_KEY)
    if (navigateToLogin)
      this._router.navigateToLogin()

  } //logOut

  //----------------------------------------------------------------------//

}//Cls

<div
  *ngIf="_show"
  class="spinner"
  [style.width.px]="_diameterPx"
  [style.height.px]="_diameterPx"
>
  <ng-container *ngIf="_useImages">
    <ng-container *ngFor="let image of _images; index as i">
      <div
        *ngIf="_useShadow"
        class="trounce images"
        [class.bounce]="_bounce"
        [class.smoke-ring]="!_bounce"
        [style.animation-delay.s]="_bounce ? -i : -i * _bounceTimeUnit * 1"
        [style.animation-duration.s]="_colors.length * 1"
      >
        <div
          class="trounce shadow"
          [style.animation-delay.s]="_bounce ? -i : -i * _bounceTimeUnit * 1"
          [style.animation-duration.s]="_colors.length * 1"
        ></div>
      </div>

      <div
        class="trounce images"
        [class.bounce]="_bounce"
        [class.smoke-ring]="!_bounce"
        [style.animation-delay.s]="_bounce ? -i : -i * _bounceTimeUnit * 1"
        [style.animation-duration.s]="_colors.length * 1"
      >
        <div
          class="trounce inner"
          [class.spinning]="_spin"
          [style.background-image]="'url(' + image + ')'"
          [style.background-color]="colorTransparent"
        ></div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!_useImages">
    <ng-container *ngFor="let color of _colors; index as i">
      <div
        *ngIf="_useShadow"
        class="trounce colors"
        [class.bounce]="_bounce"
        [class.smoke-ring]="!_bounce"
        [style.animation-delay.s]="_bounce ? -i : -i * _bounceTimeUnit * 1"
        [style.animation-duration.s]="_colors.length * 1"
      >
        <div
          class="trounce shadow"
          [style.animation-delay.s]="_bounce ? -i : -i * _bounceTimeUnit * 1"
          [style.animation-duration.s]="_colors.length * 1"
        ></div>
      </div>

      <div
        class="trounce colors"
        [class]="'trounce' + (i + 1)"
        [class.bounce]="_bounce"
        [class.smoke-ring]="!_bounce"
        [style.animation-delay.s]="_bounce ? -i : -i * _bounceTimeUnit * 1"
        [style.animation-duration.s]="_colors.length * 1"
      >
        <div
          class="trounce inner"
          [class.spinning]="_spin"
          [style.background-image]="'none'"
          [style.background-color]="color"
        ></div>
      </div>
    </ng-container>
  </ng-container>
</div>

import { Identifier } from '@inigo/data/misc';

export class StringHelpers {

  //------------------------------------------------------------------//

  /**
   * Creates a copy of @param oldString and return it.
   */
  static Clone = (oldString?: string | null): string => `${oldString ?? ''}`

  //------------------------------------------------------------------//

  /**
   * Checks if we have a blank or null String
   */
  static IsNullOrWhitespace = (str: string): boolean =>
    str === null || str.match(/^ *$/) !== null;

  //------------------------------------------------------------------//

  static IsString = (x: any) => typeof x === 'string' || x instanceof String;

  //------------------------------------------------------------------//

  /**
   * Removes all whitespac from String
   */
  static RemoveWhitespace = (str: string): string =>
    str.replace(/\s+/g, '').trim();

  //------------------------------------------------------------------//

  static ToTitleCase(originalString?: string): string {
    if (!originalString) return originalString ?? '';

    const strArray = originalString.split(/(?=[A-Z])/).join(' ');
    return strArray[0].toUpperCase() + strArray.slice(1);
  } //ToTitleCase

  //------------------------------------------------------------------//

  /**
   * Truncates string and adds elipses if required
   * @param originalStr string to be truncated
   * @param maxLength maximum length of truncated string (before elipses): default = 25
   * @param useElipses Add elipses to result if the string was truncated: default = true
   */
  static Truncate(
    originalStr?: string,
    maxLength: number = 25,
    useElipses: boolean = true
  ): string {
    if (!originalStr) return originalStr ?? '';

    const trail = useElipses ? '...' : '';

    if (originalStr.length > maxLength)
      return originalStr.substring(0, maxLength) + trail;
    else return originalStr;
  } //Truncate

  //------------------------------------------------------------------//

  /**
   *
   * @param num Pad the start of number/string with paddingChar's
   * @param size max string length
   * @param paddingChar what to pad with
   * @returns
   */
  static Pad(num: Identifier, size: number, paddingChar: string = '0') {
    if (!num) num = '';

    num = num.toString();

    while (num.length < size) num = `${paddingChar}${num}`;

    return num;
  } //Pad

  //------------------------------------------------------------------//
} //Cls

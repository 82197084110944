<div
  class="dialog-container"
  [style.--clrTheme]="_colorHeader"
  [class.custom-colors]="_colorHeader"
>
  <div class="dialog-header" [style.color]="_colorHeaderText">
    {{ dialogConfig.data.title }}
  </div>

  <div class="dialog-message">
    {{ dialogConfig.data.message }}
  </div>

  <hr />

  <div class="row-butt">
    <button #buttOk (click)="onCloseClick()">OK</button>
    <inigo-copy-button
      *ngIf="_showCopyButton"
      [color]="_colorHeader"
      [copyText]="dialogConfig.data.message">
    </inigo-copy-button>
  </div>
</div>



import { Injectable, inject } from '@angular/core';
import { MyBS } from '@inigo/gen-helpers/classes';
import { APagePkgStoreService } from '@inigo/my-store';
import { FilterListItem, PagedRequest } from '@inigo/pagination';
import { Observable, filter, map, merge, startWith } from 'rxjs';
import { Brand } from '../../../dtos/brand';
import { Department } from '../../../dtos/dept';
import { ProductPagePackage } from '../../../dtos/prod-page-package';
import { Product } from '../../../dtos/product';
import { ProductUpload } from '../../../dtos/product-upload';
import { FilterListHelpers } from '../../../utility/filter-list-heplers';
import { AProductHttpService } from '../../http/prod/a-prod.service';
import { ProductCustomerService } from '../../http/prod/prod-customer.service';
import { DeptStoreService } from '../depts/dept-store.service';
import { MsrDefsStoreService } from '../msr-defs/msr-defs-store.service';
@Injectable({
  providedIn: 'root',
})
export abstract class AProdStoreService extends APagePkgStoreService<Product, ProductUpload, ProductPagePackage> {

  protected _deptStore = inject(DeptStoreService)
  protected _msrDefsStore = inject(MsrDefsStoreService)

  //-------------------------------------------------------//

  private _pkgDepartments$ = this.package$.pipe(
    map(pkg => pkg?.departments ?? []),
    startWith([])
  )
  private _storeMsrDepartments$ = this._deptStore.filteredData$.pipe(
    filter(data => !!data?.length)) //In case  filteredData$ wasn't refreshed.

  departments$ = merge(this._pkgDepartments$, this._storeMsrDepartments$)


  private _pkgMsrDefs$ = this.package$.pipe(
    map(pkg => pkg?.measurements ?? []),
    startWith([])
  )
  private _storeMsrDefs$ = this._msrDefsStore.filteredData$.pipe(
    filter(data => !!data?.length)) //In case  filteredData$ wasn't refreshed.

  measurementDefs$ = merge(this._pkgMsrDefs$, this._storeMsrDefs$)


  units$ = this.package$.pipe(
    map(pkg => pkg?.units ?? []),
    startWith([])
  )

  deptsFilterList$ = this.departments$.pipe(
    map(depts => this.toDeptFilters(depts))
  )

  protected _brandsBs: MyBS<Brand[]> = new MyBS<Brand[]>();
  brands$: Observable<Brand[]> = this._brandsBs.obs$

  protected _prodGalleryUpdatedBs: MyBS<Product> = new MyBS<Product>()
  prodGalleryUpdated$: Observable<Product> = this._prodGalleryUpdatedBs.obs$

  brandsFilterList$ = this.brands$.pipe(
    map(brnds => this.toBrandFilters(brnds))
  )

  protected _productService: ProductCustomerService;

  //-------------------------------------------------------//

  constructor(httpService: AProductHttpService) {
    super(httpService)

    this.setItemTypeName('Product')
    this.setItemNameLamda((p) => p.name ?? `Product ${p.id}`);

    this._productService = httpService

  } //ctor

  //-------------------------------------------------------//

  protected override handlePagePackageResponse(pkg: ProductPagePackage): void {

    super.handlePagePackageResponse(pkg)
    this._brandsBs.next(pkg.brands)

  } //handleResponse

  //-------------------------------------------------------//

  private toBrandFilters(brnds: Brand[]): FilterListItem[] {

    const brandsFilterList: FilterListItem[] = []
    brnds.forEach((stl) => {
      FilterListHelpers.pushIfUnique(brandsFilterList, stl)
    })

    return brandsFilterList

  }//brandsFilterList

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  private toDeptFilters(brnds: Department[]): FilterListItem[] {

    const deptsFilterList: FilterListItem[] = []
    brnds.forEach((stl) => {
      FilterListHelpers.pushIfUnique(deptsFilterList, stl)
    })

    return deptsFilterList

  }//deptsFilterList

  //-------------------------------------------------------//

  refreshPagePackage = () => this.getPagePackage(PagedRequest.Empty())

  //-------------------------------------------------------//

} //Cls

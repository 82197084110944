import { InjectionToken } from '@angular/core'

export const TextHelpersConfigService = new InjectionToken<TextHelpersConfig>("TextHelpersConfig")


export class TextHelpersConfig {

    /**Used for underline color */
    colorPrimary: string

    //---------------------------------------------------------------------//

    /**
     * @param color1 Used for underline color
     */
    constructor(colorPrimary: string = 'var(--colorPrimary)') {

        this.colorPrimary = colorPrimary
    }//ctor

    //---------------------------------------------------------------------//    

}//Cls
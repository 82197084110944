<div
  class="overlay"
  [class.show]="_show"
  [style.z-index]="_zIndex"
  (click)="onOverlayClicked($event)">
  <!-- [class.spinner-container]="_isSpinner"> -->
  <div
    class="dialog"
    (click)="onDialogClicked($event)"
    [class.transparent-dialog]="_isTransparentDialog"
    [style.maxWidth]="_maxWidth"
    [style.width]="_width"
    [style.maxHeight]="_maxHeight">
    <!-- [class.is-spinner]="_isSpinner"> -->
    <ng-template inigoPopUpInsertion></ng-template>
    <div class="bond-screen"></div>
  </div>
</div>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';

/**
 * This interceptor shows a popup spinner every time a http request is made.
 */
export abstract class ALoaderInterceptor implements HttpInterceptor {

  private _urlsToIgnore: Set<string> = new Set()
  private _urlsToIgnoreWildCards: string[] = ['assets']
  private _isLoading: boolean = false
  private _wildCard = '*'
  /**
   * This is used to handle multiple requests at the same time.
   * Is loading is true until they have all returned.
   */
  private _ongoingRequests: Set<string> = new Set<string>()

  //---------------------------------------------//

  constructor(urlsToIgnore: string[]) {

    urlsToIgnore.forEach((uti: string) => {

      const formattedUrl = this.formatUrl(uti)
      this._urlsToIgnore.add(formattedUrl)

      if (formattedUrl.endsWith(this._wildCard))
        this._urlsToIgnoreWildCards.push(this.removeLastSegment(formattedUrl))
    })



  }//ctor

  //---------------------------------------------//

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.skipUrl(req.url))
      return next.handle(req);

    this._ongoingRequests.add(req.url);

    if (!this._isLoading)
      this.onLoadingStarted(req.url)

    this._isLoading = true

    return next.handle(req).pipe(
      finalize(() => this.completedRequest(req.url))
    )

  }//intercept

  //---------------------------------------------//

  /**
   * Should we skip the loader for this url
   * @param requestedUrl The url we've requested
   */
  private skipUrl(requestedUrl: string): boolean {

    var formattedUrl = this.formatUrl(requestedUrl)
    if (this._urlsToIgnore.has(formattedUrl))
      return true

    // console.log(this._urlsToIgnoreWildCards, this.removeLastSegment(formattedUrl));

    for (const url of this._urlsToIgnoreWildCards) {

      if (formattedUrl.startsWith(url))
        return true

    } //for

    return false

  }//skipUrl

  //---------------------------------------------//

  /**
   * Removes requestedUrl from _ongoingRequests and if _ongoingRequests is empty, closes the spnr
   * @param requestedUrl Requested Url
   * @param spnr The open Spinner
   */
  private completedRequest(requestedUrl: string) {

    if (!this._ongoingRequests.has(requestedUrl)) return

    this._ongoingRequests.delete(requestedUrl);

    if (!this._ongoingRequests?.size) {
      this.onLoadingCompleted(requestedUrl);
      this._isLoading = false
    }//if

  }//onRequestCompleted

  //---------------------------------------------//

  protected abstract onLoadingStarted(requestedUrl: string): void

  //---------------------------------------------//

  protected abstract onLoadingCompleted(requestedUrl: string): void


  //---------------------------------------------//

  private formatUrl = (url: string): string =>
    this.trimSlashes(url)
      .toLowerCase()

  private trimSlashes = (url: string): string =>
    url.replace(/^\/+/g, '')
      .replace(/\/+$/, '')

  private removeLastSegment = (url: string): string =>
    url.substring(0, url.lastIndexOf('/'));


  //---------------------------------------------//

} //Cls

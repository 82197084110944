import { Identifier } from '@inigo/data/misc';
import { Department } from './dept';
import { DimensionDefinition } from './dimension-definition';
import { ShopDocument } from './shop-doc';
import { ShopItem } from './shop-item';
import { VariantColor } from './color';
import { Style } from './style';
import { ProductVariant } from './variant';
import { Unit } from './unit';
import { Brand } from './brand';
import { ShopImg } from './shop-img';

export class Product extends ShopItem {

  public brandId: Identifier
  /** Who makes this product */
  public brand?: Brand

  /** More info */
  public description2?: string

  /**
   * What the product is selling for
   */
  public salePrice?: number

  /**
   * What was paid for the product
   */
  public costPrice?: number

  /**
   * Id of department representing this product
   */
  public departmentId: Identifier

  /**
   * Department/Category of this product
   */
  public department?: Department

  /**
 *  Id of DimensionDefinition representing this product
 */
  public dimensionDefId: Identifier
  /**
   * Dimensions/Size of proddimensionDef
   */
  public dimensionDef: DimensionDefinition = new DimensionDefinition()


  public noDimensions?: boolean

  public varyByBrand?: boolean
  public varyByColor?: boolean
  public varyByStyle?: boolean
  public varyByUnit?: boolean

  public variantCount?: number
  public brandCount?: number

  public measurement1Display?: string
  public measurement2Display?: string
  public measurement3Display?: string
  public measurement1Name?: string
  public measurement2Name?: string
  public measurement3Name?: string

  public styleName?: string

  public brands?: Brand[]
  public colors?: VariantColor[]
  public styles?: Style[]
  public units?: Unit[] = []
  public variants?: ProductVariant[]


  /**Collection of extra shop images */
  public gallery: ShopImg[] = []
  /**Collection of extra shop image urls */
  public galleryUrls: string[] = []

  /**Collection of extra shop images */
  public documentUrls: string[] = []
  /**Collection of extra documnets */
  public documents: ShopDocument[] = []

  //-------------------------------------------------------------//

  /**
   * Create empty department with non-null images to prevent null exceptions when adding files
   * @returns Empty Product
   */
  static Create(): Product {

    const prod = new Product()
    return prod

  } //Create

  //-------------------------------------------------------------//

} //Cls


// ###############################################################################//


export function getProductGallery(prod?: Product): string[] {

  let gallery: string[] = []
  if (!prod)
    return gallery

  if (prod.imgUrl)
    gallery.push(prod.imgUrl)

  if (prod.img2Url)
    gallery.push(prod.img2Url)

  if (prod.img3Url)
    gallery.push(prod.img3Url)

  if (prod.galleryUrls?.length)
    gallery = gallery.concat(prod.galleryUrls)

  return gallery

}//setGallery

//--------------------------------------------------------//


export function getProductDocuments(prod?: Product): ShopDocument[] {

    let docs: ShopDocument[] = []
    if (!prod)
      return docs

    if (prod.documents)
      return prod.documents


    return prod.documentUrls.map(
      url => new ShopDocument(0, url)
    )

  }//setDocuments

//--------------------------------------------------------//


export function getProductVideoLinks(prod?: Product): string[] {

  let videoLinks: string[] = []
  if (!prod)
    return videoLinks

  if (prod.linkVideo)
    videoLinks.push(prod.linkVideo)

  if (prod.linkVideo2)
    videoLinks.push(prod.linkVideo2)

  if (prod.linkVideo3)
    videoLinks.push(prod.linkVideo3)

  return videoLinks

}//setVideoLinks


// ###############################################################################//

import { CommonModule } from '@angular/common';
import { importProvidersFrom, isDevMode, ModuleWithProviders, NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  imports: [CommonModule, MatBottomSheetModule, ServiceWorkerModule],
})
export class SwHelpersModule {

  static configure(registrationTimeLimitMillis: number = 30000): ModuleWithProviders<SwHelpersModule> {

    return {
      ngModule: SwHelpersModule,
      providers: [
        importProvidersFrom(MatSnackBarModule),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: `registerWhenStable:${registrationTimeLimitMillis}`,
        }).providers!,
      ],
    }

    
  } //configure


} //Cls


import { Identifier } from '@inigo/data/misc';
import { DimensionDefinition } from './dimension-definition';
import { Measurement } from './measurement';
import { ProductVariant } from './variant';

export class Dimension {
  
  /** Database Identifier */
  public id: Identifier;

  public definitionId: Identifier;
  public definition?: DimensionDefinition;
  
  public productVariantId: Identifier;
  public productVariant?: ProductVariant;

  // public measurement1Id: number
  public measurement1Value?: number

  // public measurement2Id: number
  public measurement2Value?: number

  // public measurement3Id: number
  public measurement3Value?: number

  // public measurement4Id: number
  public measurement4Value?: number

  //-------------------------------------------------------------//

  /**
   * Create empty Dimension for 'New' form
   * @returns Empty Dimension
   */
  static Create(): Dimension {
    const dmn = new Dimension();
    // dmn.measurement1Value = 0

    return dmn;
  } //Create

  //-------------------------------------------------------------//
} //Cls

import { EnvironmentProviders, Provider } from "@angular/core"
import { DateAdapter_DD_MMM_YYYY, DateAdapter_DD_MMM_YYYY_HH_mm, DATE_FORMAT_DD_MMM_YYYY, DATE_FORMAT_DD_MMM_YYYY_HH_mm } from '@inigo/gen-helpers/date-time';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


export class MyMatSetup {

    /**
     * Get the providers necesary for using my-mat with Date Format dd-mmm-yyyy
     */
    public static getProviders_DD_MMM_YYYY(): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: DateAdapter,
                useClass: DateAdapter_DD_MMM_YYYY,
                deps: [MAT_DATE_LOCALE],
            },
            {
                provide: MAT_DATE_FORMATS,
                useValue: DATE_FORMAT_DD_MMM_YYYY,
            }
        ]

    } //getProviders

    //--------------------------------------------------------------------------------//

    /**
     * Get the providers necesary for using my-mat with Date Format dd-mmm-yyyy HH:mm
     */
    public static getProviders_DD_MMM_YYYY_HH_mm(): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: DateAdapter,
                useClass: DateAdapter_DD_MMM_YYYY_HH_mm,
                deps: [MAT_DATE_LOCALE],
            },
            {
                provide: MAT_DATE_FORMATS,
                useValue: DATE_FORMAT_DD_MMM_YYYY_HH_mm,
            }
        ]

    } //getProviders



} //Cls
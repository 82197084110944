import { InjectionToken } from '@angular/core';
import { StringHelpers } from '@inigo/gen-helpers/text';
import { ContactDetailsConfig, TEST_CONTACT_DETAILS } from './details-config';

//===================================================================//

export const ContactMapConfigService = new InjectionToken<ContactMapConfig>('ContactMapConfig');

//===================================================================//

export class ContactMapConfig {

  _detailsConfig: ContactDetailsConfig = TEST_CONTACT_DETAILS;
  /** Contact info (address, email, etc.) - default TEST_CONTACT_DETAILS*/
  public get detailsConfig(): ContactDetailsConfig {
    return this._detailsConfig;
  }

  /** Defines which Google Maps libraries should get loaded. - default ['places'] */
  _agmLlibraries = ['places'];
  public get agmLlibraries(): string[] {
    return this._agmLlibraries;
  }

  _apiKey?: string;
  /** Google Maps API version. - no default */
  public get apiKey(): string {
    return StringHelpers.Clone(this._apiKey);
  }

  _mapIconPath = 'assets/images/location/map-icon.png';
  /** The path to the map icon (Used to Display your location) - default 'assets/images/location/map-icon.png' */
  public get mapIconPath(): string {
    return StringHelpers.Clone(this._apiKey);
  }

  //---------------------------------------------------------------------//

  /**
   * set paramater to null to use default values
   * @param apiKey Google Maps API version. - no default
   * @param detailsConfig Contact info (address, email, etc.) - default TEST_CONTACT_DETAILS
   * @param agmLlibraries   Defines which Google Maps libraries should get loaded. - default ['places']
   */
  protected constructor(
    apiKey: string,
    detailsConfig?: ContactDetailsConfig,
    agmLlibraries?: string[]
  ) {
    if (detailsConfig) this._detailsConfig = detailsConfig;

    if (apiKey) this._apiKey = apiKey;

    if (agmLlibraries) this._agmLlibraries = agmLlibraries;

    if (detailsConfig?.mapIconUrl)
      this._mapIconPath = detailsConfig?.mapIconUrl;
  } //ctor

  //---------------------------------------------------------------------//

  /**
   * @param apiKey Google Maps API version. - no default
   * @param contactDetailsConfig Contact info (address, email, etc.) - default TEST_CONTACT_DETAILS
   */
  static Create(
    apiKey: string,
    contactDetailsConfig: ContactDetailsConfig = TEST_CONTACT_DETAILS
  ): ContactMapConfig {
    return new ContactMapConfig(apiKey, contactDetailsConfig);
  } //create

  //---------------------------------------------------------------------//

  /**
   * @param agmLlibraries   Defines which Google Maps libraries should get loaded. - default ['places']
   * @returns Updated ContactUsConfig
   */
  setAgmLlibraries(agmLlibraries: string[]): ContactMapConfig {
    this._agmLlibraries = agmLlibraries;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param apiKey Google Maps API version. - no default
   * @returns Updated ContactUsConfig
   */
  setApiKey(apiKey: string): ContactMapConfig {
    this._apiKey = apiKey;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param detailsConfig Contact info (address, email, etc.) - default TEST_CONTACT_DETAILS
   * @returns Updated ContactUsConfig
   */
  setDetailsConfig(detailsConfig: ContactDetailsConfig): ContactMapConfig {
    this._detailsConfig = detailsConfig;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param mapIconPath The path to the map icon (Used to Display your location) - default 'assets/images/location/map-icon.png'
   * @returns Updated ContactUsConfig
   */
  setMapIconPath(mapIconPath: string): ContactMapConfig {
    this._mapIconPath = mapIconPath;
    // console.log(this._mapIconPath, mapIconPath);

    return this;
  }

  //---------------------------------------------------------------------//
} //Cls

import { NgModule } from '@angular/core';
import { CamelCaseToTitleCasePipe } from './camelcase-to-titlecase.pipe';
import { FileNamePipe } from './filename.pipe';
import { SafePipe } from './safe.pipe';
import { TimeAmPmPipe } from './time-am-pm.pipe';
import { TruncatePipe } from './truncate.pipe';


const pipes = [
  SafePipe,
  TruncatePipe,
  CamelCaseToTitleCasePipe,
  FileNamePipe,
  TimeAmPmPipe,
];

@NgModule({
  imports: [pipes],
  exports: [pipes],
})
export class TextPipesModule {}



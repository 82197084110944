export class Role {

  /**
   * @param displayName The name the client will see. Default = null
   * @param fullName The name used to identify this role
   * @param position The position in the Team that this role
   */
  constructor(
    public displayName: string,
    public fullName: string,
    public position: Position = 'User'
  ) {}

  //-----------------------------------------------------------------//

  compare(that: Role): number {
    const thisNameLower = this.displayName.toLocaleLowerCase();
    const thatNameLower = that.displayName.toLocaleLowerCase();

    return thisNameLower.localeCompare(thatNameLower);
  } //compare

  //-----------------------------------------------------------------//

  comparePosition(that: Role): number {
    let thisPosition = -1;
    let thatPosition = -1;

    if (PositionMap.has(this.position))
      thisPosition = PositionMap.get(this.position)!; //we already checked

    if (PositionMap.has(that.position))
      thatPosition = PositionMap.get(that.position)!;

    if (thisPosition > thatPosition) return 1;
    if (thisPosition < thatPosition) return -1;

    return 0;
  } //comparePosition

  //-----------------------------------------------------------------//
  
} //Cls

//=====================================================================================//

export type Position = 'User' | 'Manager' | 'Admin' | 'Leader' | 'Guest';
export const PositionMap: Map<Position, number> = new Map([
  ['Guest', 0],
  ['User', 1],
  ['Manager', 2],
  ['Admin', 3],
  ['Leader', 4],
]);

import { Injectable, inject } from "@angular/core";
import { ImagePreloaderService } from "@inigo/gen-helpers/classes";
import { IndustryConfig } from "../../shared/data/industry-config";

@Injectable({
    providedIn: 'root'
})
export class IndustryImagePreloader {

    private _preloader = inject(ImagePreloaderService)

    //-------------------------------------------------//

    preload() {

        this.preloadPlc()
        this.preloadMain()
        console.log('preloaded');
    }

    //-------------------------------------------------//

    preloadPlc() {

        const imgsPlc = IndustryConfig.ALL.map(ind => ind.imgSrcMainPlc)
        this._preloader.preload(imgsPlc)
        // console.log('preloadPlc')
    }

    //-------------------------------------------------//

    preloadMain() {

        const imgs = IndustryConfig.ALL.map(ind => ind.imgSrcMain)
        this._preloader.preload(imgs)
        // console.log('preloadMain')
    }

    //-------------------------------------------------//

}//Cls
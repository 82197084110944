import { Injectable } from '@angular/core';
import { Identifier } from '@inigo/data/misc';
import { APageStoreService, DefaultTemplateStoreService, SuccessInfo } from '@inigo/my-store';
import { PagedRequest } from '@inigo/pagination';
import { Observable, ReplaySubject } from 'rxjs';
import { BulkUploadDto } from '../../../dtos/bulk-upload';
import { Style } from '../../../dtos/style';
import { StyleHttpService } from '../../http/style/style.service';

@Injectable({
  providedIn: 'root',
})
export class StyleStoreService extends APageStoreService<Style, Style> {


  private _httpService: StyleHttpService
  private _templateStore: DefaultTemplateStoreService

  protected _templateBs = new ReplaySubject<Blob>(1)
  template$: Observable<Blob> = this._templateBs.asObservable()

  //-------------------------------------------------------//

  constructor(httpService: StyleHttpService) {
    super(httpService);

    this._httpService = httpService
    this._templateStore = DefaultTemplateStoreService.Create(
      httpService.url,
      this._templateBs,
      this._isLoadingBs,
      this._errorSb,
      this._successSb
    )

    this.setItemTypeName('Style')
    this.setItemNameLamda((p) => p.name ?? `${p.id}`);

  } //ctor

  //-------------------------------------------------------//

  addBulk(dto: BulkUploadDto, filename: string = 'upload_report.txt') {

    this._httpService.addBulk(dto).subscribe({
      next: (response) => {
        this._blobHandler.download(response, filename);
        this._successSb.next(
          SuccessInfo.Other(
            `Upload complete. Extra info wil be in downloaded file, "${filename}"`
          )
        );
      },
      error: (error) => this._errorSb.next(error),
    });
  } //addBulk

  //-------------------------------------------------------//

  getTemplate = (fileName: string = 'template.tmp', id?: Identifier): Observable<Blob> =>
    this._templateStore.getTemplate(fileName, id)

  //-------------------------------------------------------//

  override refreshDataIfNecessary(): void {

    if (!this.itemCountPaged)
      this.getPage(PagedRequest.Empty())

  }//refreshDataIfNecessary

  //-------------------------------------------------------//

} //Cls


import { Component, Input, OnDestroy, Inject, OnInit } from '@angular/core';
import { BouncerConfigService, BouncerConfig } from './bouncer-config';

@Component({
  selector: 'inigo-loaders-bouncer',
  templateUrl: './bouncer.component.html',
  styleUrls: ['./bouncer.component.scss'],
})
export class LoaderBouncerComponent implements OnInit, OnDestroy {
  private currentTimeout: number | undefined;
  public isDelayedRunning = true;

  @Input() delay = 150;
  @Input('useShadow') _useShadow = true;

  @Input('color1') _color1: string | undefined; //= 'cadetblue'
  @Input('color2') _color2: string | undefined; //= 'lime'

  @Input('widthPx') _widthPx = 80;
  @Input('heightPx') _heightPx = 80;

  //---------------------------------------------------------------------------------------------//

  @Input()
  public set isRunning(value: boolean) {
    if (!value) {
      this.cancelTimeout();
      this.isDelayedRunning = false;
      return;
    }

    if (this.currentTimeout) return;

    // specify window to side-step conflict with node types: https://github.com/mgechev/angular2-seed/issues/901
    this.currentTimeout = window.setTimeout(() => {
      this.isDelayedRunning = value;
      this.cancelTimeout();
    }, this.delay);
  } //isRunning

  //---------------------------------------------------------------------------------------------//

  constructor(@Inject(BouncerConfigService) config: BouncerConfig) {
    if (config.color1) this._color1 = config.color1;

    if (config.color2) this._color2 = config.color2;
  } //ctor

  //---------------------------------------------------------------------------------------------//

  ngOnInit(): void {
    this.isRunning = true;
  }

  //---------------------------------------------------------------------------------------------//

  private cancelTimeout(): void {
    clearTimeout(this.currentTimeout);
    this.currentTimeout = undefined;
  } //cancelTimeout

  //---------------------------------------------------------------------------------------------//

  ngOnDestroy(): any {
    this.cancelTimeout();
  } //ngOnDestroy

  //---------------------------------------------------------------------------------------------//
} //Cls

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable()
export class IconRetryInterceptor implements HttpInterceptor {

  private static readonly maxRetries = 3
  private static readonly delayMs = 2000
  private static failedIconRegex = /(.*)http failure(.*).svg(.*)/

  //-----------------------------------------------------------------------//

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(

      retry({
        count: IconRetryInterceptor.maxRetries,
        delay: this.shouldRetry
      })

    ) //pipe

  } //intercept

  //-----------------------------------------------------------------------//

  shouldRetry(error: HttpErrorResponse): Observable<0> {

    const isValid = IconRetryInterceptor.failedIconRegex.test(error.message.toLowerCase())

    if (isValid)
      return timer(IconRetryInterceptor.delayMs)
    else
      throw error

  }//shouldRetry

  //-----------------------------------------------------------------------//

} //Cls



import { Injectable } from '@angular/core';
import { APagePkgHttpService } from '@inigo/my-store';
import { AppControllers } from '../../../../app-controllers';
import { DimensionDefPagePackage } from '../../../dtos/dimen-def-page-package';
import { DimensionDefinition } from '../../../dtos/dimension-definition';

@Injectable({
  providedIn: 'root',
})
export class DimensionHttpService extends APagePkgHttpService<DimensionDefinition, DimensionDefinition, DimensionDefPagePackage> {
 

  constructor() {
    super();
    this.url = AppControllers.FullPath.DIMENS
  } //ctor

} //service

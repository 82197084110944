import { Injectable } from '@angular/core';
import { BouncerComponent } from '../../../components/spinners/bouncer/bouncer.component';
import { DialogRef } from '../../../utility/dialog-ref';
import { BaseSpinner } from '../base-spinner';
import { ISpinner } from '../i-spinner';

@Injectable({
  providedIn: 'root',
})
export class BouncerService extends BaseSpinner  implements ISpinner {

  public open = (): DialogRef | undefined =>
    this.openSpinner(BouncerComponent)

} //Cls

import { StringHelpers } from "@inigo/gen-helpers/text";

export { };
declare global {
    interface Date {
        dd_MMM_YYYY(): string;
        dd_MMM_YYYY_HH_mm(): string;
    }
    interface String {
        dd_MMM_YYYY(): string;
        dd_MMM_YYYY_HH_mm(): string;
    }
}

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

export { };
Date.prototype.dd_MMM_YYYY = function (): string {

    const day = this.toLocaleString('default', { day: '2-digit' })
    const year = this.toLocaleString('default', { year: 'numeric' })
    const month = sanitizeMonth(this.toLocaleString('default', { month: 'short' }))

    return day + '-' + month + '-' + year

}//dd_MMM_YYYY

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

export { };
String.prototype.dd_MMM_YYYY = function (): string {

    return new Date(this.toString() ?? new Date()).dd_MMM_YYYY()

}//dd_MMM_YYYY

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

export { };
Date.prototype.dd_MMM_YYYY_HH_mm = function (): string {

    const day = this.toLocaleString('default', { day: '2-digit' })
    const year = this.toLocaleString('default', { year: 'numeric' })
    const month = sanitizeMonth(this.toLocaleString('default', { month: 'short' }))
    const hours = StringHelpers.Pad(this.getHours(), 2)
    const mins = StringHelpers.Pad(this.getMinutes(), 2)


    return `${day}-${month}-${year} ${hours}:${mins}`

}//dd_MMM_YYYY_HH_mm

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

export { };
String.prototype.dd_MMM_YYYY_HH_mm = function (): string {

    return new Date(this.toString() ?? new Date()).dd_MMM_YYYY_HH_mm()

}//dd_MMM_YYYY

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - -//


function sanitizeMonth(month: string): string {

    if (month.length > 3)
        month = month.substring(0, 3)

    return month

}//sanitizeMonth

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - -//


import { InsertedDto } from '@inigo/data/insert';
import { Identifier } from '@inigo/data/misc';
import { NotFoundError } from '@inigo/errors';
import { MyBS, MyBSArray } from '@inigo/gen-helpers/classes';
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { DuplicateErrorInfo, ErrorInfo, SuccessInfo } from '../models/result-info';
import { ICrudHttpService } from './i-crud-http.service';
import { ICrudStoreService } from './i-crud-store.service';
import { Directive, OnDestroy } from '@angular/core';
import { ItemIdx } from './item-idx';
import { toSignal } from '@angular/core/rxjs-interop';

//################################################################################//

/**
 * If not using PagePackages just override this.getPagePackage()  with: this.getPage()
 */
@Directive()
export abstract class ACrudStoreService<T, TUpload extends T> implements ICrudStoreService<T, TUpload>, OnDestroy {

  protected _filteredDataBsArray: MyBSArray<T> = new MyBSArray<T>([])
  filteredData$: Observable<T[]> = this._filteredDataBsArray.obs$
  filteredData = toSignal(this.filteredData$, { initialValue: [] })

  protected _currentItemBs = new MyBS<T | undefined>()
  currentItem$: Observable<T | undefined> = this._currentItemBs.obs$
  currentItem = toSignal(this.currentItem$, { initialValue: undefined })

  protected _isLoadingBs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  isLoading$: Observable<boolean> = this._isLoadingBs.asObservable()
  isLoading = toSignal(this.isLoading$, { initialValue: false })

  //Use Subjects here because ou don't want a user to be sent the previous error.
  //All relevant Success/Error info will come AFTER subscription
  protected _errorSb: Subject<any> = new Subject<any>()
  error$: Observable<any> = this._errorSb.asObservable()

  protected _duplicateErrorSb: Subject<DuplicateErrorInfo<T>> = new Subject<DuplicateErrorInfo<T>>()
  duplicateError$: Observable<DuplicateErrorInfo<T>> = this._duplicateErrorSb.asObservable()

  protected _successSb: Subject<SuccessInfo<T>> = new Subject<SuccessInfo<T>>()
  success$: Observable<SuccessInfo<T>> = this._successSb.asObservable()

  protected _addedSb: Subject<T> = new Subject<T>()
  added$: Observable<T> = this._addedSb.asObservable()

  protected _editedSb = new Subject<ItemIdx<T>>()
  edited$: Observable<ItemIdx<T>> = this._editedSb.asObservable()

  protected _deletedSb = new Subject<ItemIdx<T>>()
  deleted$ = this._deletedSb.asObservable()

  protected _destroy$ = new Subject()

  protected _addSub?: Subscription
  protected _deleteSub?: Subscription
  protected _editSub?: Subscription
  protected _filteredDataSub?: Subscription
  protected _getSub?: Subscription

  /** Name to be used in success messages ("_itemTypeName, item.name has been deleted") */
  // protected _itemTypeName: string = 'item'
  getItemTypeName: (t: T) => string = (t: T) => (t as Object).constructor.name
  protected setItemTypeName = (name: string) => this.getItemTypeName = () => name

  getItemName: (t: T) => string = (t: T) => this.getItemTypeName(t)
  protected setItemNameLamda = (lmda: (t: T) => string) => this.getItemName = lmda
  protected setItemName = (name: string) => this.getItemName = () => name

  //-------------------------------------------------------//

  constructor(protected httpService: ICrudHttpService<T, TUpload>) { }

  //-------------------------------------------------------//

  add(item: TUpload): Observable<T> {
    this._isLoadingBs.next(true)

    //store the constructor to help identify the returned item
    const ctor = (item as Object).constructor

    this._addSub?.unsubscribe()
    const addItem$ = this.httpService
      .addItem(item)
      .pipe(
        finalize(() => this._isLoadingBs.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
      )


    this._addSub = addItem$.subscribe({
      next: (newItem: T) => {

        (newItem as Object).constructor = ctor

        this._addedSb.next(newItem)
        this._filteredDataBsArray.push(newItem)
        this._successSb.next(
          SuccessInfo.Add(
            this.getItemTypeName(item),
            this.getItemName(newItem),
            newItem
          )
        )
      },
      error: (error) => this._errorSb.next(error),
    }) //subscribe

    return addItem$

  } //add 

  //-------------------------------------------------------//

  addWithCheck(item: TUpload, skipDuplicateCheck = false): Observable<InsertedDto<T>> {
    // console.log('addWithCheck', item, skipDuplicateCheck, this.httpService.addItemWithCheck)

    if (!this.httpService.addItemWithCheck) {
      this._errorSb.next(ErrorInfo.Add('Method, "addWithCheck" not found'))
      return EMPTY
    } //if

    this._isLoadingBs.next(true)

    this._addSub?.unsubscribe() //switchMap
    const addItem$ = this.httpService
      .addItemWithCheck(item, skipDuplicateCheck)
      .pipe(
        finalize(() => this._isLoadingBs.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
      )

    this._addSub = addItem$.subscribe({
      next: (dto: InsertedDto<T>) => {
        if (!dto.succeeded) {
          this._duplicateErrorSb.next(DuplicateErrorInfo.Add(dto, item))
        } else {
          this._addedSb.next(dto.entry!)
          this._filteredDataBsArray.push(dto.entry!)
          this._successSb.next(
            SuccessInfo.Add(
              this.getItemTypeName(item),
              this.getItemName(item),
              item
            )
          )
        } //else
      },
      error: (error) => this._errorSb.next(error),
    }) //subscribe

    return addItem$

  } //add

  //-------------------------------------------------------//

  delete(id: Identifier, item: T, idx: number = -1): Observable<void> {


    this._isLoadingBs.next(true)

    this._deleteSub?.unsubscribe()
    const deleteItem$ = this.httpService.deleteItem(id)
      .pipe(
        finalize(() => this._isLoadingBs.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
      )


    this._deleteSub = deleteItem$.subscribe({
      next: () => {
        this._deletedSb.next({ item: item, idx: idx })
        this._successSb.next(
          SuccessInfo.Delete(this.getItemTypeName(item), this.getItemName(item))
        )

        if (this.isValidIndex(idx)) {
          this._filteredDataBsArray.remove(idx)
        }
      },
      error: (error) => this._errorSb.next(error),
    }) //subscribe

    return deleteItem$

  } //delete

  //-------------------------------------------------------//

  edit(item: TUpload, idx: number = -1): Observable<T> {
    this._isLoadingBs.next(true)

    this._editSub?.unsubscribe() //cancel previous
    const editItem$ = this.httpService.editItem(item)
      .pipe(
        finalize(() => this._isLoadingBs.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
      )

    this._editSub = editItem$.subscribe({
      next: (editedItem: T) => {



        this._editedSb.next({ item: item, idx: idx })
        this._successSb.next(
          SuccessInfo.Edit(
            this.getItemTypeName(editedItem),
            this.getItemName(editedItem),
            item
          )
        )
        if (this.isValidIndex(idx)) {
          console.log(this.getItemTypeName(editedItem));
          this._filteredDataBsArray.replace(editedItem, idx)
        }
      },
      error: (error) => this._errorSb.next(error),
    }) //subscribe


    return editItem$

  } //edit

  //-------------------------------------------------------//

  filterData(filter?: any): Observable<T[]> {

    this._isLoadingBs.next(true)

    this._filteredDataSub?.unsubscribe()
    const filterData$ = this.httpService
      .getAllItemsFiltered(filter ?? '')
      .pipe(
        finalize(() => this._isLoadingBs.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
      )


    this._filteredDataSub = filterData$.subscribe({
      next: (data: T[]) => this._filteredDataBsArray.next(data),
      error: (error) => {
        if (error instanceof NotFoundError || !this._filteredDataBsArray.getValue())
          this._filteredDataBsArray.next([])
        this._errorSb.next(error)
      }
    }) //subscribe

    return filterData$

  } //filter

  //-------------------------------------------------------//

  get(id: Identifier): Observable<T> {

    this._isLoadingBs.next(true)

    this._getSub?.unsubscribe()
    const get$ = this.httpService.getItem(id)
      .pipe(
        finalize(() => this._isLoadingBs.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
      )

    this._getSub = get$.subscribe({
      next: (item: T) => this._currentItemBs.next(item),
      error: (error) => {
        if (error instanceof NotFoundError || !this._currentItemBs.getValue())
          this._currentItemBs.next(undefined)  //Clear it
        this._errorSb.next(error)
      }
    })

    return get$

  } //get

  //-------------------------------------------------------//

  /** Clean up resources and subscriptions */
  tidyUp(): void {
    this._filteredDataBsArray.next([])
    this._addSub?.unsubscribe()
    this._deleteSub?.unsubscribe()
    this._editSub?.unsubscribe()
    this._filteredDataSub?.unsubscribe()
    this._getSub?.unsubscribe()
  } //tidyUp

  //-------------------------------------------------------//

  getCurrentFilteredDataSize = (): number =>
    this._filteredDataBsArray.getValue()?.length ?? 0

  //-------------------------------------------------------//

  private isValidIndex = (idx: number) =>
    idx !== null && idx !== undefined && idx > -1

  //-------------------------------------------------------//

  refreshData(): void { this.filterData() }

  //-------------------------------------------------------//

  /**
   * If no page data exists request it from the Server.
   * OVerride to add or remove functionallity
   */
  protected refreshFilteredDataIfNecessary() {
    if (this.getCurrentFilteredDataSize() < 1) this.filterData()

  } //refreshIfNecessary

  //-------------------------------------------------------//

  refreshDataIfNecessary?(): void

  //-------------------------------------------------------//

  ngOnDestroy(): void {
    this.tidyUp()
    // console.log('destroy');

  }

  //-------------------------------------------------------//

} //Cls



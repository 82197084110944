import { EnvironmentProviders, Provider } from "@angular/core";
import { AuthConfig, AuthConfigService } from "./auth-config";

export class AuthSetup {

    static getProviders(config: AuthConfig): (Provider | EnvironmentProviders)[] {

        return [
            {
                provide: AuthConfigService,
                useValue: config,
            },
        ]

    } //configure


} //Cls
import { InjectionToken } from '@angular/core';
import { ContactDetailsConfig, TEST_CONTACT_DETAILS } from './details-config';
import { ContactFormConfig } from './form-config';
import { ContactMapConfig } from './map-config';
import { ContactPanelsConfig } from './panels-config';

//===================================================================//

export const ContactConfigService = new InjectionToken<ContactConfig>('ContactUsConfig');

//===================================================================//

export class ContactConfig {

  private _formConfig: ContactFormConfig = ContactFormConfig.Create()
  /** Contact Info for the ContactService API (Urls & Actions) */
  public get formConfig(): ContactFormConfig {
    return this._formConfig;
  }

  private _mapConfig: ContactMapConfig
  /** Contact info (Location, MapIconUrl, ContactDetails for the icon pop-up)  */
  public get mapConfig(): ContactMapConfig {
    return this._mapConfig;
  }

  private _detailsConfig: ContactDetailsConfig = TEST_CONTACT_DETAILS
  /** Contact info (address, email, etc.) - default TEST_CONTACT_DETAILS*/
  public get detailsConfig(): ContactDetailsConfig {
    return this._detailsConfig;
  }

  private _panelsConfig: ContactPanelsConfig
  /** Contact info (address, email, etc.) - default TEST_CONTACT_DETAILS*/
  public get panelsConfig(): ContactPanelsConfig {
    return this._panelsConfig;
  }


  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * Enter no paramaters to use default values on everything
   * @param mapConfig Contact info (Location, MapIconUrl, ContactDetails for the icon pop-up) 
   * @param detailsConfig Contact info (address, email, etc.) - default TEST_CONTACT_DETAILS
   * @param formConfig  Contact Info for the ContactService API (Urls & Actions)
   */
  private constructor(
    mapConfig: ContactMapConfig,
    formConfig?: ContactFormConfig,
    detailsConfig?: ContactDetailsConfig
  ) {

    this._mapConfig = mapConfig

    if (formConfig)
      this._formConfig = formConfig

    this._detailsConfig = detailsConfig ?? mapConfig.detailsConfig

    this._panelsConfig = ContactPanelsConfig.Create(this.mapConfig, this.formConfig)

  } //ctor

  //---------------------------------------------------------------------//

  /**
   * @param mapConfig Contact info (Location, MapIconUrl, ContactDetails for the icon pop-up) 
   * @param contactDetailsConfig Contact info (address, email, etc.) - default TEST_CONTACT_DETAILS
   * @param formConfig  Contact Info for the ContactService API (Urls & Actions)
   */
  static Create(
    mapConfig: ContactMapConfig,
    formConfig?: ContactFormConfig,
    detailsConfig?: ContactDetailsConfig,
  ): ContactConfig {

    return new ContactConfig(mapConfig, formConfig, detailsConfig)

  }

  //---------------------------------------------------------------------//

  /**
   * @param formConfig  Contact Info for the ContactService API (Urls & Actions)
   * @returns Updated ContactUsConfig
   */
  setFormConfig(formConfig: ContactFormConfig): ContactConfig {

    this._formConfig = formConfig;
    return this

  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param mapConfig Contact info (Location, MapIconUrl, ContactDetails for the icon pop-up) 
   * @returns Updated ContactUsConfig
   */
  setMapConfig(mapConfig: ContactMapConfig): ContactConfig {

    this._mapConfig = mapConfig;
    return this

  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param detailsConfig Contact info (address, email, etc.) - default TEST_CONTACT_DETAILS
   * @returns Updated ContactUsConfig
   */
  setDetailsConfig(detailsConfig: ContactDetailsConfig): ContactConfig {
    this._detailsConfig = detailsConfig;
    return this;
  }



  //---------------------------------------------------------------------//
} //Cls

import { Component, OnInit, Inject } from '@angular/core';
import {
  PopUpConfig,
  PopUpConfigService,
} from '../../../setup/pop-up-config';

@Component({
  selector: 'app-dot-spinner',
  templateUrl: './dot-spinner.component.html',
  styleUrls: ['./dot-spinner.component.scss'],
})
export class DotSpinnerComponent implements OnInit {
  //This will be used to set the Inputs of DotSpinnerComponent rather than through the forRoot Config
  //That was this won't interfere with another use of the DotSpinnerModule
  _colors: string[] = new Array(8);
  _animationDuration: number; //= 666// = 1.2
  _message: string; //= "Loading"

  private _color1: string = '#00ff80';
  private _color2: string = '#00ffea';
  private _color3: string = '#00aaff';
  private _color4: string = '#0040ff';
  private _color5: string = '#2a00ff';
  private _color6: string = '#9500ff';
  private _color7: string = '#ff00ff';
  private _color8: string = '#ff0095';

  //---------------------------------------------------------------------------------//

  constructor(@Inject(PopUpConfigService) popUpConfig: PopUpConfig) {
    this._colors[0] = popUpConfig.dotSpinnerConfig.color1 ?? this._color1;
    this._colors[1] = popUpConfig.dotSpinnerConfig.color2 ?? this._color2;
    this._colors[2] = popUpConfig.dotSpinnerConfig.color3 ?? this._color3;
    this._colors[3] = popUpConfig.dotSpinnerConfig.color4 ?? this._color4;
    this._colors[4] = popUpConfig.dotSpinnerConfig.color5 ?? this._color5;
    this._colors[5] = popUpConfig.dotSpinnerConfig.color6 ?? this._color6;
    this._colors[6] = popUpConfig.dotSpinnerConfig.color7 ?? this._color7;
    this._colors[7] = popUpConfig.dotSpinnerConfig.color8 ?? this._color8;

    this._animationDuration = popUpConfig.dotSpinnerConfig.animationDuration;

    this._message = popUpConfig.dotSpinnerConfig.message;
  } //ctor

  //---------------------------------------------------------------------------------//

  ngOnInit(): void {}

  //---------------------------------------------------------------------------------//
} //Cls

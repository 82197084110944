import { Injectable, Inject, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { HttpServicesConfig, HttpServicesConfigService } from '@inigo/http-services/config';
import { ErrorHelpers } from '@inigo/gen-helpers/errors';

@Injectable({
  providedIn: 'root',
})
export class LoggingService extends DataService {

  private _errorhelpers = inject(ErrorHelpers)
  private _config: HttpServicesConfig = inject(HttpServicesConfigService)

  //- - - - - - - - - - - - - - - - - - - - -//

  private _loggingAction: string;

  //-----------------------------------------//

  constructor() {
    super()

    this._url = this._config.loggingUrl
    this._loggingAction = this._config.loggingAction;
  } //ctor

  //-----------------------------------------//

  public websiteLog(error: any) {

    const errorObj = this._errorhelpers.CreateErrorInfoObject(error);
    console.log('LoggingService.websiteLog', errorObj);
    return this.postAction(this._loggingAction, errorObj);
  } //websiteLog

  //-----------------------------------------//
} //Cls

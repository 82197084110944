import { Injectable } from "@angular/core";

//============================================================================================================//
export const YOUTUBE_REGEX_BASIC = /((http|https):\/\/)?(www\.)?(youtube\.com)(\/)?([a-zA-Z0-9\-\.]+)\/?/
export const VIMEO_REGEX_BASIC = /vimeo.*\/(\d+)/i
export const YOUTUBE_VIDEO_ID_KEY = 'v'
//============================================================================================================//

@Injectable({
    providedIn: 'root'
})
export class UrlHelpers {

    //------------------------------------------------------------------//

    isYoutubeUrl(url: string): boolean {

        const regex = new RegExp(YOUTUBE_REGEX_BASIC)
        return regex.test(url)

    }//IsYoutubeUrl

    //------------------------------------------------------------------//

    isVimeoUrl(url: string): boolean {

        const regex = new RegExp(VIMEO_REGEX_BASIC)
        return regex.test(url)

    }//IsVimeoUrl

    //------------------------------------------------------------------//

    getQueryParams(url: string): Map<string, string> | null {

        if (!url)
            return null

        const urlParts = url.split('?')

        //Query params will be in the second part
        if (!urlParts[1])
            return null

        var paramPairs = urlParts[1].split('&')

        if (!paramPairs?.length)
            return null

        const params = new Map<string, string>()

        paramPairs.forEach(
            pp => {
                const paramKvp = pp.split('=')
                //Must have 2 entries
                if (paramKvp?.[1])
                    params.set(paramKvp[0], paramKvp[1])
            }
        )

        return params

    }//GetQueryParams

    //------------------------------------------------------------------//

    getYoutubeId(url: string): string | null {

        if (!url || !this.isYoutubeUrl(url))
            return null


        const params = this.getQueryParams(url)

        if (!params?.size)
            return null

        //Return the result
        return params.get(YOUTUBE_VIDEO_ID_KEY) ?? null

    }//GetYoutubeId

    //------------------------------------------------------------------//

    getVimeoId(url: string): string | null {

        if (!url || !this.isVimeoUrl(url))
            return null


        // Look for a string with 'vimeo', then whatever, then a forward slash and a group of digits.
        const match = VIMEO_REGEX_BASIC.exec(url);

        // If the match isn't null (i.e. it matched)
        if (!match)
            return null

        return match[1]

    }//GetVimeoId

    //------------------------------------------------------------------//

    getFirstSegment(url: string): string {

        if (!url?.length)
            return ''

        return url.split('/')?.filter(Boolean)[0]

    }//getBaseSegment

    //------------------------------------------------------------------//


}//Cls

//====================================================================================//

export class QueryParam {

    constructor(public key: string, public value: string) {
    }

}//Cls

//====================================================================================//
import { Identifier } from '@inigo/data/misc';
import { FilterRequest, PagedRequest, PagedResponse } from '@inigo/pagination';
import { Observable } from 'rxjs';
import { ACrudHttpService } from '../crud/a-crud-http.service';
import { IPageHttpService } from './i-page-http.service';

/**
 * Interface for basic HttpCalls that an implentation of BaseStore will user
 *
 * @template T The main type that we're performing CRUD on.
 * @template TUpload The type that will be used for uploading (add/edit). Can be the same as T but may contain some extra fields.
 */
export abstract class APageHttpService<T, TUpload extends T> extends ACrudHttpService<T, TUpload> implements IPageHttpService<T, TUpload>
{
  //---------------------------------------------------------------------//

  getAllItemsPaged(pagedRequest: PagedRequest, id?: Identifier, opts?: any): Observable<PagedResponse<T>> {
    // console.log('getAllItemsPaged===>>', id);
    // console.trace()
    if (id == null || id == undefined)
      return this.getPage<T>(pagedRequest, opts ?? {})
    else
      return this.getPageById<T>(pagedRequest, id, opts ?? {})
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  export(filterRequest: FilterRequest[], id?: Identifier): Observable<Blob> {
    const action = id ? 'export/' + id : 'export'
    return this.postAction(action, filterRequest, { responseType: 'blob' })
  } //export

  //---------------------------------------------------------------------//

} //Cls

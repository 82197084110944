import { Directive } from '@angular/core';
import { Identifier } from '@inigo/data/misc';
import { MyBS } from '@inigo/gen-helpers/classes';
import { PagedRequest, PagedResponse } from '@inigo/pagination';
import { EMPTY, Observable } from 'rxjs';
import { finalize, map, shareReplay } from 'rxjs/operators';
import { APagePackage } from '../models/a-page-package';
import { APageStoreService } from '../page/a-page-store.service';
import { IPagePkgHttpService } from './i-page-pkg-http.service';
import { IPagePkgStoreService } from './i-page-pkg-store.service';

//################################################################################//

/**
 * If not using PagePackages just override this.getPagePackage()  with: this.getPage()
 */
@Directive()
export abstract class APagePkgStoreService<T, TUpload extends T, TPkg extends APagePackage<T>> extends APageStoreService<T, TUpload> implements IPagePkgStoreService<T, TUpload, TPkg>
{


  // protected _package?: APagePackage<APagePackage<T>>
  protected _packageBs: MyBS<TPkg> = new MyBS<TPkg>()
  package$: Observable<TPkg> = this._packageBs.obs$

  //-------------------------------------------------------//

  constructor(httpService: IPagePkgHttpService<T, TUpload, TPkg>) {
    super(httpService)
  }

  //-------------------------------------------------------//

  /**
   * If not using PagePackages just override this method with: this.getPage(pagedRequest)
   */
  getPagePackage(pagedRequest: PagedRequest, id?: Identifier): Observable<TPkg> {

    this._isLoadingBs.next(true)
    this._pkgSub?.unsubscribe()

    const httpGetAllItemsPagePkg$ = (this.httpService as IPagePkgHttpService<T, TUpload, TPkg>)
      .getAllItemsPagePkg(pagedRequest, id)
      .pipe(map(pkg => pkg))

    const getAllItemsPagePkg$ = httpGetAllItemsPagePkg$
      .pipe(
        finalize(() => this._isLoadingBs.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
      )

    this._pkgSub = getAllItemsPagePkg$
      .subscribe({
        next: (pkg) => this.handlePagePackageResponse(pkg),
        error: (error) => this._errorSb.next(error),
      }) //subscribe

    return getAllItemsPagePkg$

  } //getPagePackage

  //-------------------------------------------------------//

  /**
   * @param pkg Paged resonse with extras
   */
  protected handlePagePackageResponse(pkg: TPkg): void {

    this.handlePageResponse(pkg.pagedResponse)
    this._packageBs.next(pkg)

  } //handleResponse

  //-------------------------------------------------------//

  override filterPage(pagedRequest: PagedRequest, id: Identifier = null): Observable<PagedResponse<T>> {

    if (!pagedRequest) return EMPTY

    this._currentFilterBs.next(pagedRequest)

    if (!this._packageBs.getValue())
      return this.getPagePackage(pagedRequest, id)
        .pipe(map(pkg => pkg.pagedResponse))
    else
      return this.getPage(pagedRequest, id)

  } //filterPage

  //-------------------------------------------------------//

  /** Clean up resources and subscriptions */
  override tidyUp(): void {
    super.tidyUp()
    this._pkgSub?.unsubscribe()
  } //tidyUp

  //-------------------------------------------------------//


  refreshPackage(id: Identifier): Observable<TPkg> { return this.getPagePackage(PagedRequest.Empty(), id) }

  //-------------------------------------------------------//

} //Cls


import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CoreConfig } from './core-config';
import { CoreSetup } from './core-setup';


@NgModule({
  imports: [
    // HammerModule,
  ],
  exports: [
  ]
})
export class InigoCoreModule {



  //-------------------------------------------------------//

  constructor(@Optional() @SkipSelf() parentModule?: InigoCoreModule) {
    
    if (parentModule)
      throw new Error(
        'InigoCoreModule is already loaded. Import it in the AppModule only'
      );
  } //ctor

  //-------------------------------------------------------//

  static configure(config: CoreConfig): ModuleWithProviders<InigoCoreModule> {
    console.log('configure');
    
    return {
      ngModule: InigoCoreModule,
      providers: CoreSetup.getProviders(config),
    };
  } //forRoot




}//Cls

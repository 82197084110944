import { InjectionToken } from '@angular/core';
import { StringHelpers } from '@inigo/gen-helpers/text';

export const BouncerConfigService = new InjectionToken<BouncerConfig>(
  'BouncerConfig'
);

export class BouncerConfig {
  /**Color for first bouncer */
  private _color1: string = '';
  public get color1(): string {
    return StringHelpers.Clone(this._color1);
  }

  private _color2: string = '';
  /**Color for second bouncer */
  public get color2(): string {
    return StringHelpers.Clone(this._color2);
  }

  private _zIndex = 1000;
  /** Z-Index for the Bouncers */
  public get zIndex(): number {
    return this._zIndex;
  }

  //---------------------------------------------------------------------//

  /**
   * Set paramater to null to use default values.
   * Enter no paramaters to use default values on everything
   * @param color1 Color for first bouncer - default colorPrimary
   * @param color2 Color for second bouncer - default colorPrimaryLight
   */
  protected constructor(
    color1: string = '',
    color2: string = '',
    zIndex: number = 1
  ) {
    if (color1) this._color1 = color1;
    if (color2) this._color2 = color2;
    if (zIndex) this._zIndex = zIndex;
  } //ctor

  //---------------------------------------------------------------------//

  /**
   * Create new instance of BouncerConfig
   * @param color1 Color for first bouncer - default colorPrimary
   * @param color2 Color for second bouncer - default colorPrimaryLight
   * @param zIndex Z-Index for the Bouncers - default 1000
   */
  static Create(
    color1: string = '',
    color2: string = '',
    zIndex: number = 1000
  ): BouncerConfig {
    return new BouncerConfig(color1, color2, zIndex);
  } //create

  //---------------------------------------------------------------------//

  /**
   * @param color1 Color for first bouncer - default 'deepskyblue'
   * @returns Updated AuthConfig
   */
  setColor1(color1: string): BouncerConfig {
    this._color1 = color1;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param color2 Color for first bouncer - default 'deepskyblue'
   * @returns Updated AuthConfig
   */
  setColor2(color2: string): BouncerConfig {
    this._color2 = color2;
    return this;
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//

  /**
   * @param zIndex Z-Index for the Bouncers - default 1000
   * @returns Updated AuthConfig
   */
  setZIndex(zIndex: number): BouncerConfig {
    this._zIndex = zIndex;
    return this;
  }

  //---------------------------------------------------------------------//
} //Cls

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, AuthConfigService } from '@inigo/authentication/config';
import { AuthMiniService } from '@inigo/authentication/core';
import { StatusCodes } from '@inigo/gen-helpers/classes';
import { PopUpService } from '@inigo/pop-up';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  //-----------------------------------------------------------------------//

  private _config: AuthConfig = inject(AuthConfigService)
  private _popUps = inject(PopUpService)
  private _authService = inject(AuthMiniService)
  private _router = inject(Router)

  //-----------------------------------------------------------------------//

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {

          if (errorResponse instanceof HttpErrorResponse) {

            if (this.notAuthError(errorResponse.status))
              return throwError(() => errorResponse)
            else if (this.isAlreadyOnIgnorePage())
              return throwError(() => errorResponse)

          } //if

          this.handleUnauthorized(errorResponse)
          return new Observable<HttpEvent<any>>()

        })
      )

  } //intercept

  //-----------------------------------------------------------------------//

  /**
   * Show dialog then go to login when it closes.
   */
  private handleUnauthorized(errorResponse: HttpErrorResponse) {

    //If we're already on the login page, do nothing
    if (this.isAlreadyOnIgnorePage()) return

    const dialogRef = this._popUps.errorDialog
      .open(
        'Unauthorized',
        errorResponse?.error?.message ?? 'You must login to view this content'
      )

    dialogRef.afterClosed.subscribe(() => this.goToLogin(errorResponse.status))

  } //handleUnauthorized

  //-----------------------------------------------------------------------//

  /**
   * Sets current route as redirectUrl and then navigates to Login page
   */
  private goToLogin(status: number) {

    this._authService.logOut()

  } //goToLogin

  //-----------------------------------------------------------------------//

  isAlreadyOnIgnorePage(): boolean {


    if (!this._config.routesToIgnoreUnauthorizedResponse?.length)
      return false

    for (const route of this._config.routesToIgnoreUnauthorizedResponse) {

      if (this._router.url.includes(route))
        return true

    }

    return false

  }//isAlreadyOnIgnorePage

  //-----------------------------------------------------------------------//

  notAuthError = (status: number): boolean =>
    status !== StatusCodes.UNAUTHORIZED && status !== StatusCodes.FORBIDDEN;

  //-----------------------------------------------------------------------//
} //Cls

//---------------------------------------------------------------------//

export function ToFormData(obj: object | any): FormData {

    const formData = new FormData();


    for (const key in obj) {

        if (!Object.prototype.hasOwnProperty.call(obj, key))
            continue

        if (obj[key] instanceof Date)
            formData.append(key, to_dd_MMM_YYYY(new Date(obj[key])))

        else if (obj[key] instanceof Array)
            deconstructAndAppendArray(obj[key], key, formData)

        else if (obj[key] instanceof File)
            formData.append(key, obj[key])

        else if (obj[key] instanceof Object)
            deconstructAndAppendObject(obj[key], key, formData)

        else
            formData.append(key, obj[key])

    }//for

    return formData

}//ToFormData


//---------------------------------------------------------------------//

function appendAnyFiles(obj: object | any, field: string, formData: FormData) {

    for (const key in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, key))
            continue
        if (obj[key] instanceof File)
            formData.append(`${field}.${key}`, obj[key])

    }//for

}//appendAnyFiles

//---------------------------------------------------------------------//


/**
 * Breaks an object down to its primitive parts and concatanates the fields to make a Form Key
 * @param obj Object with possible inner objects/fields
 * @param formKey key to place the obj in the form 
 * @param formData current formData
 */
function deconstructAndAppendObject(obj: object | any, formKey: string, formData: FormData) {

    for (const key in obj) {

        if (!Object.prototype.hasOwnProperty.call(obj, key))
            continue

        if (obj[key] instanceof Array)//recurse
            deconstructAndAppendArray(obj[key], formKey + '.' + key, formData)

        else if (obj[key] instanceof File)
            formData.append(formKey + '.' + key, obj[key])

        else if (obj[key] instanceof Object)//recurse
            deconstructAndAppendObject(obj[key], formKey + '.' + key, formData)

        else if (obj[key]) //skip undefined
            formData.append(formKey + '.' + key, obj[key])

    }//for

}//deconstructAndAppendObject

//---------------------------------------------------------------------//


/**
 * Breaks an object down to its primitive parts and concatanates the fields to make a Form Key
 * @param array Object with possible inner objects/fields
 * @param formKey key to place the obj in the form 
 * @param formData current formData
 */
function deconstructAndAppendArray(array: any[], formKey: string, formData: FormData) {

    array.forEach((obj, idx) => {

        if (obj instanceof Array)//recurse
            deconstructAndAppendArray(obj, arrayKey(formKey, idx), formData)

        else if (obj instanceof File) //File is an object but we don't want to recurse
            formData.append(arrayKey(formKey, idx), obj)

        else if (obj instanceof Object)//recurse
            deconstructAndAppendObject(obj, arrayKey(formKey, idx), formData)

        else
            formData.append(arrayKey(formKey, idx), obj)

    })//for

}//deconstructAndAppendArray

//---------------------------------------------------------------------//


function to_dd_MMM_YYYY(date: Date): string {

    if (!date)
        return ''

    const safeDate = new Date(date)


    const day = safeDate.toLocaleString('default', { day: '2-digit' });
    const month = safeDate.toLocaleString('default', { month: 'short' });
    const year = safeDate.toLocaleString('default', { year: 'numeric' });

    return day + '-' + month + '-' + year;

}//to_dd_MMM_YYYY

//---------------------------------------------------------------------//

function arrayKey(formKey: string, idx: number): string {
    return `${formKey}[${idx}]`
}
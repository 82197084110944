/**Class for encapsulating all the model state errorrs */
export class ModelStateErrors {
  private _list = Array<ModelStateError>();

  //----------------------------------------------//

  getList(): Array<ModelStateError> {
    if (!this._list) this._list = Array<ModelStateError>();

    return this._list;
  } //getList

  //----------------------------------------------//

  getFirstErrorMessage(): string {
    const first = this.getList()[0];

    if (typeof first?.errors === 'string') return first?.errors;

    return first?.errors[0] ?? first?.key ?? 'Unknown Error';
  } //getFirstErrorMessage

  //----------------------------------------------//

  getModelStateError(idx: number): ModelStateError {
    return this.getList()[idx];
  } //getModelStateError

  //----------------------------------------------//

  addModelStateError(key: string, errorArray: Array<string>) {
    const _list = this.getList();
    const idx = this._list.findIndex((ms) => ms.key === key);

    if (idx >= 0) _list[idx].errors.push(...errorArray);
    else _list.push(new ModelStateError(key, errorArray));
  } //addModelStateError
} //Cls

//======================================================================//

/**Individual model state error */
export class ModelStateError {
  //----------------------------------------------------//

  constructor(public key: string, public errors = Array<string>()) {} //ctor

  //----------------------------------------------------//

  joinErrorsIntoString() {
    console.log('joinErrorsIntoString');
  } //joinErrorsIntoString
} //Cls

//======================================================================//

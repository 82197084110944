import { IsNumber } from '@inigo/gen-helpers/numbers';
import { TimeInMillis } from './time-in';

export class DateHelpers {

  static FromMilliseconds(millis: number): Date {
    try {
      if (!IsNumber(millis) || millis < 0) return new Date();

      return new Date(millis);
    } catch (error) {
      return new Date();
    }
  } //FromMilliseconds

  //--------------------------------------------------//

  static FromSeconds = (secs?: number): Date =>
    DateHelpers.FromMilliseconds((secs ?? 0) * TimeInMillis.Second);

  //--------------------------------------------------//

  static FormatYearMonthDay(date: Date = new Date(), separator = '-') {

    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;

    if (day.length < 2) day = '0' + day;

    return [year, month, day].join(separator);
  } //FormatDateYearMonthDay

  //--------------------------------------------------//


  static To_dd_MMM_YYYY(date: Date): string {

    if (!date)
      return ''

    const dt = new Date(date)
    const day = dt.toLocaleString('default', { day: '2-digit' });
    const month = dt.toLocaleString('default', { month: 'short' });
    const year = dt.toLocaleString('default', { year: 'numeric' });

    return day + '-' + month + '-' + year;

  }//to_dd_MMM_YYYY

  //--------------------------------------------------//


  static WithoutTime(date?: Date): Date {

    //Make sure it's a date object
    const d = date ? new Date(date) : new Date()
    d.setHours(0, 0, 0, 0)
    return d

  }//withoutTime

  //--------------------------------------------------//


  static IsToday(date?: Date): boolean {

    if (!date)
      return false

    const today = new Date()

    return this.WithoutTime(today).getTime() === this.WithoutTime(date).getTime()

  }//isToday

  //--------------------------------------------------//


  static IsTomorrow(date?: Date): boolean {

    if (!date)
      return false

    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)

    return this.WithoutTime(tomorrow).getTime() === this.WithoutTime(date).getTime()

  }//IsTomorrow


} //Cls

export class InputAcceptFileTypes {
    
    /**
     * ANy file type
     */
    static ALL = '*'

    /**
     * PNG is preferred over JPEG for more precise reproduction of source images, or when transparency is needed. WebP/AVIF provide even better compression and reproduction, but browser support is more limited.
     * 
     * Support: Chrome, Edge, Firefox, IE, Opera, Safari.
     */
    static IMAGE_PNG = 'image/png'

    /**
     * Good choice for lossy compression of still images (currently the most popular). Prefer PNG when more precise reproduction of the image is required, or WebP/AVIF if both better reproduction and highercompression are required.
     * 
     * Support: Chrome, Edge, Firefox, IE, Opera, Safari.
     */
    static IMAGE_JPG = 'image/jpeg'

    /**
     * Good choice for both images and animated images due to high performance and royalty free image format. It offers much better compression than PNG or JPEG with support for higher color depths, animated frames, transparency etc. Note that when using AVIF, you should include fallbacks to formats with better browser support (i.e. using the <picture> element).
     * 
     * Supported: Chrome, Opera, Firefox (still images only: animated images not implemented).
     */
    static IMAGE_AVIF = 'image/avif'

    /**
     * Good choice for simple images and animations. Prefer PNG for lossless and indexed still images, and consider WebP, AVIF or APNG for animation sequences.
     *
     *  Supported: Chrome, Edge, Firefox, IE, Opera, Safari.
     */
    static IMAGE_GIF = 'image/gif'

    /**
     * Excellent choice for both images and animated images. WebP offers much better compression than PNG or JPEG with support for higher color depths, animated frames, transparency etc. AVIF offers slightly better compression, but is not quite as well-supported in browsers and does not support progressive rendering.
     * 
     * Support: Chrome, Edge, Firefox, Opera, Safari
     */
    static IMAGE_WEBP = 'image/webp'

    /**
     * Vector image format; ideal for user interface elements, icons, diagrams, etc., that must be drawn accurately at different sizes.
     * 
     * Support: Chrome, Edge, Firefox, IE, Opera, Safari.
     */
    static IMAGE_SVG = 'image/svg+xml'

    /**
     * Good choice for lossless animation sequences (GIF is less performant). AVIF and WebP have better performance but less broad browser support.
     * 
     * Supported: Chrome, Edge, Firefox, Opera, Safari.
     */    
    static IMAGE_APNG = 'image/apng'

    static IMAGE_PNG_JPG = `${InputAcceptFileTypes.IMAGE_PNG}, ${InputAcceptFileTypes.IMAGE_JPG}`

    static IMAGE_ALL = 'image/*'


    static MS_WORD = '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'

    static CSV = '.csv'

    static PDF = '.pdf'

    static EXCEL_MACRO_EMABLED = 'application/vnd.ms-excel.sheet.macroenabled.12'

    static EXCEL_LEGACY = 'application/vnd.ms-excel'

    static EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    static EXCEL_ALL = `${InputAcceptFileTypes.EXCEL_MACRO_EMABLED}, ${InputAcceptFileTypes.EXCEL_LEGACY}, ${InputAcceptFileTypes.EXCEL}`


    static ZIP = '.zip'
    static COMPRESSED = '.zip,.rar,.7zip,application/zip,application/x-zip,application/x-zip-compressed '

}//Cls
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelToTitle',
  standalone: true,
})
export class CamelCaseToTitleCasePipe implements PipeTransform {
  //---------------------------------------------------------------------//

  transform(value?: string): string {
    if (!value) return '';

    if (typeof value !== 'string') return value;

    value = value.split(/(?=[A-Z])/).join(' ');
    value = value[0].toUpperCase() + value.slice(1);

    return value;
  } //transform

  //---------------------------------------------------------------------//
} //Cls

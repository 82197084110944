export class AuthActions {
  /** Account Management */
  static Acc = class {
    static readonly CHNG_PASSWORD = 'changePassword';
    static readonly CONFIRM_EMAIL_MNTC = 'confirmEmailMntc';
    static readonly CONFIRM_EMAIL_GUEST = 'confirmEmailGuest';
    static readonly CONFIRM_EMAIL_SPR = 'confirmEmailSpr';
    static readonly CONFIRM_EMAIL_CUSTOMER = 'confirmEmailCustomer';
    static readonly CREATE_CUSTOMER = 'createCustomer';
    static readonly FORGOT_PASSWORD = 'forgotPassword';
    static readonly LOGIN = 'login';
    static readonly PUBLIC_SIGNING_KEY = 'publicSigningKey';
    static readonly REGISTER_CUSTOMER = 'registerCustomer';
    static readonly REGISTER_ADMIN_TEAM = 'registerAdminTeam';
    static readonly REGISTER_GUEST = 'registerGuest';
    static readonly REGISTER_MNTC_TEAM_MEMBER = 'registerMntcTeamMember';
    static readonly REGISTER_MNTC_TEAM_LEADER = 'registerMntcTeamLeader';
    static readonly REGISTER_SPR_TEAM_MEMBER = 'registerSuperTeamMember';
    static readonly REGISTER_SPR_TEAM_LEADER = 'registerSuperTeamLeader';
    static readonly RESEND_EMAIL_CONFIRMATION = 'resendEmailConfirmation';
    static readonly RESEND_TWO_FACTOR_CODE = 'resendTwoFactorCode';
    static readonly RESET_PASSWORD = 'resetPassword';
    static readonly TWO_FACTOR_VERIFICATION = 'twoFactorVerification';
  }; //Cls

  //#########################################################################//

  /** User Management */
  static UMgmt = class {
    static readonly ALL_USERS = 'allUsers';
    static readonly ALL_USERS_MAINTENANCE = 'allMaintenanceUsers';
    static readonly ALL_USERS_SUPER = 'allSuperUsers';
    static readonly DELETE_USER = 'deleteUser';
    static readonly EDITSELF = 'editSelf';
    static readonly EDITUSER = 'editUser';
    static readonly EDIT_CUSTOMER = 'editCustomer';
    static readonly GET_CUSTOMER = 'getCustomer';
    static readonly GET_USER = 'getUser';
    static readonly MYINFO = 'myInfo';
    static readonly MYINFO_CUSTOMER = 'myInfoCustomer';
    static readonly PAGE = 'page';
    static readonly PAGE_MAINTENANCE = 'maintenancePage';
    static readonly PAGE_CUSTOMERS = 'customersPage';
    static readonly PAGE_PACKAGE_CUSTOMERS = 'customersPagePackage';
    static readonly PAGE_SUPER = 'superPage';
    static readonly UPGRADETOADMIN = 'upgradeToAdmin';
    static readonly UPGRADETOMANAGER = 'upgradeToManager';
    static readonly USER = 'user​';
    static readonly USERCLAIMS = 'userClaims';
    static readonly USERCLAIMSBYID = 'userClaimsById​';
    static readonly USERS = 'users';
    static readonly UPDATE_SUBSCRIPTION = 'updateSubscription';
    static readonly UPGRADE_TO_ADMIN = 'upgradeToAdmin';
    static readonly UPGRADE_TO_MGR = 'upgradeToManager';
  }; //Cls

  //#########################################################################//

  /** Device Management */
  static DvcMgmt = class {
    static readonly EDIT_DEVICE = 'Edit';
    static readonly GET_DEVICE = 'Get';
    static readonly GET_ALL = 'GetAll';
    static readonly MY_DEVICES = 'MyDevices';
    static readonly MY_DEVICES_BY_APP_ID = 'MyDevicesByApp';
    /** Gets everyone's devices */
    static readonly PAGE = 'page';
    /** Gets this user's devices */
    static readonly PAGE_MY_DEVICES = 'MyDevicesPage';

    static readonly REGISTER_DEVICE = 'RegisterDevice';
    static readonly REGISTER_MY_DEVICE = 'RegisterMyDevice';
    static readonly REMOVE_DEVICE = 'RemoveDevice';
    static readonly REMOVE_MY_DEVICE_BY_ID = 'RemoveDevice';
  }; //Cls

  //#########################################################################//

  /** Access Point Management */
  static AcsPntMgmt = class {
    static readonly ADD_ACS_PNT = 'Add';
    static readonly DELETE_ACS_PNT = 'Delete';
    static readonly EDIT_ACS_PNT = 'Edit';
    static readonly GET_ACS_PNT = 'Get';
    static readonly GET_ALL_ACS_PNT = 'GetAll';
    static readonly PAGE = 'page';
  }; //Cls

  //#########################################################################//

  /** Access Point Management */
  static SubDefMgmt = class {
    static readonly ADD_ACS_PNT = 'Add';
    static readonly DELETE_ACS_PNT = 'Delete';
    static readonly EDIT_ACS_PNT = 'Edit';
    static readonly GET_ACS_PNT = 'Get';
    static readonly GET_ALL_ACS_PNT = 'GetAll';
    static readonly PAGE = 'page';
    static readonly PAGE_PACKAGE = 'pagePackage';
  }; //Cls

  //#########################################################################//
} //Cls

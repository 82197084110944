import { Injectable } from '@angular/core';
import { MessageResponseDto } from '@inigo/data/misc';
import { ErrorComponent } from '../../../components/info/error/error.component';
import { DialogConfig } from '../../../utility/dialog-config';
import { BaseDialogService } from '../../base/base-dialog.service';
import { DialogRef } from '../../../utility/dialog-ref';

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService extends BaseDialogService {

  //------------------------------------------------------//

  /**
   * Open Error Dialog
   * @param title Title of Dialog
   * @param message Content of Dialog
   * @param width How wide is it - defauults to '500px'
   */
  public open(title: string, message: string, width: string = '500px'): DialogRef {
    const data = {
      title: title,
      message: message,
    };
    const config = new DialogConfig(width, data, false);

    return this.openBase(ErrorComponent, config)

  } //open

  //------------------------------------------------------//

  /** Display an error
   * @param error: error object with message in it.
   * @param width How wide is it - defaults to '500px'
   */
  public openErrorResponse(error: any, width: string = '500px', title: string = 'Error'): DialogRef {

    let msg: string | undefined;
    if (error && typeof error === 'string') msg = error;

    const data = {
      title: title,
      message: msg ?? error?.message ?? error?.msg ?? 'Something went wrong.',
    };
    const config = new DialogConfig(width, data, false)

    return this.openBase(ErrorComponent, config);
  } //open

  //------------------------------------------------------//

  /**
   * Open Success Dialog with title set to 'Success'
   * @param msgDto Dto with the message info
   * @param width How wide is it - defaults to '500px'
   */
  public openMessageResponseDto = (msgDto: MessageResponseDto, width?: string): DialogRef =>
    this.open('Error', msgDto.message, width)

  //------------------------------------------------------//
} //Cls

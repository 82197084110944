import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BouncerConfig, BouncerConfigService } from './bouncer-config';
import { LoaderBouncerComponent } from './bouncer.component';

@NgModule({
  declarations: [LoaderBouncerComponent],
  imports: [CommonModule],
  exports: [LoaderBouncerComponent],
})
export class LoaderBouncerModule {

  static configure(config: BouncerConfig | undefined = BouncerConfig.Create()): ModuleWithProviders<LoaderBouncerModule> {
    return {
      ngModule: LoaderBouncerModule,
      providers: [
        {
          provide: BouncerConfigService,
          useValue: config,
        },
      ],
    };
  } //configure

}//Cls

import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs'

/**
 * Class for creating observables that is readonly for consumers of it.
 */
export class MyBS<T> {

    protected _source: Subject<T>
    obs$: Observable<T>

    private value?: T

    //----------------------------------------------------------------//

    /**
     * @param startVal first value to output (defaults to null)
     * Default = false
     */
    constructor(startVal?: T) {

        if (startVal) {
            this._source = new BehaviorSubject<T>(startVal)
            this.value = startVal
        }
        else {

            this._source = new ReplaySubject<T>(1)
            
        }//else


        this.obs$ = this._source.asObservable()

    }//ctor

    //----------------------------------------------------------------//

    /**Pass in a new value */
    next(nextVal: T): void {

        this.value = nextVal        
        this._source.next(nextVal)

    }//next

    //----------------------------------------------------------------//

    /**
     * Current value
     */
    getValue = (): T | undefined => this.value

    //----------------------------------------------------------------//

}//Cls
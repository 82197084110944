import { Injectable } from '@angular/core';
import { ToFormData } from '@inigo/gen-helpers/objects';
import { APageHttpService } from '@inigo/my-store';
import { Observable, timeout } from 'rxjs';
import { AppActions } from '../../../../app-actions';
import { AppControllers } from '../../../../app-controllers';
import { BulkUploadDto } from '../../../dtos/bulk-upload';
import { VariantColor } from '../../../dtos/color';

@Injectable({
  providedIn: 'root',
})
export class ColorHttpService extends APageHttpService<VariantColor, VariantColor> {

  constructor() {
    super();
    this.url = AppControllers.FullPath.COLORS
  } //ctor

  //---------------------------------------------------------------------//

  getAllPresets = (opts?: any): Observable<VariantColor[]> =>
    this.getAllAction(AppActions.Colors.ALL_PRESETS, opts ?? {})

  //---------------------------------------------------------------------//
  
  addBulk = (dto: BulkUploadDto): Observable<Blob> =>
    this.postAction(AppActions.Colors.ADD_BULK, ToFormData(dto), { responseType: 'blob' })
      .pipe(
        timeout(600000)
      )

  //---------------------------------------------------------------------//

} //service

import { Subject } from 'rxjs'

/**
 * Class for creating observables that is readonly for consumers of it.
 */
export class MySub<T> {

    /**
     * @param startVal first value to output (defaults to null)
     */
    constructor(startVal?: T) {
        
        if (startVal) this.next(startVal)

    }//ctor

    //----------------------------------------------------------------//

    protected _source = new Subject<T>()
    obs$ = this._source.asObservable()

    //----------------------------------------------------------------//

    next(nextVal: T): void {
        this._source.next(nextVal)
    }//next

    //----------------------------------------------------------------//

}//Cls